import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { LomakeApiRequest } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

interface TilintarkastusAsiointiClient {
  postPaatostili: (data: LomakeApiRequest) => Promise<ApiResponse<unknown>>

  postOmaisuusluettelo: (
    data: LomakeApiRequest
  ) => Promise<ApiResponse<unknown>>
}

export const TilintarkastusApiClient: TilintarkastusAsiointiClient = {
  postPaatostili: (data: LomakeApiRequest): Promise<ApiResponse<unknown>> =>
    executeBackendClient((client) => {
      return client.post('/tilintarkastus-asiointi/api/v1/paatostili', data)
    }),

  postOmaisuusluettelo: (
    data: LomakeApiRequest
  ): Promise<ApiResponse<unknown>> =>
    executeBackendClient((client) => {
      return client.post(
        '/tilintarkastus-asiointi/api/v1/omaisuusluettelo',
        data
      )
    }),
}
