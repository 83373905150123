import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { KyllaEiTuntematon } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const getContent = (tili: TiliData, t: TFunction): YhteenvetoGroupProps[] => {
  const content = []
  const laadittu = tili.testamentti?.laadittu
  const rajoittaviaEhtoja = tili.testamentti?.rajoittaviaEhtoja
  content.push({
    heading: t('onkoHenkiloLaatinutTestamentin'),
    rows: [
      laadittu === KyllaEiTuntematon.KYLLA
        ? t('kylla')
        : laadittu === KyllaEiTuntematon.EI
        ? t('ei')
        : laadittu === KyllaEiTuntematon.TUNTEMATON
        ? t('enTieda')
        : undefined,
    ],
  })
  if (laadittu === KyllaEiTuntematon.KYLLA) {
    content.push({
      heading: t('testamentinSisalto'),
      rows: [
        rajoittaviaEhtoja === KyllaEiTuntematon.KYLLA
          ? t('sisaltaaRajoittaviaEhtoja')
          : rajoittaviaEhtoja === KyllaEiTuntematon.EI
          ? t('eiSisallaRajoittaviaEhtoja')
          : rajoittaviaEhtoja === KyllaEiTuntematon.TUNTEMATON
          ? t('tuntematon')
          : undefined,
      ],
    })
  }
  return content
}

const Testamentti: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('henkilonLaatimaTestamentti')}
      </Heading>
      <YhteenvetoGroups groups={getContent(tili, t)} />
    </>
  )
})

export default Testamentti
