import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import { FrontSopimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import {
  formatDateAsLocal,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { Voimassaoloaika } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const SopimuksetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const { tili } = getTilintarkastusStore().lomake

  return (
    <section data-test-id={mkYleiskatsausTestId('sopimukset-form')}>
      <Heading variant="h3" mb="xs">
        {t('sopimuksetHeading')}
      </Heading>
      <Paragraph>
        {isOmaisuusluettelo()
          ? t('ohjeOnkoVoimassaoleviaSopimuksia')
          : t('ohjeIlmoitaSopimukset')}
      </Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={SopimuksetListItemContent}
        state={tili.sopimustiedot.sopimukset}
        dataTestIdPrefix={mkYleiskatsausTestId('sopimukset-form')}
        ModalContentComponent={SopimuksetModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            sopimustyyppi: '',
            toimittaja: '',
            voimassaoloaika: undefined,
            beginDate: undefined,
            endDate: undefined,
            asiakirjat: [],
          } as FrontSopimus)
        }
        stateSubmitAdapter={({ endDate, ...rest }: FrontSopimus) => {
          if (
            rest.voimassaoloaika === Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA
          ) {
            return rest
          } else {
            return { ...rest, endDate }
          }
        }}
        translationPrefix="sopimukset"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

export default SopimuksetForm

const SopimuksetModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontSopimus>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const showEndDate =
    entry.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN ||
    !isOmaisuusluettelo()

  return (
    <>
      <FormTextInput
        data-test-id={createDataTestId('modal-sopimustyyppi')}
        labelText={t('sopimustyyppi')}
        hintText={t('sopimustyyppiHintText')}
        value={entry.sopimustyyppi}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, sopimustyyppi: value })}
      />

      <FormTextInput
        data-test-id={createDataTestId('modal-toimittaja')}
        labelText={t('toimittaja')}
        value={entry.toimittaja}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, toimittaja: value })}
      />

      <Block mb={verticalMarginToken}>
        <FormRadioButtonGroup
          required
          value={entry.voimassaoloaika}
          data-test-id={createDataTestId('modal-voimassaoloaika')}
          labelText={t('voimassaoloaika')}
          updateValue={(value) => {
            value === 'MAARA_AIKAINEN'
              ? setEntry({
                  ...entry,
                  voimassaoloaika: value as Voimassaoloaika,
                })
              : setEntry({
                  ...entry,
                  endDate: undefined,
                  voimassaoloaika: value as Voimassaoloaika,
                })
          }}
          radioButtons={[
            {
              value: Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA,
              labelText: t('toistaiseksiVoimassaOleva'),
            },
            {
              value: Voimassaoloaika.MAARA_AIKAINEN,
              labelText: t('maaraaikainen'),
            },
          ]}
        />
      </Block>

      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />

      {showEndDate && (
        <FormDateInput
          labelText={t('paattymispaivamaara')}
          data-test-id={createDataTestId('modal-paattymispaivamaara')}
          value={
            entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              endDate: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />
      )}

      <FormAttachmentFileBox
        observableAttachments={entry.asiakirjat}
        asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
        data-test-id={createDataTestId('modal-asiakirja')}
        title={t('asiakirjat')}
        text={t('ohjeSopimusAsiakirjat')}
      />
    </>
  )
}

const SopimuksetListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontSopimus>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const isMaaraaikainen =
    entry.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN || entry.endDate

  const voimassaoloaikaHeading = isMaaraaikainen
    ? t('maaraaikainen')
    : t('toistaiseksiVoimassaoleva')

  const alkamispvm = entry.beginDate
    ? formatDateAsLocal(entry.beginDate)
    : t('alkamispaivamaaraEiTiedossa')

  const paattymispvm = entry.endDate
    ? formatDateAsLocal(entry.endDate)
    : t('paattymispaivamaaraEiTiedossa')

  const dates = isMaaraaikainen ? `${alkamispvm} - ${paattymispvm}` : alkamispvm

  return (
    <FormListInputExpander
      heading={entry.sopimustyyppi}
      headingRight={voimassaoloaikaHeading}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      <Heading variant="h5">{t('toimittaja')}</Heading>
      <Text>{entry.toimittaja}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">
        {isMaaraaikainen ? t('jakso') : t('alkamispaivamaara')}
      </Heading>
      <Text>{dates}</Text>
      <Block mt={verticalMarginToken} />

      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('asiakirjat')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
    </FormListInputExpander>
  )
}
