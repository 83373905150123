import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTili,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { Heading, Paragraph } from 'suomifi-ui-components'
import { mkKayttovaratTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { runInAction } from 'mobx'

const KayttovaratForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const { paamiehenOmaisuus } = getTili()

  const translationType = isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI'

  return (
    <section data-test-id={mkKayttovaratTestId('kayttovarat-container')}>
      <Heading mb="xs" variant="h3">
        {t(translations[translationType].heading)}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t(translations[translationType].info)}
      </Paragraph>

      <FormTextArea
        data-test-id={mkKayttovaratTestId('kaytanto-input')}
        labelText={t(translations[translationType].kaytantoLabel)}
        hintText={t(translations[translationType].kaytantoHint)}
        maxLength={1000}
        required
        value={paamiehenOmaisuus.kayttovarakaytanto}
        validate={commonXssValidator(t)}
        updateValue={(value) => {
          runInAction(() => {
            paamiehenOmaisuus.kayttovarakaytanto = value
          })
        }}
      />
    </section>
  )
})

export default KayttovaratForm

const translations = {
  OMAISUUSLUETTELO: {
    heading: 'kayttovarojenTarveJaKaytannot',
    info: 'ohjeKayttovarojenTarveJaKaytannot',
    kaytantoLabel: 'mitenAnnatKayttovarojaHenkilonOmaanKayttoon',
    kaytantoHint: 'ohjeJosEtAnnaKayttovaroja',
  },
  TILI: {
    heading: 'kayttovarat',
    info: 'ohjeIlmoitaMitaKayttovarojaHenkilolle',
    kaytantoLabel: 'mitenKayttovarojaOnHenkilolleAnnettu',
    kaytantoHint: 'ohjeKuvaileKayttovarojenKaytontoja',
  },
}
