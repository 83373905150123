import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkAlaikainenAsuminenFormTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { action } from 'mobx'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { AsuinpaikkaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/AsuinpaikkaForm'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import {
  getTili,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const AlaikainenAsuminenForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const { asuminen } = getTili()
  return (
    <section data-test-id={mkAlaikainenAsuminenFormTestId('container')}>
      <Heading variant={'h3'} mb="xs">
        {t('asuinpaikka')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t(
          isOmaisuusluettelo()
            ? 'alaikainenAsuinpaikkaPaaOhje'
            : 'ohjeIlmoitaAsuinpaikat'
        )}
      </Paragraph>

      <FormRadioButtonGroup
        required
        value={asuminen.asuuHuoltajanKanssa?.toString()}
        data-test-id={mkAlaikainenAsuminenFormTestId('radio')}
        labelText={t('alaikainenAsuinpaikkaOhje')}
        updateValue={action((value) => {
          asuminen.asuuHuoltajanKanssa = value === 'true'
        })}
        radioButtons={[
          { value: 'false', labelText: t('ei') },
          { value: 'true', labelText: t('kylla') },
        ]}
      />

      {asuminen.asuuHuoltajanKanssa ? (
        <Block mt={verticalMarginToken}>
          <FormTextArea
            data-test-id={mkAlaikainenAsuminenFormTestId('huoltajien-nimet')}
            value={asuminen.huoltajalla}
            updateValue={action((value) => {
              asuminen.huoltajalla = value
            })}
            required
            labelText={t('kenenKanssaHenkiloAsuu')}
            hintText={t('ilmoitaHenkiloidenNimet')}
            maxLength={1000}
            validate={commonXssValidator(t)}
          />
        </Block>
      ) : asuminen.asuuHuoltajanKanssa === false ? (
        <Block mt={verticalMarginToken}>
          <AsuinpaikkaForm />
        </Block>
      ) : null}
    </section>
  )
})

export default AlaikainenAsuminenForm
