import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ExpanderGroup,
  IconPlus,
  InlineAlert,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Text,
} from 'suomifi-ui-components'
import { action, runInAction } from 'mobx'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { FormProvider, useForm } from 'react-hook-form'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import styled from 'styled-components'
import { saveDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import FormCustomValidationRule from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCustomValidationRule'

type TiliointiRivi = { id: string; tyyppi?: string; tiliointinumero?: string }

export interface FormElementProps<T extends object> {
  entry: T
  onEdit: () => void
  onRemove: (deleteModalText: string) => void
  createDataTestId: (...arg: ChildTestId[]) => string
}

export interface FormModalProps<T extends object> {
  entry: T
  setEntry: (entry: T) => void
  createDataTestId: (...arg: ChildTestId[]) => string
}

export interface FormListProps<T extends TiliointiRivi> {
  translationPrefix: string
  ModalContentComponent: React.FC<FormModalProps<T>>
  ExpanderComponent: React.FC<FormElementProps<T>>
  state: T[] // observable array
  stateSubmitAdapter?: (t: T) => T
  newEntry: () => T
  dataTestIdPrefix: string
  lomakkeelleValitutTiedot?: Record<string, boolean>
}

export const FormListInput = observer(
  <T extends TiliointiRivi>({
    translationPrefix,
    ModalContentComponent,
    ExpanderComponent,
    newEntry,
    dataTestIdPrefix,
    state,
    lomakkeelleValitutTiedot,
  }: FormListProps<T>) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const [modalVisible, setModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteModalText, setDeleteModalText] = useState('')
    const [deleteModalOnClick, setDeleteModalOnClick] = useState<() => void>(
      () => () => null
    )
    const [nextNewEntryState, setNextNewEntryState] = useState(newEntry())
    const [modalEntryState, setModalEntryState] = useState(nextNewEntryState)
    const createListDataTestId = (...arg: ChildTestId[]) =>
      `${[dataTestIdPrefix, ...arg].join('-')}`

    const isEditing = () => state.find(({ id }) => id === modalEntryState.id)

    const modalForm = useForm()

    const openModalForEdit = (entry: T & { id: string }) => {
      setModalEntryState({ ...entry })
      modalForm.reset()
      setModalVisible(true)
    }

    const openModalForAddNew = () => {
      const entry = { ...nextNewEntryState }
      setModalEntryState(entry)
      if (modalEntryState.id !== entry.id) {
        modalForm.reset()
      }
      setModalVisible(true)
    }

    const onModalAbort = () => {
      setModalVisible(false)
      if (!isEditing()) {
        setNextNewEntryState(modalEntryState) // save data to continue later
      }
    }

    const onModalSubmit = async () => {
      if (!isEditing()) {
        runInAction(() => {
          state.push(modalEntryState)
        })
        setNextNewEntryState(newEntry())
      } else {
        runInAction(() =>
          state.forEach((item) => {
            if (item.id === modalEntryState.id) {
              Object.assign(item, modalEntryState)
            }
          })
        )
      }
      await saveDraft()
      setModalVisible(false)
    }

    return (
      <FormCustomValidationRule<T>
        data-test-id={createListDataTestId('validator')}
        value={state}
        validate={(state: T[]) => {
          if (
            (lomakkeelleValitutTiedot?.[translationPrefix] === true ||
              !lomakkeelleValitutTiedot) &&
            state?.length === 0
          ) {
            return t(`${translationPrefix}-ADD-HEADER`)
          }
        }}
      >
        <>
          {lomakkeelleValitutTiedot && (
            <FormRadioButtonGroup
              required
              value={lomakkeelleValitutTiedot[translationPrefix]?.toString()}
              updateValue={action((value) => {
                lomakkeelleValitutTiedot[translationPrefix] = value === 'true'
              })}
              data-test-id={createListDataTestId('kylla-ei')}
              labelText={t(`${translationPrefix}-RADIO-LABEL`)}
              radioButtons={[
                { value: 'false', labelText: t('ei') },
                { value: 'true', labelText: t('kylla') },
              ]}
            />
          )}
          {lomakkeelleValitutTiedot?.[translationPrefix] !== false &&
            state.length > 0 && (
              <ExpanderGroup
                openAllText={t('formListAvaaKaikki')}
                ariaOpenAllText={t('formListAvaaKaikki')}
                closeAllText={t('formListSuljeKaikki')}
                ariaCloseAllText={t('formListSuljeKaikki')}
                data-test-id={createListDataTestId('expander-group')}
                mt={'m'}
              >
                {state.map((entry, index) => {
                  const createFieldTestId = (...id: ChildTestId[]) =>
                    createListDataTestId(index, ...id)

                  return (
                    <ExpanderComponent
                      key={index}
                      entry={entry}
                      onEdit={() => openModalForEdit({ ...entry })}
                      onRemove={(deleteModalText: string) => {
                        setDeleteModalText(deleteModalText)
                        setDeleteModalOnClick(() => async () => {
                          runInAction(() => state.splice(index, 1))
                          await saveDraft()
                          setDeleteModalVisible(false)
                        })
                        setDeleteModalVisible(true)
                      }}
                      createDataTestId={createFieldTestId}
                    />
                  )
                })}
              </ExpanderGroup>
            )}

          {lomakkeelleValitutTiedot?.[translationPrefix] === false &&
            state.length > 0 && (
              <InlineAlert mt={'s'} status={'warning'}>
                {t('valittuEiInlineAlert')}
              </InlineAlert>
            )}

          {(lomakkeelleValitutTiedot?.[translationPrefix] === true ||
            !lomakkeelleValitutTiedot) && (
            <Button
              icon={<IconPlus />}
              variant="secondary"
              data-test-id={dataTestIdPrefix + '-add-button'}
              onClick={openModalForAddNew}
              mt={verticalMarginToken}
            >
              {t(`${translationPrefix}-ADD-BUTTON`)}
            </Button>
          )}

          <Modal
            visible={modalVisible}
            appElementId={ASIOINTI_ROOT_ELEMENT_ID}
            variant={isTablet ? 'default' : 'smallScreen'}
            onEscKeyDown={onModalAbort}
          >
            <FormProvider {...modalForm}>
              <ModalContent
                data-test-id={createListDataTestId('modal-content')}
              >
                <ModalTitle>
                  {!isEditing()
                    ? t(`${translationPrefix}-ADD-HEADER`)
                    : t(`${translationPrefix}-EDIT-HEADER`)}
                </ModalTitle>
                <ModalContentComponent
                  entry={modalEntryState}
                  setEntry={setModalEntryState}
                  createDataTestId={createListDataTestId}
                />
              </ModalContent>
              <ModalFooterWrapper>
                <ModalFooter
                  data-test-id={createListDataTestId('modal-footer')}
                >
                  <Button
                    data-test-id={createListDataTestId('modal-save-button')}
                    onClick={async () =>
                      modalForm.handleSubmit(() => onModalSubmit())()
                    }
                  >
                    {t('save')}
                  </Button>
                  <Button
                    data-test-id={createListDataTestId('modal-cancel-button')}
                    onClick={onModalAbort}
                    variant="secondary"
                  >
                    {t('cancel')}
                  </Button>
                </ModalFooter>
              </ModalFooterWrapper>
            </FormProvider>
          </Modal>
          <Modal
            visible={deleteModalVisible}
            appElementId={ASIOINTI_ROOT_ELEMENT_ID}
            variant={isTablet ? 'default' : 'smallScreen'}
            onEscKeyDown={() => setDeleteModalVisible(false)}
          >
            <ModalContent
              data-test-id={createListDataTestId('delete-modal-content')}
            >
              <ModalTitle>{t('vahvistaPoisto')}</ModalTitle>
              <Text>{deleteModalText}</Text>
            </ModalContent>
            <ModalFooter
              data-test-id={createListDataTestId('delete-modal-footer')}
            >
              <Button
                data-test-id={createListDataTestId(
                  'delete-modal-confirm-button'
                )}
                onClick={() => deleteModalOnClick()}
              >
                {t('vahvistaPoisto')}
              </Button>
              <Button
                data-test-id={createListDataTestId(
                  'delete-modal-cancel-button'
                )}
                onClick={() => setDeleteModalVisible(false)}
                variant="secondary"
              >
                {t('cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </FormCustomValidationRule>
    )
  }
)

const ModalFooterWrapper = styled.div`
  .fi-modal_footer_content-gradient-overlay {
    pointer-events: none;
  } // hax, this allows clicking through gradient overlay, will be removed after design system update
`
