import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  getTili,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'

const VerotiedotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const verotustiedotTestId = mkYleiskatsausTestId(
    'verotustiedot-attachment-file-box'
  )

  const tili = getTili()
  return (
    <section data-test-id={mkYleiskatsausTestId('verotiedot-container')}>
      <Heading variant="h3" mb="xs">
        {t('verotiedot')}
      </Heading>
      <Paragraph>
        {isOmaisuusluettelo()
          ? t('ilmoitaOmaisuusluetteloVerotiedot')
          : t('ohjeIlmoitaHenkilonVerotiedot')}
      </Paragraph>
      <Block mt={verticalMarginToken} />
      <FormAttachmentFileBox
        observableAttachments={tili.verotustiedot}
        asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
        data-test-id={verotustiedotTestId}
        title={t('veroIlmoitusTaiVerotuspaatosTuloverosta')}
      />
    </section>
  )
})

export default VerotiedotForm
