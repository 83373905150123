import {
  Block,
  Expander,
  ExpanderContent,
  ExpanderTitleButton,
  ExternalLink,
  Heading,
  Paragraph,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkAloitussivuTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import styled from 'styled-components'
import {
  omaisuusluetteloOhjeAlaikaiselleUrl,
  omaisuusluetteloOhjeUrl,
  paatostiliOhjeAlaikaiselleUrl,
  paatostiliOhjeTaysikaiselleUrl,
  vuositiliOhjeAlaikaiselleUrl,
  vuositiliOhjeTaysikaiselleUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Perustiedot'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const AloitusPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const userStore = useAsiointiUserStore()
    const {
      vaatimus: { isPrincipalUnderaged, tilivelvollisuusType, asiaType },
    } = getTilintarkastusStore()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'
    const onErrorRef = useRef<HTMLDivElement>(null)

    const lueLisaaExternalLink = (): string => {
      switch (asiaType) {
        case 'OMAISUUSLUETTELO':
          return isPrincipalUnderaged
            ? omaisuusluetteloOhjeAlaikaiselleUrl(userStore.lang)
            : omaisuusluetteloOhjeUrl(userStore.lang)
        case 'VUOSITILI':
          return isPrincipalUnderaged
            ? vuositiliOhjeAlaikaiselleUrl(userStore.lang)
            : vuositiliOhjeTaysikaiselleUrl(userStore.lang)
        default:
          return isPrincipalUnderaged
            ? paatostiliOhjeAlaikaiselleUrl(userStore.lang)
            : paatostiliOhjeTaysikaiselleUrl(userStore.lang)
      }
    }

    return (
      <>
        <FormProvider {...form}>
          <ResponsiveBorderedContentBlock
            data-test-id={mkAloitussivuTestId('general-info-container')}
          >
            <PageStepHeading
              heading={t('aloitus')}
              steps={TILINTARKASTUS_STEPS}
            />
            <FormErrorSummary ref={onErrorRef} />

            <Paragraph>{t(translations[asiaType].info1)}</Paragraph>
            <Paragraph mt={verticalMarginToken}>
              {t(translations[asiaType].info2)}
            </Paragraph>
            <Block mt={verticalMarginToken} />
            <DividerLine mt={verticalMarginToken} />

            <Perustiedot />
            <Block mt={verticalMarginToken} />

            <DividerLine mt={verticalMarginToken} />

            {asiaType === 'OMAISUUSLUETTELO' && (
              <>
                <Heading variant="h3" mt={verticalMarginToken}>
                  {t('omaisuusluettelonTekeminen')}
                </Heading>
                <Block mt={verticalMarginToken} />
                <Paragraph mt={verticalMarginToken}>
                  {t('omaisuusluetteloOhje')}
                </Paragraph>
                <Paragraph mt={verticalMarginToken}>
                  {t('omaisuusluetteloOhjeListHeading')}
                </Paragraph>
                <Block mt={verticalMarginToken} />
                <OhjeList>
                  <li>{t('omaisuuluetteloOhjelist1')}</li>
                  <li>{t('omaisuuluetteloOhjelist2')}</li>
                </OhjeList>
                {tilivelvollisuusType ===
                  TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA && (
                  <Block mt={verticalMarginToken}>
                    <Expander>
                      <ExpanderTitleButton>
                        {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOtsikko')}
                      </ExpanderTitleButton>
                      <ExpanderContent>
                        <Block
                          data-test-id={mkAloitussivuTestId(
                            'tilivelvollisuus-yhdessa-ohje'
                          )}
                        >
                          <Paragraph mb={verticalMarginToken}>
                            <Text>
                              {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOhje1')}
                            </Text>
                          </Paragraph>
                          <OhjeList>
                            <li>
                              {t(
                                'nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista1'
                              )}
                            </li>
                            <li>
                              {t(
                                'nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista2'
                              )}
                            </li>
                            <li>
                              {t(
                                'nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista3'
                              )}
                            </li>
                            <li>
                              {`${t(
                                'nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista4'
                              )} `}
                              <Text variant={'bold'}>{`${t(
                                'valtakirja'
                              )}.`}</Text>
                            </li>
                          </OhjeList>
                        </Block>
                      </ExpanderContent>
                    </Expander>
                  </Block>
                )}
                <Block mt={verticalMarginToken} />
                <Expander>
                  <ExpanderTitleButton>
                    {t('mitaTarvitsetOmaisuusluetteloon')}
                  </ExpanderTitleButton>
                  <ExpanderContent>
                    <Paragraph>
                      <Text variant="bold">
                        {t('omaisuusluettelossaTarvittavatTiedot')}
                      </Text>
                    </Paragraph>
                    <Paragraph>{t('omLtarvittavatTiedotOhje')}</Paragraph>

                    <Paragraph mt={verticalMarginToken}>
                      {t('omLtarvittavatTiedotListHeading')}
                    </Paragraph>
                    <Block mt={verticalMarginToken} />
                    <OhjeList>
                      <li>{t('olTieto1')}</li>
                      <li>{t('olTieto2')}</li>
                      <li>{t('olTieto3')}</li>
                      <li>{t('olTieto4')}</li>
                      <li>{t('olTieto5')}</li>
                      <li>{t('olTieto6')}</li>
                    </OhjeList>
                    <Block mt={verticalMarginToken} />

                    <Paragraph>
                      <Text variant="bold">
                        {t('omaisuusluettelonAsiakirjat')}
                      </Text>
                    </Paragraph>
                    <Paragraph>{t('omLtarvittavatAsiakirjatOhje')}</Paragraph>

                    <Paragraph mt={verticalMarginToken}>
                      {t('omLtarvittavatAsiakirjatListHeading')}
                    </Paragraph>
                    <Block mt={verticalMarginToken} />
                    <OhjeList>
                      <li>{t('olAsiakirjainfo1')}</li>
                      <li>{t('olAsiakirjainfo2')}</li>
                      <li>{t('olAsiakirjainfo3')}</li>
                      <li>{t('olAsiakirjainfo4')}</li>
                      <li>{t('olAsiakirjainfo5')}</li>
                      <li>{t('olAsiakirjainfo6')}</li>
                      <li>{t('olAsiakirjainfo7')}</li>
                    </OhjeList>
                  </ExpanderContent>
                </Expander>
              </>
            )}

            <Block mt={verticalMarginToken} />
            <ExternalLink
              data-test-id={mkAloitussivuTestId('ohje-link')}
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={lueLisaaExternalLink()}
            >
              {t(translations[asiaType].lueLisaa)}
            </ExternalLink>
            <DividerLine mt={verticalMarginToken} />
            <FormNavigationBar onErrorRef={onErrorRef} />
          </ResponsiveBorderedContentBlock>
        </FormProvider>
      </>
    )
  }
)

export default AloitusPage

const translations = {
  OMAISUUSLUETTELO: {
    info1: 'omaisuusluetteloAloitusInfo1',
    info2: 'omaisuusluetteloAloitusInfo2',
    lueLisaa: 'lueLisaaOmaisuusluettelosta',
  },
  PAATOSTILI: {
    info1: 'paatostiliJuurisivuOhje',
    info2: '',
    lueLisaa: 'lueLisaaPaatostilista',
  },
  VUOSITILI: {
    info1: 'vuositiliAloitusInfo',
    info2: '',
    lueLisaa: 'lueLisaaVuositilista',
  },
}

const OhjeList = styled.ul`
  list-style: disc outside;
  margin: 0 0 0 ${suomifiDesignTokens.spacing.l};
`
