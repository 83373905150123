import React from 'react'
import { Validate } from 'react-hook-form/dist/types/validator'
import { FieldValues, useFormContext } from 'react-hook-form'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Textarea } from 'suomifi-ui-components'
import { lowerFirst } from 'lodash'

const FormTextArea: React.FC<{
  'data-test-id': string
  labelText: string
  optionalText?: string
  hintText?: string
  value?: string
  rows?: number
  required?: boolean
  maxLength: number
  validate?: Validate<string | null | undefined, FieldValues>
  updateValue: (value: string) => void
}> = observer(
  ({
    'data-test-id': dataTestId,
    labelText,
    optionalText,
    hintText,
    value,
    rows = 3,
    required = false,
    maxLength,
    validate,
    updateValue,
  }) => {
    const [t] = useTranslation()
    const {
      register,
      formState: { errors },
    } = useFormContext()
    if (!dataTestId) {
      throw new Error('Missing data test id')
    }

    const { onChange, ref, name } = register(dataTestId, {
      required: {
        value: required,
        message: `${t('kirjoita')} ${lowerFirst(labelText)}`,
      },
      maxLength: {
        value: maxLength,
        message: t('virheTekstinPituusYlittaaMerkkimaaran', {
          maxLength,
        }),
      },
      validate,
      onChange: (event) => updateValue(event.target.value),
    })

    return (
      <Textarea
        data-test-id={dataTestId}
        fullWidth
        labelText={labelText}
        statusTextAriaLiveMode="off"
        characterLimit={maxLength}
        ariaCharactersRemainingText={(amount) =>
          `${maxLength - amount} / ${maxLength}`
        }
        ariaCharactersExceededText={(_amount) =>
          t('virheTekstinPituusYlittaaMerkkimaaran', { maxLength })
        }
        status={errors[dataTestId] ? 'error' : 'default'}
        statusText={(errors[dataTestId]?.message || '') as string}
        data-test-error-message={(errors[dataTestId]?.message || '') as string}
        onChange={onChange}
        ref={ref}
        rows={rows}
        name={name}
        {...{ optionalText, hintText }}
      >
        {value || ''}
      </Textarea>
    )
  }
)

export default FormTextArea
