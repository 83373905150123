import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
  AsiaPersonType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { InternalToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  edunvalvonta50VuottaRetention,
  edunvalvontaJulkinenHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaPysyvaRetention,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/common-edunvalvontavaltuutus-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'

export const edunvalvojanMaaraaminenHakemuksellaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_EDUNVALVOJAN_MAARAAMISEKSI,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e65bc9e7-1415-497a-b0cc-36cbad5c6b79'],
      defaultToivoAsiakirjaTypeId: 'e65bc9e7-1415-497a-b0cc-36cbad5c6b79',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['b49df9e8-ccb0-441b-9981-c60a08e7eedc'],
      defaultToivoAsiakirjaTypeId: 'b49df9e8-ccb0-441b-9981-c60a08e7eedc',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['4375f4af-4ec0-419e-9ece-095ba4477c0d'],
      defaultToivoAsiakirjaTypeId: '4375f4af-4ec0-419e-9ece-095ba4477c0d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUN_HENKILON_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['adce6e0f-9e0a-49bf-8be1-95d92c8184e9'],
      defaultToivoAsiakirjaTypeId: 'adce6e0f-9e0a-49bf-8be1-95d92c8184e9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['75eb4e9f-8179-4c07-a4c0-4869fe626f0f'],
      defaultToivoAsiakirjaTypeId: '75eb4e9f-8179-4c07-a4c0-4869fe626f0f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['35f1939a-f69a-4dbb-becc-4887120ed7ff'],
      defaultToivoAsiakirjaTypeId: '35f1939a-f69a-4dbb-becc-4887120ed7ff',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['0dee7ca1-b0a9-4f10-811a-36ce9927150f'],
      defaultToivoAsiakirjaTypeId: '0dee7ca1-b0a9-4f10-811a-36ce9927150f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['03d0a3c7-3808-47e4-b649-bc282582d248'],
      defaultToivoAsiakirjaTypeId: '03d0a3c7-3808-47e4-b649-bc282582d248',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['18f842ab-693b-4c86-a8cb-27539dcdae1d'],
      defaultToivoAsiakirjaTypeId: '18f842ab-693b-4c86-a8cb-27539dcdae1d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.POYTAKIRJA_HAKIJAN_HENKILOKOHTAISESTA_KUULEMISESTA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['18a0b5a6-404c-4b8f-b599-4142658a404c'],
      defaultToivoAsiakirjaTypeId: '18a0b5a6-404c-4b8f-b599-4142658a404c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['75778121-cedf-42d4-986b-e21d8c578ab3'],
      defaultToivoAsiakirjaTypeId: '75778121-cedf-42d4-986b-e21d8c578ab3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['b132cc8f-f953-44bc-8564-71ace5969608'],
      defaultToivoAsiakirjaTypeId: 'b132cc8f-f953-44bc-8564-71ace5969608',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['346555a1-ce9f-47aa-b555-133397ed3f4b'],
      defaultToivoAsiakirjaTypeId: '346555a1-ce9f-47aa-b555-133397ed3f4b',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d32169c9-27aa-4c96-8ea7-8a49667e9758'],
      defaultToivoAsiakirjaTypeId: 'd32169c9-27aa-4c96-8ea7-8a49667e9758',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RIKOSREKISTERIOTE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d78e3ca8-23bb-458a-89bc-7e630737a7a5'],
      defaultToivoAsiakirjaTypeId: 'd78e3ca8-23bb-458a-89bc-7e630737a7a5',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['bada2490-bc78-402b-a606-58496f1ca19b'],
      defaultToivoAsiakirjaTypeId: 'bada2490-bc78-402b-a606-58496f1ca19b',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a19fe271-3970-401f-b94d-de678b3e1388'],
      defaultToivoAsiakirjaTypeId: 'a19fe271-3970-401f-b94d-de678b3e1388',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9cab5769-2b33-477a-8ac0-9f8c4c41d4ad'],
      defaultToivoAsiakirjaTypeId: '9cab5769-2b33-477a-8ac0-9f8c4c41d4ad',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['44bacbb6-538a-47f8-9f55-e4df42b91c74', '5bb991b2-8ee3-4cb7-9c18-6eec894e64e1'],
      defaultToivoAsiakirjaTypeId: '44bacbb6-538a-47f8-9f55-e4df42b91c74',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ESITTELIJAN_ERIAVA_MIELIPIDE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a908bc04-0b36-427c-9355-72b10a821b83'],
      defaultToivoAsiakirjaTypeId: 'a908bc04-0b36-427c-9355-72b10a821b83',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OTE_HOLHOUSASIOIDEN_REKISTERISTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c6161d4d-7afc-448d-8869-736a885edee9'],
      defaultToivoAsiakirjaTypeId: 'c6161d4d-7afc-448d-8869-736a885edee9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['2e8b0a8f-7c25-471b-b24e-f18b311286c4'],
      defaultToivoAsiakirjaTypeId: '2e8b0a8f-7c25-471b-b24e-f18b311286c4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIREILLETULOILMOITUS,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['89e5de58-839e-4c1e-8b89-07c5ce87b1b6'],
      defaultToivoAsiakirjaTypeId: '89e5de58-839e-4c1e-8b89-07c5ce87b1b6',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.EDUNVALVOJAN_TIEDOT_SAAPUNEET,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.EDUNVALVOJAKSI_EHDOTETTU],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.HAKIJA, AsiaPersonRole.EDUNVALVOJAKSI_EHDOTETTU, AsiaPersonRole.MUU_HENKILO],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO, AsiaPersonType.AMMATTIHENKILO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.MYONTEINEN_PAATOS,
    manualRatkaisuTypeIds: [RatkaisuType.MYONTEINEN_PAATOS, RatkaisuType.KIELTEINEN_PAATOS, RatkaisuType.RAUENNUT],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.KV_ASIA, SpecifierType.TILIKAUSIEN_YHDISTAMINEN],
  },
  toimenpideMetadata: [],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.VALMISTELTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMISTELTAVANA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.ESITTELIJA,
      defaultStartableStatusId: AsiaStatus.RATKAISUEHDOTUS_ANNETTU,
      defaultMovableStatusId: AsiaStatus.RATKAISTAVANA,
    },
    {
      asiaStatusId: AsiaStatus.RATKAISUEHDOTUS_ANNETTU,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.ESITTELIJA,
      defaultStartableStatusId: AsiaStatus.RATKAISTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.RATKAISTAVANA,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.RATKAISIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.VALMISTELTAVANA,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
  retentionMetadata: edunvalvontaPysyvaRetention,
  restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
}
