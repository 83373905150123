import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { action } from 'mobx'
import FormSimpleListInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSimpleListInput'
import React, { FC } from 'react'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const OmassaKaytossaOlevaOmaisuusForm: FC = observer(() => {
  const [t] = useTranslation()
  const { paamiehenOmaisuus } = getTili()
  const isTablet = useDeviceContext().tablet

  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkOmaisuusTestId('kaytossa-oleva-container')}>
      <Heading mb="xs" variant="h3">
        {t('henkilonKaytossaOlevaMuuOmaisuus')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t('ohjeOnkoHenkilollaOmaisuutta')}
      </Paragraph>

      <FormRadioButtonGroup
        required
        value={paamiehenOmaisuus.hasKaytossaOlevaOmaisuus?.toString()}
        updateValue={action((value) => {
          paamiehenOmaisuus.hasKaytossaOlevaOmaisuus = value === 'true'
        })}
        data-test-id={mkOmaisuusTestId('omassa-kaytossa-radio')}
        labelText={t('onkoHenkilollaOmaisuutta')}
        radioButtons={[
          { value: 'false', labelText: t('ei') },
          { value: 'true', labelText: t('kylla') },
        ]}
      />

      {paamiehenOmaisuus.hasKaytossaOlevaOmaisuus && (
        <Block mt={verticalMarginToken}>
          <FormSimpleListInput
            dataTestIdPrefix={mkOmaisuusTestId('kaytto-omaisuus')}
            description={t('kaytossaOlevaOmaisuusListInfo')}
            heading={t('kaytossaOlevaOmaisuusListHeading')}
            state={paamiehenOmaisuus.omaisuusOmassaKaytossa}
          />
        </Block>
      )}
      {paamiehenOmaisuus.hasKaytossaOlevaOmaisuus === false &&
        paamiehenOmaisuus.omaisuusOmassaKaytossa.find(
          (omaisuus) => !!omaisuus.value
        ) && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default OmassaKaytossaOlevaOmaisuusForm
