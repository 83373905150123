import {
  TILINTARKASTUS_ROUTES,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FieldValues, UseFormReturn } from 'react-hook-form'

export type TilintarkastusForm = UseFormReturn<FieldValues, unknown>

export type TilintarkastusRouteForms = Record<
  Extract<
    TilintarkastusRoutePath,
    | (typeof TILINTARKASTUS_ROUTES)['ALOITUS']
    | (typeof TILINTARKASTUS_ROUTES)['TOIMINTAKYKY_JA_ASUMISTIEDOT']
    | (typeof TILINTARKASTUS_ROUTES)['OMASSA_KAYTOSSA_OLEVA_OMAISUUS']
    | (typeof TILINTARKASTUS_ROUTES)['VARAT']
    | (typeof TILINTARKASTUS_ROUTES)['VELAT']
    | (typeof TILINTARKASTUS_ROUTES)['TULOT']
    | (typeof TILINTARKASTUS_ROUTES)['MENOT']
    | (typeof TILINTARKASTUS_ROUTES)['ELAKKEET_JA_ETUUDET']
    | (typeof TILINTARKASTUS_ROUTES)['KAYTTOVARAT']
    | (typeof TILINTARKASTUS_ROUTES)['SOPIMUKSET_JA_VAKUUTUKSET']
    | (typeof TILINTARKASTUS_ROUTES)['MUUT_MERKITTAVAT_TOIMET']
    | (typeof TILINTARKASTUS_ROUTES)['PALKKIO']
    | (typeof TILINTARKASTUS_ROUTES)['LISATIEDOT']
    | (typeof TILINTARKASTUS_ROUTES)['VALTAKIRJA']
    | (typeof TILINTARKASTUS_ROUTES)['YHTEYSTIEDOT']
    | (typeof TILINTARKASTUS_ROUTES)['YHTEENVETO']
  >,
  TilintarkastusForm
>

export type TilintarkastusFormControl = {
  isComplete: boolean
}

export type TilintarkastusRouteFormControls = Record<
  keyof TilintarkastusRouteForms,
  TilintarkastusFormControl
>

export const createTilintarkastusFormControls = (
  forms: TilintarkastusRouteForms
): TilintarkastusRouteFormControls => {
  return {
    [TILINTARKASTUS_ROUTES.ALOITUS]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.ALOITUS]
    ),
    [TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]
    ),
    [TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]
    ),
    [TILINTARKASTUS_ROUTES.VARAT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.VARAT]
    ),
    [TILINTARKASTUS_ROUTES.VELAT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.VELAT]
    ),
    [TILINTARKASTUS_ROUTES.TULOT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.TULOT]
    ),
    [TILINTARKASTUS_ROUTES.MENOT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.MENOT]
    ),
    [TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET]
    ),
    [TILINTARKASTUS_ROUTES.KAYTTOVARAT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.KAYTTOVARAT]
    ),
    [TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]
    ),
    [TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET]
    ),
    [TILINTARKASTUS_ROUTES.PALKKIO]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.PALKKIO]
    ),
    [TILINTARKASTUS_ROUTES.LISATIEDOT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.LISATIEDOT]
    ),
    [TILINTARKASTUS_ROUTES.VALTAKIRJA]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.VALTAKIRJA]
    ),
    [TILINTARKASTUS_ROUTES.YHTEYSTIEDOT]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.YHTEYSTIEDOT]
    ),
    [TILINTARKASTUS_ROUTES.YHTEENVETO]: createFormControls(
      forms[TILINTARKASTUS_ROUTES.YHTEENVETO]
    ),
  }
}

const resolveRouteErrors = (
  [key, { isComplete }]: [
    TilintarkastusRoutePath,
    TilintarkastusRouteFormControls[keyof TilintarkastusRouteFormControls]
  ],
  mapping: Partial<Record<TilintarkastusRoutePath, string>>
): string | undefined => {
  if (isComplete) {
    return undefined
  } else {
    const virhe = mapping[key]
    if (virhe) {
      return virhe
    } else {
      return 'virhe' // form is bad but no mapping exists
    }
  }
}

export const resolveFormErrors = (
  forms: Partial<TilintarkastusRouteFormControls>,
  errorToTranslationKeysMapping: Partial<
    Record<TilintarkastusRoutePath, string>
  >
): Record<TilintarkastusRoutePath, string> => {
  const { excludeSteps } = getTilintarkastusStore()
  const errors = Object.fromEntries(
    Object.entries(forms)
      .filter(([key]) => !excludeSteps.has(key))
      .map(([k, v]) => [
        k,
        resolveRouteErrors(
          [k as TilintarkastusRoutePath, v],
          errorToTranslationKeysMapping
        ),
      ])
      .filter(([, v]) => v)
  )
  return errors
}

export const hasErrors = ({ formState }: TilintarkastusForm): boolean => {
  return (
    formState.isSubmitted &&
    !formState.isValid &&
    // form with 0 registered inputs is invalid, thus check for errors
    Object.entries(formState.errors).length > 0
  )
}

export const isComplete = ({ formState }: TilintarkastusForm): boolean => {
  return (
    formState.isSubmitted &&
    // form with 0 registered inputs is invalid, thus check for errors
    (formState.isValid || Object.entries(formState.errors).length === 0)
  )
}

export const createFormControls = (
  form: TilintarkastusForm
): TilintarkastusFormControl => {
  return {
    isComplete: isComplete(form),
  }
}
