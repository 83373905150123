import { TilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store.type'
import { useLocation } from 'react-router-dom'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'

export const TILINTARKASTUS_APP_ROUTE_PREFIX =
  '/asioi/:personId/tili/:vaatimusId/'

export const TILINTARKASTUS_ROUTES = {
  ALOITUS: 'aloitus',
  TOIMINTAKYKY_JA_ASUMISTIEDOT: `toimintakyky-ja-asumistiedot`,
  OMASSA_KAYTOSSA_OLEVA_OMAISUUS: `omassa-kaytossa-oleva-omaisuus`,
  VARAT: `varat`,
  VELAT: `velat`,
  TULOT: `tulot`,
  MENOT: `menot`,
  ELAKKEET_JA_ETUUDET: `elakkeet-ja-etuudet`,
  KAYTTOVARAT: `kayttovarat`,
  SOPIMUKSET_JA_VAKUUTUKSET: `sopimukset-ja-vakuutukset`,
  MUUT_MERKITTAVAT_TOIMET: `muut-merkittavat-toimet`,
  PALKKIO: `palkkio`,
  LISATIEDOT: 'lisatiedot',
  YHTEYSTIEDOT: `yhteystiedot`,
  VALTAKIRJA: `valtakirja`,
  YHTEENVETO: `yhteenveto`,
  VALMIS: `valmis`,
  TULOSTUS: 'tulostus',
} as const

export const TILINTARKASTUS_STEPS: TilintarkastusRoutePath[] = [
  TILINTARKASTUS_ROUTES.ALOITUS,
  TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT,
  TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS,
  TILINTARKASTUS_ROUTES.KAYTTOVARAT,
  TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET,
  TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET,
  TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET,
  TILINTARKASTUS_ROUTES.VARAT,
  TILINTARKASTUS_ROUTES.VELAT,
  TILINTARKASTUS_ROUTES.TULOT,
  TILINTARKASTUS_ROUTES.MENOT,
  TILINTARKASTUS_ROUTES.PALKKIO,
  TILINTARKASTUS_ROUTES.LISATIEDOT,
  TILINTARKASTUS_ROUTES.YHTEYSTIEDOT,
  TILINTARKASTUS_ROUTES.VALTAKIRJA,
  TILINTARKASTUS_ROUTES.YHTEENVETO,
] as const

export type TilintarkastusRoutePath =
  (typeof TILINTARKASTUS_ROUTES)[keyof typeof TILINTARKASTUS_ROUTES]

export const currentNavigationSteps = (
  excludeSteps: TilintarkastusStore['excludeSteps'],
  location: ReturnType<typeof useLocation>
): { previous?: TilintarkastusRoutePath; next?: TilintarkastusRoutePath } => {
  const currentStep = resolveNavigationStep(location) as TilintarkastusRoutePath
  const activeOmaisuusluetteloSteps = TILINTARKASTUS_STEPS.filter(
    (step) => !excludeSteps.has(step)
  )
  const { steps, currentStepIndex } = {
    steps: activeOmaisuusluetteloSteps,
    currentStepIndex: activeOmaisuusluetteloSteps.indexOf(currentStep),
  }
  return {
    previous: steps[currentStepIndex - 1],
    next: steps[currentStepIndex + 1],
  }
}

export const resolveNavigationStep = (
  location: ReturnType<typeof useLocation>
): string | undefined => {
  return location.pathname.split('/').pop()
}

export const resolveTilintarkastusNavigationPath = (
  vaatimus: AsiointiSeurantaAsiavaatimus,
  step: TilintarkastusRoutePath
): string => {
  return `/asioi/${vaatimus.personId}/tili/${vaatimus.seurantaAsiavaatimusId}/${step}`
}
