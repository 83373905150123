import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { action, runInAction } from 'mobx'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const MuutToimetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const mitaMuitaMerkittaviaToimiaTestId = mkYleiskatsausTestId(
    'muut-merkittavat-toimet-input'
  )

  const { tili } = getTilintarkastusStore().lomake

  const translations =
    muutToimetTranslationKeys[
      isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI'
    ]

  return (
    <section data-test-id={mkYleiskatsausTestId('muut-toimet-container')}>
      <Heading variant="h3" mb="xs">
        {t(translations.heading)}
      </Heading>
      <Paragraph mb={verticalMarginToken}>{t(translations.infoText)}</Paragraph>

      <FormRadioButtonGroup
        required
        value={
          tili.isMuutMerkittavatToimet !== undefined
            ? tili.isMuutMerkittavatToimet.toString()
            : undefined
        }
        data-test-id={mkYleiskatsausTestId('onko-muita-toimia-radio')}
        labelText={t(translations.RadioButtonGroupLabel)}
        updateValue={(value) =>
          runInAction(() => {
            tili.isMuutMerkittavatToimet = value === 'true'
          })
        }
        radioButtons={[
          {
            labelText: t('ei'),
            value: 'false',
          },
          { labelText: t('kylla'), value: 'true' },
        ]}
      />

      {tili.isMuutMerkittavatToimet && (
        <>
          <Block mt={verticalMarginToken} />
          <FormTextArea
            data-test-id={mitaMuitaMerkittaviaToimiaTestId}
            labelText={t(translations.textAreaLabel)}
            maxLength={1000}
            required
            value={tili.muutMerkittavatToimet}
            validate={commonXssValidator(t)}
            updateValue={action((value) => {
              tili.muutMerkittavatToimet = value
            })}
          />
        </>
      )}

      {tili.isMuutMerkittavatToimet === false &&
        !!tili.muutMerkittavatToimet && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default MuutToimetForm

const muutToimetTranslationKeys = {
  OMAISUUSLUETTELO: {
    heading: 'vireillaJaTulossaOlevatToimet',
    infoText: 'ohjeIlmoitaVireillaJaTulossaOlevatToimet',
    RadioButtonGroupLabel: 'onkoTulossaMuitaMerkittaviaToimia',
    textAreaLabel: 'mitaToimiaOnTulossa',
  },
  TILI: {
    heading: 'vireillaTaiTilikaudenToimet',
    infoText: 'ohjeIlmoitaMerkittavatToimetHenkilolle',
    RadioButtonGroupLabel: 'onkoMuitaMerkittaviaToimia',
    textAreaLabel: 'mitaMuitaMerkittaviaToimia',
  },
}
