import * as DateFns from 'date-fns/fp'

export type LongIsoDateString =
  `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`

export const IsoDatetimeToUiDate = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy', parsedDate)
  }
  return ''
}

export const IsoDatetimeToUiDatetime = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy HH:mm', parsedDate)
  }
  return ''
}

export const displayDateToIsoString = (
  value: string | null
): LongIsoDateString => {
  const date = DateFns.parse(new Date(), 'd.M.yyyy', value)
  return date.toISOString() as LongIsoDateString
}
