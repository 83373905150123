import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const getContent = (tili: TiliData, t: TFunction): YhteenvetoGroupProps[] => {
  const content = []
  content.push({
    heading: t('onkoHenkilollaOmaisuutta'),
    rows: [
      tili.paamiehenOmaisuus.hasKaytossaOlevaOmaisuus !== undefined
        ? tili.paamiehenOmaisuus.hasKaytossaOlevaOmaisuus
          ? t('kylla')
          : t('ei')
        : undefined,
    ],
  })
  if (tili.paamiehenOmaisuus.hasKaytossaOlevaOmaisuus) {
    content.push({
      heading: t('kaytossaOlevaOmaisuusListHeading'),
      rows:
        tili.paamiehenOmaisuus.omaisuusOmassaKaytossa.map(
          ({ value }) => value
        ) || [],
    })
  }
  return content
}

const OmassaKaytossaOlevaOmaisuus: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('kaytossaOlevaOmaisuusHeading')}
      </Heading>
      <YhteenvetoGroups groups={getContent(tili, t)} />
    </>
  )
})

export default OmassaKaytossaOlevaOmaisuus
