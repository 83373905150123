import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const nimiasiatJulkinenEiHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatOsittainSalassaPidettavaErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}

export const commonNimiasiatAsiakirjaMetadata: AsiakirjaMetadata[] = [
  {
    asiakirjaTypeId: AsiakirjaType.NIMENMUUTOSHAKEMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LISASELVITYS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.VIRKATODISTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.SUOSTUMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
    restrictionMetadata: nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_PAATOS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTO_SOSIAALITOIMELTA,
    restrictionMetadata: nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },

  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYS_ASIAKKAALTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.HENKILON_NIMI_LAKI_VAATIMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO_NIMILAUTAKUNNALLE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.NIMILAUTAKUNNAN_LAUSUNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINEPYYNTO_ASIAKKAALLE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINE_ASIAKKAALTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUISTUTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.PAATOS_NIMENMUUTOKSESTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['c0420101-ce83-465f-ba15-8094bd9ee3e2', 'bb9f436a-6eb3-4dd2-af23-a71c6c5387db'],
    defaultToivoAsiakirjaTypeId: 'c0420101-ce83-465f-ba15-8094bd9ee3e2',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
    restrictionMetadata: nimiasiatJulkinenEiHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['6dae0105-29ea-4bfa-bdbd-3e6ae54f304d'],
    defaultToivoAsiakirjaTypeId: '6dae0105-29ea-4bfa-bdbd-3e6ae54f304d',
  },

  {
    asiakirjaTypeId: AsiakirjaType.MUISTIO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },

  {
    asiakirjaTypeId: AsiakirjaType.SUKUSELVITYS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },

  {
    asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
]
