/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontKorkotulo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const KorkotulotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const tilirivit = getTilirivit()

  const mkNewElement = (): FrontKorkotulo =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountIncomeType.KORKOTULOT,
      maksaja: '',
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('korkotulot-form')}>
      <Heading variant="h3" mb="xs">
        {t('korkotulotHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaKorkotulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={KorkotuloListItem}
        state={tilirivit.tulot.korkotulot}
        dataTestIdPrefix={mkTulotMenotTestId('korkotulot-form')}
        ModalContentComponent={KorkotuloModal}
        newEntry={mkNewElement}
        translationPrefix="korkotulot"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}

const KorkotuloModal: FC<FormModalProps<FrontKorkotulo>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-maksaja')}
          labelText={t('maksaja')}
          value={entry.maksaja}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, maksaja: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-tulotYhteensa')}
          labelText={t('tulotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const KorkotuloListItem: FC<FormElementProps<FrontKorkotulo>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={entry.maksaja}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(KorkotulotForm)
