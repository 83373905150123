import React, { RefObject, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import {
  getCountryByShortName,
  getCountryNamesInDisplayOrder,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/country.store'
import { useFormContext } from 'react-hook-form'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { Dropdown, DropdownItem, DropdownProps } from 'suomifi-ui-components'
import styled from 'styled-components'

export const StyledDropdown = styled(
  React.forwardRef<HTMLButtonElement, DropdownProps>(function StyledDropdown(
    props,
    ref
  ) {
    return (
      <Dropdown {...props} forwardedRef={ref as RefObject<HTMLButtonElement>} />
    )
  })
)`
  width: 100%;
  max-width: 290px;
  margin-bottom: 25px;
`

export const FormCountryDropdown: React.FC<{
  'data-test-id': string
  value: string
  updateValue: (countryCode: string) => void
}> = observer(({ 'data-test-id': dataTestId, value, updateValue }) => {
  if (!dataTestId) {
    throw new Error('Missing data test id')
  }
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()
  const [countryShortNamesInDisplayOrder, setCountryShortNamesInDisplayOrder] =
    useState(getCountryNamesInDisplayOrder(lang))

  useEffect(() => {
    setCountryShortNamesInDisplayOrder(getCountryNamesInDisplayOrder(lang))
  }, [lang])

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { onChange, ref, name } = register(dataTestId, {
    required: true,
    onChange: (event) => {
      const countryName = event.target.value
      const countryCode = getCountryByShortName(lang, countryName)
      updateValue(countryCode.countryId)
    },
  })

  return (
    <StyledDropdown
      data-test-id={dataTestId}
      labelText={t('maa')}
      value={value}
      status={errors[dataTestId] ? 'error' : 'default'}
      statusText={(errors[dataTestId]?.message || '') as string}
      onChange={(value: string) =>
        onChange(createOnChangeEvent(value, dataTestId))
      }
      ref={ref}
      name={name}
    >
      {countryShortNamesInDisplayOrder.map((name: string) => (
        <DropdownItem
          key={name}
          value={name}
          data-test-id={dataTestId + '-set-' + name}
        >
          {name}
        </DropdownItem>
      ))}
    </StyledDropdown>
  )
})

export default FormCountryDropdown
