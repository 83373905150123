import React, { ReactNode, useEffect, useState } from 'react'
import {
  Outlet,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  useAsiointiUser,
  useAsiointiUserStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { I18nextProvider } from 'react-i18next'
import {
  TILINTARKASTUS_ROUTES,
  TILINTARKASTUS_APP_ROUTE_PREFIX,
  resolveTilintarkastusNavigationPath,
  resolveNavigationStep,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { initTilinpaatosAsiointiI18n } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/localization/i18next'
import { suomifiDesignTokens } from 'suomifi-ui-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { centeredWidthLimited } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  getTilintarkastusStore,
  initTilintarkastusStore,
  isTilintarkastusStoreInitialized,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'
import UnexpectedError from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/UnexpectedError'
import PageLoading from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageLoading'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useRedirectUnauthenticatedToLogIn } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/login-redirect'
import PalkkioPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/PalkkioPage'
import TilintarkastusAppPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/TilintarkastusAppPage'
import ToimintakykyAsuminenPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/ToimintakykyAsuminenPage'
import {
  fetchDraftsIntoStore,
  getDraftBySourceId,
  useDraftStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import OmassaKaytossaOmaisuusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/OmassaKaytossaOmaisuusPage'
import { DraftData } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import AloitusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/AloitusPage'
import ElakkeetJaEtuudetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/ElakkeetJaEtuudetPage'
import KayttovaratPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/KayttovaratPage'
import SopimuksetJaVakuutuksetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/SopimuksetJaVakuutuksetPage'
import MuutMerkittavatToimetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/MuutMerkittavatToimetPage'
import YhteystiedotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/YhteystiedotPage'
import VaratPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/VaratPage'
import VelatPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/VelatPage'
import YhteenvetoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/YhteenvetoPage'
import ValmisPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/ValmisPage'
import ValtakirjaPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/ValtakirjaPage'
import {
  ASIOINTI_MAIN_ROUTES,
  getAsioiPathByPersonId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import {
  fetchEvtvDataIntoStore,
  getEvtvVaatimus,
  useEvtvStore,
  valitseHenkiloByPersonId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import TulotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/TulotPage'
import MenotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/MenotPage'
import {
  createTilintarkastusFormControls,
  TilintarkastusRouteForms,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import LisatiedotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/LisatiedotPage'
import TulostusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/TulostusPage'
import SimpleAppPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/TilintarkastusSimpleAppPage'

export const TilintarkastusApp: React.FC<{
  forms: TilintarkastusRouteForms
}> = observer(({ forms }) => {
  const { lang } = useAsiointiUserStore()
  const i18n = initTilinpaatosAsiointiI18n(lang)
  const navigate = useNavigate()
  const location = useLocation()

  const { vaatimusId, personId } = useParams()
  if (!vaatimusId) {
    if (personId) {
      navigate(getAsioiPathByPersonId(personId))
    } else {
      navigate(ASIOINTI_MAIN_ROUTES.JUURI)
    }
    return
  }
  if (!personId) {
    navigate(ASIOINTI_MAIN_ROUTES.JUURI)
    return
  }

  const evtvStore = useEvtvStore()
  const draftStore = useDraftStore()
  const [isLoading, setLoading] = useState(
    !isTilintarkastusStoreInitialized(vaatimusId)
  )
  const [initError, setInitError] = useState<Error | undefined>()

  const user = useAsiointiUser()

  const isRedirected = useRedirectUnauthenticatedToLogIn()
  if (isRedirected) {
    return null
  }

  useEffect(() => {
    const initFormStateWithDraft = async ({ visitedSteps }: DraftData) => {
      for (const step of visitedSteps) {
        for (const [path, form] of Object.entries(forms)) {
          if (path === step) {
            await form.handleSubmit(() => undefined)()
          }
        }
      }
    }
    const initAndHandleErrors = async () => {
      setLoading(true)
      try {
        if (evtvStore.isInitialized && draftStore.isInitialized) {
          for (const form of Object.values(forms)) {
            form.reset()
          }
          valitseHenkiloByPersonId(personId)
          const vaatimus = getEvtvVaatimus(vaatimusId)
          if (!vaatimus) {
            throw new Error(`Missing vaatimus ${vaatimusId}`)
          }
          const tiliDraft = getDraftBySourceId(vaatimus.seurantaAsiavaatimusId)
          initTilintarkastusStore(user, vaatimus, tiliDraft?.data)
          if (tiliDraft) {
            await initFormStateWithDraft(tiliDraft?.data)
          }
          setLoading(false)
        }
      } catch (err) {
        setInitError(
          err instanceof Error
            ? err
            : new Error('Tilintarkastus initialization failed')
        )
        setLoading(false)
      }
    }

    // useEffect is only called in a browser(-like) environment. If asiointi store initialization
    // is ever done server-side, we must take care to initialize the store from scratch for every request
    // to avoid mixing up different users' data (and the same user's data from different devices/tabs).
    if (isInsideBrowser()) {
      if (!isTilintarkastusStoreInitialized(vaatimusId)) {
        if (!initError) {
          void initAndHandleErrors()
        }
      }
    }
  }, [
    initError,
    vaatimusId,
    lang,
    user,
    forms,
    personId,
    evtvStore.isInitialized,
    draftStore.isInitialized,
  ])

  useEffect(() => {
    if (!evtvStore.isInitialized) {
      void fetchEvtvDataIntoStore()
    }
  }, [evtvStore.isInitialized])

  useEffect(() => {
    if (!draftStore.isInitialized) {
      void fetchDraftsIntoStore()
    }
  }, [draftStore.isInitialized])

  if (initError) {
    return (
      <UnexpectedError
        error={initError}
        data-test-id={mkTestId('init-error')}
      />
    )
  }

  if (isLoading) {
    return <PageLoading data-test-id={mkTestId('loader')} />
  }

  const store = getTilintarkastusStore()
  const vaatimus = getEvtvVaatimus(vaatimusId)
  const validStepsAfterSubmit = [
    TILINTARKASTUS_ROUTES.VALMIS,
    TILINTARKASTUS_ROUTES.TULOSTUS,
  ] as string[]
  const currentStep = resolveNavigationStep(location) ?? 'unknown'
  if (
    (store.submitStatus === 'submitted' || vaatimus.sentDate) &&
    !validStepsAfterSubmit.includes(currentStep)
  ) {
    navigate(
      resolveTilintarkastusNavigationPath(
        vaatimus,
        TILINTARKASTUS_ROUTES.VALMIS
      )
    )
  }

  return (
    <I18nextProvider i18n={i18n}>
      <App data-test-id={mkTestId('app')}>
        <Main>
          <MainContent>
            <Outlet />
          </MainContent>
        </Main>
      </App>
    </I18nextProvider>
  )
})

export const TilintarkastusAppRoutes = (
  forms: TilintarkastusRouteForms
): ReactNode => {
  const formControls = createTilintarkastusFormControls(forms)
  return (
    <Route
      path={`${TILINTARKASTUS_APP_ROUTE_PREFIX}*`}
      element={<TilintarkastusApp forms={forms} />}
    >
      <Route element={<TilintarkastusAppPage forms={formControls} />}>
        <Route
          path={TILINTARKASTUS_ROUTES.ALOITUS}
          element={<AloitusPage form={forms[TILINTARKASTUS_ROUTES.ALOITUS]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT}
          element={
            <ToimintakykyAsuminenPage
              form={forms[TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS}
          element={
            <OmassaKaytossaOmaisuusPage
              form={forms[TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.VARAT}
          element={<VaratPage form={forms[TILINTARKASTUS_ROUTES.VARAT]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.VELAT}
          element={<VelatPage form={forms[TILINTARKASTUS_ROUTES.VELAT]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.TULOT}
          element={<TulotPage form={forms[TILINTARKASTUS_ROUTES.TULOT]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.MENOT}
          element={<MenotPage form={forms[TILINTARKASTUS_ROUTES.MENOT]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET}
          element={
            <ElakkeetJaEtuudetPage
              form={forms[TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.KAYTTOVARAT}
          element={
            <KayttovaratPage form={forms[TILINTARKASTUS_ROUTES.KAYTTOVARAT]} />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET}
          element={
            <SopimuksetJaVakuutuksetPage
              form={forms[TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET}
          element={
            <MuutMerkittavatToimetPage
              form={forms[TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.PALKKIO}
          element={<PalkkioPage form={forms[TILINTARKASTUS_ROUTES.PALKKIO]} />}
        />
        <Route
          path={TILINTARKASTUS_ROUTES.LISATIEDOT}
          element={
            <LisatiedotPage form={forms[TILINTARKASTUS_ROUTES.LISATIEDOT]} />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.YHTEYSTIEDOT}
          element={
            <YhteystiedotPage
              form={forms[TILINTARKASTUS_ROUTES.YHTEYSTIEDOT]}
            />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.VALTAKIRJA}
          element={
            <ValtakirjaPage form={forms[TILINTARKASTUS_ROUTES.VALTAKIRJA]} />
          }
        />
        <Route
          path={TILINTARKASTUS_ROUTES.YHTEENVETO}
          element={
            <YhteenvetoPage
              formControls={formControls}
              form={forms[TILINTARKASTUS_ROUTES.YHTEENVETO]}
            />
          }
        />
        <Route path={TILINTARKASTUS_ROUTES.VALMIS} element={<ValmisPage />} />
      </Route>
      <Route element={<SimpleAppPage />}>
        <Route
          path={TILINTARKASTUS_ROUTES.TULOSTUS}
          element={<TulostusPage />}
        />
      </Route>
    </Route>
  )
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};
`

const App = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Main = styled.main`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.s} 0;

  @media ${device.tablet} {
    padding: ${suomifiDesignTokens.spacing.xl} ${suomifiDesignTokens.spacing.xl};
  }
`
