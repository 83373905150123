import { TFunction } from 'react-i18next'
import fp from 'lodash/fp'
import {
  formatDateAsLocal,
  formatNumberWithComma,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  AsiointiType,
  FrontHuoneisto,
  FrontKiinteisto,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiAddress } from 'tilintarkastus-common/src/vtj/types/asiointi-api.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/country.store'
import { Kayttotarkoitus } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const getArvoTilikaudenAlussaString = (
  arvoTilikaudenAlussaEur: number | undefined,
  t: TFunction
): string | undefined =>
  arvoTilikaudenAlussaEur !== undefined
    ? `${t('arvoTilikaudenAlussaEuroina')}: ${formatNumberWithComma(
        arvoTilikaudenAlussaEur
      )} €`
    : undefined

const getArvoTilikaudenLopussaString = (
  arvoTilikaudenLopussaEur: number | undefined,
  t: TFunction
): string | undefined =>
  arvoTilikaudenLopussaEur !== undefined
    ? `${t('arvoTilikaudenLopussaEuroina')}: ${formatNumberWithComma(
        arvoTilikaudenLopussaEur
      )} €`
    : undefined

const getArvoTehtavanAlussaString = (
  arvoTilikaudenAlussaEur: number | undefined,
  t: TFunction
): string | undefined =>
  arvoTilikaudenAlussaEur !== undefined
    ? `${t('arvoTehtavanAlkamispaivana', {
        alkupvm: '',
      })}: ${formatNumberWithComma(arvoTilikaudenAlussaEur)} €`
    : undefined

export const getArvoAlussaString = (
  t: TFunction,
  asiaType: AsiointiType,
  arvo: number | undefined
): string | undefined => getArvoTilikaudenAlussaString(arvo, t)

export const getArvoLopussaString = (
  t: TFunction,
  asiaType: AsiointiType,
  arvo: number | undefined
): string | undefined =>
  asiaType === AsiointiType.OMAISUUSLUETTELO
    ? getArvoTehtavanAlussaString(arvo, t)
    : getArvoTilikaudenLopussaString(arvo, t)

const getKappalemaaraTehtavanAlussaString = (
  kpl: number | undefined,
  t: TFunction
): string | undefined =>
  kpl !== undefined
    ? `${t('kappalemaaraTehtavanAlussa')}: ${kpl} ${t('kpl')}`
    : undefined

const getKappalemaaraTilikaudenLopussaString = (
  kpl: number | undefined,
  t: TFunction
): string | undefined =>
  kpl !== undefined
    ? `${t('kappalemaaraTilikaudenLopussa')}: ${kpl} ${t('kpl')}`
    : undefined

export const getKappalemaaraLopussaString = (
  t: TFunction,
  asiaType: AsiointiType,
  kpl: number | undefined
): string | undefined =>
  asiaType === AsiointiType.OMAISUUSLUETTELO
    ? getKappalemaaraTehtavanAlussaString(kpl, t)
    : getKappalemaaraTilikaudenLopussaString(kpl, t)

export const getKappalemaaraTilikaudenAlussaString = (
  t: TFunction,
  kpl: number | undefined
): string | undefined =>
  kpl !== undefined
    ? `${t('kappalemaaraTilikaudenAlussa')}: ${kpl} ${t('kpl')}`
    : undefined

export const getOmistusosuus = (
  osuus: number | undefined,
  t: TFunction
): string | undefined =>
  osuus !== undefined ? `${t('omistusosuus')}: ${osuus} %` : undefined

export const getOsakkeidenMaara = (
  kpl: number | undefined,
  t: TFunction
): string | undefined =>
  kpl !== undefined ? `${t('osakkeidenMaara')}: ${kpl} ${t('kpl')}` : undefined

export const getKayttotarkoitus = (
  t: TFunction,
  {
    kayttotarkoitus,
    tyhjillaanSelvitys,
  }: Pick<
    FrontKiinteisto | FrontHuoneisto,
    'kayttotarkoitus' | 'tyhjillaanSelvitys'
  >
): string | undefined =>
  kayttotarkoitus
    ? `${t('kayttotarkoitus_' + (kayttotarkoitus || ''))}${
        kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN && tyhjillaanSelvitys
          ? `. ${tyhjillaanSelvitys}`
          : ''
      }`
    : undefined

export const getVelallinenString = (
  t: TFunction,
  etunimi: string,
  sukunimi: string
): string => `${t('velallinen')} ${sukunimi}${etunimi ? ', ' : ''}${etunimi}`

const getOnlyBeginDateString = (t: TFunction, beginDate: string) =>
  ` ${formatDateAsLocal(beginDate)} ${t('alkaen')}`

const getOnlyEndDateString = (t: TFunction, endDate: string) =>
  ` ${formatDateAsLocal(endDate)} ${t('asti')}`

export const getPaivamaaraString = (
  t: TFunction,
  beginDate: string | undefined,
  endDate: string | undefined
): string => {
  if (beginDate) {
    if (endDate) {
      return ` ${formatDateAsLocal(beginDate)} - ${formatDateAsLocal(endDate)}`
    } else {
      return getOnlyBeginDateString(t, beginDate)
    }
  } else if (endDate) {
    return getOnlyEndDateString(t, endDate)
  }

  return ''
}

export const getIncomeForAccountingPeriod = (
  t: TFunction,
  value: number | undefined
): string =>
  value
    ? `${t('tulotTilikaudenAikana')}: ${formatNumberWithComma(value)} €`
    : ''

export const getExpenseForAccountingPeriod = (
  t: TFunction,
  value: number | undefined
): string =>
  value
    ? `${t('menotTilikaudenAikana')}: ${formatNumberWithComma(value)} €`
    : ''

export const getAddressString = (
  address: AsiointiAddress,
  lang: HolhousAsiointiLanguageCode
): string =>
  fp.flow(
    fp.compact,
    fp.join(', ')
  )([
    fp.flow(
      fp.compact,
      fp.join(' ')
    )([address.streetAddress, address.streetAddressExtra]),
    fp.flow(fp.compact, fp.join(' '))([address.postalCode, address.postOffice]),
    address.countryId ? getCountryById(address.countryId).shortName[lang] : '',
  ])
