import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { runTilintarkastusStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusApiClient } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/api/tilintarkastusAsiointiClient'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { fetchEvtvDataIntoStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'

export const submitTili = async (
  locale: HolhousAsiointiLanguageCode
): Promise<ApiResponse<unknown>> =>
  runTilintarkastusStoreFlow(function* (store) {
    store.submitStatus = 'in-progress'
    store.submitError = undefined
    const submitFn =
      store.vaatimus.asiaType === 'OMAISUUSLUETTELO'
        ? TilintarkastusApiClient.postOmaisuusluettelo
        : TilintarkastusApiClient.postPaatostili

    const response: ApiResponse<unknown> = yield submitFn({
      ...store.lomake,
      seurantaAsiavaatimusId: store.vaatimus.seurantaAsiavaatimusId,
      locale,
    })
    if (response.isOk) {
      store.submitStatus = 'submitted'
      store.vaatimus.sentDate = new Date().toISOString() as LongIsoDateString
      yield fetchEvtvDataIntoStore() // to force reset UI for other vaatimukset!!
    } else {
      store.submitStatus = 'none'
      store.submitError = response.errorData ?? {} // On network error there is no error data
    }
    return response
  })
