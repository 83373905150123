import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import ElakkeetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/ElakkeetForm'
import PalkkatulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/PalkkatulotForm'
import EtuudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/EtuudetForm'
import TyottomyysturvaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/TyottomyysturvaForm'
import VuokratulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/VuokratulotForm'
import OsinkotulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/OsinkotulotForm'
import KorkotulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/KorkotulotForm'
import MyyntitulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/MyyntitulotForm'
import MuutTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/tulot/MuutTulotForm'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const TulotPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('tulotHeading')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />

        <Paragraph>{t('tulotInfo1')}</Paragraph>
        <Paragraph mt="s">{t('tulotInfo2')}</Paragraph>

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <ElakkeetForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <PalkkatulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <EtuudetForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <TyottomyysturvaForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <VuokratulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <OsinkotulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <KorkotulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <MyyntitulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <MuutTulotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default TulotPage
