import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import MuutToimetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/MuutToimetForm'
import TestamenttiForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/TestamenttiForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const MuutMerkittavatToimetPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const onErrorRef = useRef<HTMLDivElement>(null)
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('muutMerkittavatToimet')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph>
          {t(
            isOmaisuusluettelo()
              ? 'omaisuusluetteloMuutMerkittavatToimetInfo1'
              : 'tilikausiMuutMerkittavatToimetInfo1'
          )}
        </Paragraph>
        <Paragraph mt="s">
          {isPrincipalUnderaged
            ? t('omaisuusluetteloMuutMerkittavatToimetInfo2_alaikainen')
            : t('omaisuusluetteloMuutMerkittavatToimetInfo2')}
        </Paragraph>

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <MuutToimetForm />

        {!isPrincipalUnderaged && (
          <>
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <TestamenttiForm />
          </>
        )}

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default MuutMerkittavatToimetPage
