import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  suomifiDesignTokens,
  Heading,
  Expander,
  ExpanderTitleButton,
  Text,
  ExpanderContent,
  Button,
  IconEdit,
  IconRemove,
  Block,
} from 'suomifi-ui-components'

export const FormListInputExpander: React.FC<
  PropsWithChildren & {
    heading: string
    headingRight: string
    subHeading?: string
    createDataTestId: (...arg: ChildTestId[]) => string
    onEdit: () => void
    onRemove: (deleteModalText: string) => void
    showSubHeadingWhileOpen?: boolean
  }
> = ({
  children,
  heading,
  headingRight,
  subHeading,
  createDataTestId,
  onEdit,
  onRemove,
  showSubHeadingWhileOpen,
}) => {
  const [t] = useTranslation()

  return (
    <Expander data-test-id={createDataTestId('expander-container')}>
      <ExpanderTitleButton
        data-test-id={createDataTestId('expander-title-button')}
      >
        <ExpanderTitleMainText>
          <ExpanderHeading
            variant="h4"
            data-test-id={createDataTestId('expander-heading')}
          >
            {heading}
          </ExpanderHeading>
          <Text
            smallScreen
            data-test-id={createDataTestId('expander-heading-count')}
          >
            {headingRight}
          </Text>
        </ExpanderTitleMainText>
        {(!open || showSubHeadingWhileOpen) && subHeading && (
          <ExpanderTitleSubHeading
            smallScreen
            data-test-id={createDataTestId('expander-subheading')}
          >
            {subHeading}
          </ExpanderTitleSubHeading>
        )}
      </ExpanderTitleButton>

      <ExpanderContent
        data-test-id={createDataTestId('expander-content-container')}
      >
        {children}
        <ExpanderModifyComponent>
          <Block mt="s" />
          <Button
            icon={<IconEdit />}
            onClick={onEdit}
            data-test-id={createDataTestId('expander-content-edit-button')}
          >
            {t('edit')}
          </Button>
          <Button
            icon={<IconRemove />}
            variant="secondary"
            onClick={() => onRemove(`${heading} ${headingRight}`)}
            data-test-id={createDataTestId('expander-content-remove-button')}
          >
            {t('remove')}
          </Button>
        </ExpanderModifyComponent>
      </ExpanderContent>
    </Expander>
  )
}

const ExpanderTitleMainText = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const ExpanderTitleSubHeading = styled(Text)`
  color: ${suomifiDesignTokens.colors.depthDark1};
`

const ExpanderHeading = styled(Heading)`
  color: ${suomifiDesignTokens.colors.highlightBase};
`

const ExpanderModifyComponent = styled.div`
  width: 100%;
  display: flex;
  gap: ${suomifiDesignTokens.spacing.s};
  justify-content: flex-end;
`
