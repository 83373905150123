import { Country } from 'holhous-common/src/vtj/country/country.type'

const data: Country[] = [
  {
    countryId: '004',
    countryCode2: 'AF',
    countryCode3: 'AFG',
    shortName: {
      fi: 'Afganistan',
      sv: 'Afghanistan',
      en: 'Afghanistan',
    },
    officialName: {
      fi: 'Afganistanin islamilainen tasavalta',
      sv: 'Islamiska republiken Afghanistan',
      en: 'Islamic Republic of Afghanistan',
    },
  },
  {
    countryId: '008',
    countryCode2: 'AL',
    countryCode3: 'ALB',
    shortName: {
      fi: 'Albania',
      sv: 'Albanien',
      en: 'Albania',
    },
    officialName: {
      fi: 'Albanian tasavalta',
      sv: 'Republiken Albanien',
      en: 'Republic of Albania',
    },
  },
  {
    countryId: '010',
    countryCode2: 'AQ',
    countryCode3: 'ATA',
    shortName: {
      fi: 'Antarktis',
      sv: 'Antarktis',
      en: 'Antarctic',
    },
    officialName: {
      fi: 'Antarktis',
      sv: 'Antarktis',
      en: 'Antarctic',
    },
  },
  {
    countryId: '012',
    countryCode2: 'DZ',
    countryCode3: 'DZA',
    shortName: {
      fi: 'Algeria',
      sv: 'Algeriet',
      en: 'Algeria',
    },
    officialName: {
      fi: 'Algerian demokraattinen kansantasavalta',
      sv: 'Demokratiska folkrepubliken Algeriet',
      en: 'Peoples Democratic Republic of Algeria',
    },
  },
  {
    countryId: '016',
    countryCode2: 'AS',
    countryCode3: 'ASM',
    shortName: {
      fi: 'Amerikan Samoa',
      sv: 'Amerikanska Samoa',
      en: 'American Samoa',
    },
    officialName: {
      fi: 'Amerikan Samoa',
      sv: 'Amerikanska Samoa',
      en: 'American Samoa',
    },
  },
  {
    countryId: '020',
    countryCode2: 'AD',
    countryCode3: 'AND',
    shortName: {
      fi: 'Andorra',
      sv: 'Andorra',
      en: 'Andorra',
    },
    officialName: {
      fi: 'Andorran ruhtinaskunta',
      sv: 'Furstendömet Andorra',
      en: 'Principality of Andorra',
    },
  },
  {
    countryId: '024',
    countryCode2: 'AO',
    countryCode3: 'AGO',
    shortName: {
      fi: 'Angola',
      sv: 'Angola',
      en: 'Angola',
    },
    officialName: {
      fi: 'Angolan tasavalta',
      sv: 'Republiken Angola',
      en: 'Republic of Angola',
    },
  },
  {
    countryId: '028',
    countryCode2: 'AG',
    countryCode3: 'ATG',
    shortName: {
      fi: 'Antigua ja Barbuda',
      sv: 'Antigua och Barbuda',
      en: 'Antigua and Barbuda',
    },
    officialName: {
      fi: 'Antigua ja Barbuda',
      sv: 'Antigua och Barbuda',
      en: 'Antigua and Barbuda',
    },
  },
  {
    countryId: '031',
    countryCode2: 'AZ',
    countryCode3: 'AZE',
    shortName: {
      fi: 'Azerbaidzhan',
      sv: 'Azerbajdzjan',
      en: 'Azerbaijan',
    },
    officialName: {
      fi: 'Azerbaidzhanin tasavalta',
      sv: 'Republiken Azerbajdzjan',
      en: 'Republic of Azerbaijan',
    },
  },
  {
    countryId: '032',
    countryCode2: 'AR',
    countryCode3: 'ARG',
    shortName: {
      fi: 'Argentiina',
      sv: 'Argentina',
      en: 'Argentina',
    },
    officialName: {
      fi: 'Argentiinan tasavalta',
      sv: 'Republiken Argentina',
      en: 'Argentine Republic',
    },
  },
  {
    countryId: '036',
    countryCode2: 'AU',
    countryCode3: 'AUS',
    shortName: {
      fi: 'Australia',
      sv: 'Australien',
      en: 'Australia',
    },
    officialName: {
      fi: 'Australia',
      sv: 'Australien',
      en: 'Australia',
    },
  },
  {
    countryId: '040',
    countryCode2: 'AT',
    countryCode3: 'AUT',
    shortName: {
      fi: 'Itävalta',
      sv: 'Österrike',
      en: 'Austria',
    },
    officialName: {
      fi: 'Itävallan tasavalta',
      sv: 'Republiken Österrike',
      en: 'Republic of Austria',
    },
  },
  {
    countryId: '044',
    countryCode2: 'BS',
    countryCode3: 'BHS',
    shortName: {
      fi: 'Bahama',
      sv: 'Bahamas',
      en: 'Bahamas',
    },
    officialName: {
      fi: 'Bahaman liittovaltio',
      sv: 'Statsförbundet Bahamaöarna',
      en: null,
    },
  },
  {
    countryId: '048',
    countryCode2: 'BH',
    countryCode3: 'BHR',
    shortName: {
      fi: 'Bahrain',
      sv: 'Bahrain',
      en: 'Bahrain',
    },
    officialName: {
      fi: 'Bahrainin kuningaskunta',
      sv: 'Konungariket Bahrain',
      en: 'Kingdom of Bahrain',
    },
  },
  {
    countryId: '050',
    countryCode2: 'BD',
    countryCode3: 'BGD',
    shortName: {
      fi: 'Bangladesh',
      sv: 'Bangladesh',
      en: 'Bangladesh',
    },
    officialName: {
      fi: 'Bangladeshin kansantasavalta',
      sv: 'Folkrepubliken Bangladesh',
      en: 'Peoples Republic of Bangladesh',
    },
  },
  {
    countryId: '051',
    countryCode2: 'AM',
    countryCode3: 'ARM',
    shortName: {
      fi: 'Armenia',
      sv: 'Armenien',
      en: 'Armenia',
    },
    officialName: {
      fi: 'Armenian tasavalta',
      sv: 'Republiken Armenien',
      en: 'Republic of Armenia',
    },
  },
  {
    countryId: '052',
    countryCode2: 'BB',
    countryCode3: 'BRB',
    shortName: {
      fi: 'Barbados',
      sv: 'Barbados',
      en: 'Barbados',
    },
    officialName: {
      fi: 'Barbados',
      sv: 'Barbados',
      en: 'Barbados',
    },
  },
  {
    countryId: '056',
    countryCode2: 'BE',
    countryCode3: 'BEL',
    shortName: {
      fi: 'Belgia',
      sv: 'Belgien',
      en: 'Belgium',
    },
    officialName: {
      fi: 'Belgian kuningaskunta',
      sv: 'Konungariket Belgien',
      en: 'Kingdom of Belgium',
    },
  },
  {
    countryId: '060',
    countryCode2: 'BM',
    countryCode3: 'BMU',
    shortName: {
      fi: 'Bermuda',
      sv: 'Bermuda',
      en: 'Bermuda',
    },
    officialName: {
      fi: 'Bermuda',
      sv: 'Bermuda',
      en: 'Bermuda',
    },
  },
  {
    countryId: '064',
    countryCode2: 'BT',
    countryCode3: 'BTN',
    shortName: {
      fi: 'Bhutan',
      sv: 'Bhutan',
      en: 'Bhutan',
    },
    officialName: {
      fi: 'Bhutanin kuningaskunta',
      sv: 'Konungariket Bhutan',
      en: 'Kingdom of Bhutan',
    },
  },
  {
    countryId: '068',
    countryCode2: 'BO',
    countryCode3: 'BOL',
    shortName: {
      fi: 'Bolivia',
      sv: 'Bolivia',
      en: 'Bolivia',
    },
    officialName: {
      fi: 'Bolivian monikansainen valtio',
      sv: 'Mångnationella staten Bolivia',
      en: 'Plurinational State of Bolivia',
    },
  },
  {
    countryId: '070',
    countryCode2: 'BA',
    countryCode3: 'BIH',
    shortName: {
      fi: 'Bosnia-Hertsegovina',
      sv: 'Bosnien-Hercegovina',
      en: 'Bosnia and Herzegov',
    },
    officialName: {
      fi: 'Bosnia ja Hertsegovina',
      sv: 'Bosnien och Hercegovina',
      en: 'Bosnia and Herzegovina',
    },
  },
  {
    countryId: '072',
    countryCode2: 'BW',
    countryCode3: 'BWA',
    shortName: {
      fi: 'Botswana',
      sv: 'Botswana',
      en: 'Botswana',
    },
    officialName: {
      fi: 'Botswanan tasavalta',
      sv: 'Republiken Botswana',
      en: 'Republic of Botswana',
    },
  },
  {
    countryId: '074',
    countryCode2: 'BV',
    countryCode3: 'BVT',
    shortName: {
      fi: 'Bouvet nsaari',
      sv: 'Bouvetön',
      en: 'Bouvet Island',
    },
    officialName: {
      fi: 'Bouvet nsaari',
      sv: 'Bouvetön',
      en: 'Bouvet Island',
    },
  },
  {
    countryId: '076',
    countryCode2: 'BR',
    countryCode3: 'BRA',
    shortName: {
      fi: 'Brasilia',
      sv: 'Brasilien',
      en: 'Brazil',
    },
    officialName: {
      fi: 'Brasilian liittotasavalta',
      sv: 'Förbundsrepubliken Brasilien',
      en: 'Federative Republic of Brazil',
    },
  },
  {
    countryId: '084',
    countryCode2: 'BZ',
    countryCode3: 'BLZ',
    shortName: {
      fi: 'Belize',
      sv: 'Belize',
      en: 'Belize',
    },
    officialName: {
      fi: 'Belize',
      sv: 'Belize',
      en: 'Belize',
    },
  },
  {
    countryId: '086',
    countryCode2: 'IO',
    countryCode3: 'IOT',
    shortName: {
      fi: 'Intian valtamer alue',
      sv: 'Terr Ind oceanen',
      en: 'Br Indian Ocean Terr',
    },
    officialName: {
      fi: 'Brittiläinen Intian valtameren alue',
      sv: 'Brittiska territoriet i Indiska oceanen',
      en: 'British Indian Ocean Territory',
    },
  },
  {
    countryId: '090',
    countryCode2: 'SB',
    countryCode3: 'SLB',
    shortName: {
      fi: 'Salomonsaaret',
      sv: 'Salomonöarna',
      en: 'Solomon Islands',
    },
    officialName: {
      fi: 'Salomonsaaret',
      sv: 'Salomonöarna',
      en: null,
    },
  },
  {
    countryId: '092',
    countryCode2: 'VG',
    countryCode3: 'VGB',
    shortName: {
      fi: 'Brit Neitsytsaaret',
      sv: 'Brit Jungfruöarna',
      en: 'Virgin Isl British',
    },
    officialName: {
      fi: 'Brittiläiset Neitsytsaaret',
      sv: 'Brittiska Jungfruöarna',
      en: 'British Virgin Islands',
    },
  },
  {
    countryId: '096',
    countryCode2: 'BN',
    countryCode3: 'BRN',
    shortName: {
      fi: 'Brunei',
      sv: 'Brunei',
      en: 'Brunei',
    },
    officialName: {
      fi: 'Brunei Darussalamin valtio',
      sv: 'Brunei Darussalam',
      en: 'Brunei Darussalam',
    },
  },
  {
    countryId: '100',
    countryCode2: 'BG',
    countryCode3: 'BGR',
    shortName: {
      fi: 'Bulgaria',
      sv: 'Bulgarien',
      en: 'Bulgaria',
    },
    officialName: {
      fi: 'Bulgarian tasavalta',
      sv: 'Republiken Bulgarien',
      en: 'Republic of Bulgaria',
    },
  },
  {
    countryId: '104',
    countryCode2: 'MM',
    countryCode3: 'MMR',
    shortName: {
      fi: 'Myanmar',
      sv: 'Myanmar',
      en: 'Myanmar',
    },
    officialName: {
      fi: 'Myanmarin liiton tasavalta',
      sv: 'Republiken Unionen Myanmar',
      en: 'Republic of the Union of Myanmar',
    },
  },
  {
    countryId: '108',
    countryCode2: 'BI',
    countryCode3: 'BDI',
    shortName: {
      fi: 'Burundi',
      sv: 'Burundi',
      en: 'Burundi',
    },
    officialName: {
      fi: 'Burundin tasavalta',
      sv: 'Republiken Burundi',
      en: 'Republic of Burundi',
    },
  },
  {
    countryId: '112',
    countryCode2: 'BY',
    countryCode3: 'BLR',
    shortName: {
      fi: 'Valko-Venäjä',
      sv: 'Belarus',
      en: 'Belarus',
    },
    officialName: {
      fi: 'Valko-Venäjän tasavalta',
      sv: 'Republiken Belarus',
      en: 'Republic of Belarus',
    },
  },
  {
    countryId: '116',
    countryCode2: 'KH',
    countryCode3: 'KHM',
    shortName: {
      fi: 'Kambodzha',
      sv: 'Kambodja',
      en: 'Cambodia',
    },
    officialName: {
      fi: 'Kambodzhan kuningaskunta',
      sv: 'Konungariket Kambodja',
      en: 'Kingdom of Cambodia',
    },
  },
  {
    countryId: '120',
    countryCode2: 'CM',
    countryCode3: 'CMR',
    shortName: {
      fi: 'Kamerun',
      sv: 'Kamerun',
      en: 'Cameroon',
    },
    officialName: {
      fi: 'Kamerunin tasavalta',
      sv: 'Republiken Kamerun',
      en: 'Republic of Cameroon',
    },
  },
  {
    countryId: '124',
    countryCode2: 'CA',
    countryCode3: 'CAN',
    shortName: {
      fi: 'Kanada',
      sv: 'Kanada',
      en: 'Canada',
    },
    officialName: {
      fi: 'Kanada',
      sv: 'Kanada',
      en: 'Canada',
    },
  },
  {
    countryId: '132',
    countryCode2: 'CV',
    countryCode3: 'CPV',
    shortName: {
      fi: 'Kap Verde',
      sv: 'Kap Verde',
      en: 'Cabo Verde',
    },
    officialName: {
      fi: 'Kap Verden tasavalta',
      sv: 'Republiken Kap Verde',
      en: 'Republic of Cabo Verde',
    },
  },
  {
    countryId: '136',
    countryCode2: 'KY',
    countryCode3: 'CYM',
    shortName: {
      fi: 'Caymansaaret',
      sv: 'Caymanöarna',
      en: 'Cayman Islands',
    },
    officialName: {
      fi: 'Caymansaaret',
      sv: 'Caymanöarna',
      en: 'Cayman Islands',
    },
  },
  {
    countryId: '140',
    countryCode2: 'CF',
    countryCode3: 'CAF',
    shortName: {
      fi: 'Keski-Afrikka',
      sv: 'Centralafrikan',
      en: 'Central African Rep',
    },
    officialName: {
      fi: 'Keski-Afrikan tasavalta',
      sv: 'Centralafrikanska republiken',
      en: 'Central African Republic',
    },
  },
  {
    countryId: '144',
    countryCode2: 'LK',
    countryCode3: 'LKA',
    shortName: {
      fi: 'Sri Lanka',
      sv: 'Sri Lanka',
      en: 'Sri Lanka',
    },
    officialName: {
      fi: 'Sri Lankan demokraattinen sosialistinen tasavalta',
      sv: 'Republiken Sri Lanka',
      en: null,
    },
  },
  {
    countryId: '148',
    countryCode2: 'TD',
    countryCode3: 'TCD',
    shortName: {
      fi: 'Tsad',
      sv: 'Tchad',
      en: 'Chad',
    },
    officialName: {
      fi: 'Tsadin tasavalta',
      sv: 'Republiken Tchad',
      en: null,
    },
  },
  {
    countryId: '152',
    countryCode2: 'CL',
    countryCode3: 'CHL',
    shortName: {
      fi: 'Chile',
      sv: 'Chile',
      en: 'Chile',
    },
    officialName: {
      fi: 'Chilen tasavalta',
      sv: 'Republiken Chile',
      en: 'Republic of Chile',
    },
  },
  {
    countryId: '156',
    countryCode2: 'CN',
    countryCode3: 'CHN',
    shortName: {
      fi: 'Kiina',
      sv: 'Kina',
      en: 'China',
    },
    officialName: {
      fi: 'Kiinan kansantasavalta',
      sv: 'Folkrepubliken Kina',
      en: 'Peoples Republic of China',
    },
  },
  {
    countryId: '158',
    countryCode2: 'TW',
    countryCode3: 'TWN',
    shortName: {
      fi: 'Taiwan',
      sv: 'Taiwan',
      en: 'Taiwan',
    },
    officialName: {
      fi: 'Taiwan',
      sv: 'Taiwan',
      en: 'Taiwan',
    },
  },
  {
    countryId: '162',
    countryCode2: 'CX',
    countryCode3: 'CXR',
    shortName: {
      fi: 'Joulusaari',
      sv: 'Julön',
      en: 'Christmas Island',
    },
    officialName: {
      fi: 'Joulusaari',
      sv: 'Julön',
      en: 'Christmas Island',
    },
  },
  {
    countryId: '166',
    countryCode2: 'CC',
    countryCode3: 'CCK',
    shortName: {
      fi: 'Kookossaaret',
      sv: 'Kocosöarna',
      en: 'Cocos Islands',
    },
    officialName: {
      fi: 'Kookossaaret',
      sv: 'Kocosöarna',
      en: 'Cocos Islands',
    },
  },
  {
    countryId: '170',
    countryCode2: 'CO',
    countryCode3: 'COL',
    shortName: {
      fi: 'Kolumbia',
      sv: 'Colombia',
      en: 'Colombia',
    },
    officialName: {
      fi: 'Kolumbian tasavalta',
      sv: 'Republiken Colombia',
      en: 'Republic of Colombia',
    },
  },
  {
    countryId: '174',
    countryCode2: 'KM',
    countryCode3: 'COM',
    shortName: {
      fi: 'Komorit',
      sv: 'Komorerna',
      en: 'Comoros',
    },
    officialName: {
      fi: 'Komorien liitto',
      sv: 'Unionen Komorerna',
      en: 'Union of the Comoros',
    },
  },
  {
    countryId: '175',
    countryCode2: 'YT',
    countryCode3: 'MYT',
    shortName: {
      fi: 'Mayotte',
      sv: 'Mayotte',
      en: 'Mayotte',
    },
    officialName: {
      fi: 'Mayotte',
      sv: 'Mayotte',
      en: 'Mayotte',
    },
  },
  {
    countryId: '178',
    countryCode2: 'CG',
    countryCode3: 'COG',
    shortName: {
      fi: 'Kongo Tv',
      sv: 'Kongo Rep',
      en: 'Congo',
    },
    officialName: {
      fi: 'Kongon tasavalta',
      sv: 'Republiken Kongo',
      en: 'Republic of the Congo',
    },
  },
  {
    countryId: '180',
    countryCode2: 'CD',
    countryCode3: 'COD',
    shortName: {
      fi: 'Kongo Dem Tv',
      sv: 'Kongo Dem R',
      en: 'Congo Democratic Rep',
    },
    officialName: {
      fi: 'Kongon demokraattinen tasavalta',
      sv: 'Demokratiska republiken Kongo',
      en: 'Democratic Republic of the Congo',
    },
  },
  {
    countryId: '184',
    countryCode2: 'CK',
    countryCode3: 'COK',
    shortName: {
      fi: 'Cookinsaaret',
      sv: 'Cooköarna',
      en: 'Cook Islands',
    },
    officialName: {
      fi: 'Cookinsaaret',
      sv: 'Cooköarna',
      en: 'Cook Islands',
    },
  },
  {
    countryId: '188',
    countryCode2: 'CR',
    countryCode3: 'CRI',
    shortName: {
      fi: 'Costa Rica',
      sv: 'Costa Rica',
      en: 'Costa Rica',
    },
    officialName: {
      fi: 'Costa Rican tasavalta',
      sv: 'Republiken Costa Rica',
      en: 'Republic of Costa Rica',
    },
  },
  {
    countryId: '191',
    countryCode2: 'HR',
    countryCode3: 'HRV',
    shortName: {
      fi: 'Kroatia',
      sv: 'Kroatien',
      en: 'Croatia',
    },
    officialName: {
      fi: 'Kroatian tasavalta',
      sv: 'Republiken Kroatien',
      en: 'Republic of Croatia',
    },
  },
  {
    countryId: '192',
    countryCode2: 'CU',
    countryCode3: 'CUB',
    shortName: {
      fi: 'Kuuba',
      sv: 'Kuba',
      en: 'Cuba',
    },
    officialName: {
      fi: 'Kuuban tasavalta',
      sv: 'Republiken Cuba',
      en: 'Republic of Cuba',
    },
  },
  {
    countryId: '196',
    countryCode2: 'CY',
    countryCode3: 'CYP',
    shortName: {
      fi: 'Kypros',
      sv: 'Cypern',
      en: 'Cyprus',
    },
    officialName: {
      fi: 'Kyproksen tasavalta',
      sv: 'Republiken Cypern',
      en: 'Republic of Cyprus',
    },
  },
  {
    countryId: '203',
    countryCode2: 'CZ',
    countryCode3: 'CZE',
    shortName: {
      fi: 'Tsekki',
      sv: 'Tjeckien',
      en: 'Czechia',
    },
    officialName: {
      fi: 'Tsekin tasavalta',
      sv: 'Republiken Tjeckien',
      en: 'Czech Republic',
    },
  },
  {
    countryId: '204',
    countryCode2: 'BJ',
    countryCode3: 'BEN',
    shortName: {
      fi: 'Benin',
      sv: 'Benin',
      en: 'Benin',
    },
    officialName: {
      fi: 'Beninin tasavalta',
      sv: 'Republiken Benin',
      en: 'Republic of Benin',
    },
  },
  {
    countryId: '208',
    countryCode2: 'DK',
    countryCode3: 'DNK',
    shortName: {
      fi: 'Tanska',
      sv: 'Danmark',
      en: 'Denmark',
    },
    officialName: {
      fi: 'Tanskan kuningaskunta',
      sv: 'Konungariket Danmark',
      en: 'Kingdom of Denmark',
    },
  },
  {
    countryId: '212',
    countryCode2: 'DM',
    countryCode3: 'DMA',
    shortName: {
      fi: 'Dominica',
      sv: 'Dominica',
      en: 'Dominica',
    },
    officialName: {
      fi: 'Dominican yhteisö',
      sv: 'Samväldet Dominica',
      en: 'Commonwealth of Dominica',
    },
  },
  {
    countryId: '214',
    countryCode2: 'DO',
    countryCode3: 'DOM',
    shortName: {
      fi: 'Dominik Tv',
      sv: 'Dominik Rep',
      en: 'Dominican Republic',
    },
    officialName: {
      fi: 'Dominikaaninen tasavalta',
      sv: 'Dominikanska republiken',
      en: 'Dominican Republic',
    },
  },
  {
    countryId: '218',
    countryCode2: 'EC',
    countryCode3: 'ECU',
    shortName: {
      fi: 'Ecuador',
      sv: 'Ecuador',
      en: 'Ecuador',
    },
    officialName: {
      fi: 'Ecuadorin tasavalta',
      sv: 'Republiken Ecuador',
      en: 'Republic of Ecuador',
    },
  },
  {
    countryId: '222',
    countryCode2: 'SV',
    countryCode3: 'SLV',
    shortName: {
      fi: 'El Salvador',
      sv: 'El Salvador',
      en: 'El Salvador',
    },
    officialName: {
      fi: 'El Salvadorin tasavalta',
      sv: 'Republiken El Salvador',
      en: 'Republic of El Salvador',
    },
  },
  {
    countryId: '226',
    countryCode2: 'GQ',
    countryCode3: 'GNQ',
    shortName: {
      fi: 'Päiv Guinea',
      sv: 'Ekvat Guinea',
      en: 'Equatorial Guinea',
    },
    officialName: {
      fi: 'Päiväntasaajan Guinean tasavalta',
      sv: 'Republiken Ekvatorialguinea',
      en: 'Republic of Equatorial Guinea',
    },
  },
  {
    countryId: '231',
    countryCode2: 'ET',
    countryCode3: 'ETH',
    shortName: {
      fi: 'Etiopia',
      sv: 'Etiopien',
      en: 'Ethiopia',
    },
    officialName: {
      fi: 'Etiopian demokraattinen liittotasavalta',
      sv: 'Demokratiska förbundsrepubliken Etiopien',
      en: 'Federal Democratic Republic of Ethiopia',
    },
  },
  {
    countryId: '232',
    countryCode2: 'ER',
    countryCode3: 'ERI',
    shortName: {
      fi: 'Eritrea',
      sv: 'Eritrea',
      en: 'Eritrea',
    },
    officialName: {
      fi: 'Eritrean valtio',
      sv: 'Staten Eritrea',
      en: 'State of Eritrea',
    },
  },
  {
    countryId: '233',
    countryCode2: 'EE',
    countryCode3: 'EST',
    shortName: {
      fi: 'Viro',
      sv: 'Estland',
      en: 'Estonia',
    },
    officialName: {
      fi: 'Viron tasavalta',
      sv: 'Republiken Estland',
      en: 'Republic of Estonia',
    },
  },
  {
    countryId: '234',
    countryCode2: 'FO',
    countryCode3: 'FRO',
    shortName: {
      fi: 'Färsaaret',
      sv: 'Färöarna',
      en: 'Faroe Islands',
    },
    officialName: {
      fi: 'Färsaaret',
      sv: 'Färöarna',
      en: 'Faroe Islands',
    },
  },
  {
    countryId: '238',
    countryCode2: 'FK',
    countryCode3: 'FLK',
    shortName: {
      fi: 'Falklandinsaaret',
      sv: 'Falklandsöarna',
      en: 'Falkland Islands',
    },
    officialName: {
      fi: 'Falklandinsaaret',
      sv: 'Falklandsöarna',
      en: 'Falkland Islands',
    },
  },
  {
    countryId: '239',
    countryCode2: 'GS',
    countryCode3: 'SGS',
    shortName: {
      fi: 'Georgia ja Sandwichs',
      sv: 'Georgien och Sydsand',
      en: 'S Georgia and S Sand',
    },
    officialName: {
      fi: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
      sv: 'Sydgeorgien och Sydsandwichöarna',
      en: 'South Georgia and the South Sandwich Islands',
    },
  },
  {
    countryId: '242',
    countryCode2: 'FJ',
    countryCode3: 'FJI',
    shortName: {
      fi: 'Fidzi',
      sv: 'Fiji',
      en: 'Fiji',
    },
    officialName: {
      fi: 'Fidzin tasavalta',
      sv: 'Republiken Fiji',
      en: 'Republic of Fiji',
    },
  },
  {
    countryId: '246',
    countryCode2: 'FI',
    countryCode3: 'FIN',
    shortName: {
      fi: 'Suomi',
      sv: 'Finland',
      en: 'Finland',
    },
    officialName: {
      fi: 'Suomen tasavalta',
      sv: 'Republiken Finland',
      en: 'Republic of Finland',
    },
  },
  {
    countryId: '250',
    countryCode2: 'FR',
    countryCode3: 'FRA',
    shortName: {
      fi: 'Ranska',
      sv: 'Frankrike',
      en: 'France',
    },
    officialName: {
      fi: 'Ranskan tasavalta',
      sv: 'Republiken Frankrike',
      en: 'French Republic',
    },
  },
  {
    countryId: '254',
    countryCode2: 'GF',
    countryCode3: 'GUF',
    shortName: {
      fi: 'Ranskan Guayana',
      sv: 'Franska Guyana',
      en: 'French Guiana',
    },
    officialName: {
      fi: 'Ranskan Guayana',
      sv: 'Franska Guyana',
      en: null,
    },
  },
  {
    countryId: '258',
    countryCode2: 'PF',
    countryCode3: 'PYF',
    shortName: {
      fi: 'Ranskan Polynesia',
      sv: 'Franska Polynesien',
      en: 'French Polynesia',
    },
    officialName: {
      fi: 'Ranskan Polynesia',
      sv: 'Franska Polynesien',
      en: null,
    },
  },
  {
    countryId: '260',
    countryCode2: 'TF',
    countryCode3: 'ATF',
    shortName: {
      fi: 'Ranskan etel alueet',
      sv: 'Franska sydl territo',
      en: 'French Southern Terr',
    },
    officialName: {
      fi: 'Ranskan eteläiset alueet',
      sv: 'Franska sydliga territorier',
      en: null,
    },
  },
  {
    countryId: '262',
    countryCode2: 'DJ',
    countryCode3: 'DJI',
    shortName: {
      fi: 'Djibouti',
      sv: 'Djibouti',
      en: 'Djibouti',
    },
    officialName: {
      fi: 'Djiboutin tasavalta',
      sv: 'Republiken Djibouti',
      en: 'Republic of Djibouti',
    },
  },
  {
    countryId: '266',
    countryCode2: 'GA',
    countryCode3: 'GAB',
    shortName: {
      fi: 'Gabon',
      sv: 'Gabon',
      en: 'Gabon',
    },
    officialName: {
      fi: 'Gabonin tasavalta',
      sv: 'Republiken Gabon',
      en: 'Gabonese Republic',
    },
  },
  {
    countryId: '268',
    countryCode2: 'GE',
    countryCode3: 'GEO',
    shortName: {
      fi: 'Georgia',
      sv: 'Georgien',
      en: 'Georgia',
    },
    officialName: {
      fi: 'Georgia',
      sv: 'Georgien',
      en: 'Georgia',
    },
  },
  {
    countryId: '270',
    countryCode2: 'GM',
    countryCode3: 'GMB',
    shortName: {
      fi: 'Gambia',
      sv: 'Gambia',
      en: 'Gambia',
    },
    officialName: {
      fi: 'Gambian islamilainen tasavalta',
      sv: 'Islamiska republiken Gambia',
      en: 'Islamic Republic of the Gambia',
    },
  },
  {
    countryId: '275',
    countryCode2: 'PS',
    countryCode3: 'PSE',
    shortName: {
      fi: 'Palestiinan itsehall',
      sv: 'De palestinska områd',
      en: 'Palestinian terr occ',
    },
    officialName: {
      fi: 'Palestiinan itsehallintoalue',
      sv: 'De palestinska områdena',
      en: null,
    },
  },
  {
    countryId: '276',
    countryCode2: 'DE',
    countryCode3: 'DEU',
    shortName: {
      fi: 'Saksa',
      sv: 'Tyskland',
      en: 'Germany',
    },
    officialName: {
      fi: 'Saksan liittotasavalta',
      sv: 'Förbundsrepubliken Tyskland',
      en: 'Federal Republic of Germany',
    },
  },
  {
    countryId: '288',
    countryCode2: 'GH',
    countryCode3: 'GHA',
    shortName: {
      fi: 'Ghana',
      sv: 'Ghana',
      en: 'Ghana',
    },
    officialName: {
      fi: 'Ghanan tasavalta',
      sv: 'Republiken Ghana',
      en: 'Republic of Ghana',
    },
  },
  {
    countryId: '292',
    countryCode2: 'GI',
    countryCode3: 'GIB',
    shortName: {
      fi: 'Gibraltar',
      sv: 'Gibraltar',
      en: 'Gibraltar',
    },
    officialName: {
      fi: 'Gibraltar',
      sv: 'Gibraltar',
      en: 'Gibraltar',
    },
  },
  {
    countryId: '296',
    countryCode2: 'KI',
    countryCode3: 'KIR',
    shortName: {
      fi: 'Kiribati',
      sv: 'Kiribati',
      en: 'Kiribati',
    },
    officialName: {
      fi: 'Kiribatin tasavalta',
      sv: 'Republiken Kiribati',
      en: 'Republic of Kiribati',
    },
  },
  {
    countryId: '300',
    countryCode2: 'GR',
    countryCode3: 'GRC',
    shortName: {
      fi: 'Kreikka',
      sv: 'Grekland',
      en: 'Greece',
    },
    officialName: {
      fi: 'Kreikan tasavalta',
      sv: 'Republiken Grekland',
      en: 'Hellenic Republic',
    },
  },
  {
    countryId: '304',
    countryCode2: 'GL',
    countryCode3: 'GRL',
    shortName: {
      fi: 'Grönlanti',
      sv: 'Grönland',
      en: 'Greenland',
    },
    officialName: {
      fi: 'Grönlanti',
      sv: 'Grönland',
      en: 'Greenland',
    },
  },
  {
    countryId: '308',
    countryCode2: 'GD',
    countryCode3: 'GRD',
    shortName: {
      fi: 'Grenada',
      sv: 'Grenada',
      en: 'Grenada',
    },
    officialName: {
      fi: 'Grenada',
      sv: 'Grenada',
      en: 'Grenada',
    },
  },
  {
    countryId: '312',
    countryCode2: 'GP',
    countryCode3: 'GLP',
    shortName: {
      fi: 'Guadeloupe',
      sv: 'Guadeloupe',
      en: 'Guadeloupe',
    },
    officialName: {
      fi: 'Guadeloupen departementti',
      sv: 'Departementet Guadeloupe',
      en: 'Department of Guadeloupe',
    },
  },
  {
    countryId: '316',
    countryCode2: 'GU',
    countryCode3: 'GUM',
    shortName: {
      fi: 'Guam',
      sv: 'Guam',
      en: 'Guam',
    },
    officialName: {
      fi: 'Guamin territorio',
      sv: 'Territoriet Guam',
      en: 'Territory of Guam',
    },
  },
  {
    countryId: '320',
    countryCode2: 'GT',
    countryCode3: 'GTM',
    shortName: {
      fi: 'Guatemala',
      sv: 'Guatemala',
      en: 'Guatemala',
    },
    officialName: {
      fi: 'Guatemalan tasavalta',
      sv: 'Republiken Guatemala',
      en: 'Republic of Guatemala',
    },
  },
  {
    countryId: '324',
    countryCode2: 'GN',
    countryCode3: 'GIN',
    shortName: {
      fi: 'Guinea',
      sv: 'Guinea',
      en: 'Guinea',
    },
    officialName: {
      fi: 'Guinean tasavalta',
      sv: 'Republiken Guinea',
      en: 'Republic of Guinea',
    },
  },
  {
    countryId: '328',
    countryCode2: 'GY',
    countryCode3: 'GUY',
    shortName: {
      fi: 'Guyana',
      sv: 'Guyana',
      en: 'Guyana',
    },
    officialName: {
      fi: 'Guyanan tasavalta',
      sv: 'Republiken Guyana',
      en: 'Republic of Guyana',
    },
  },
  {
    countryId: '332',
    countryCode2: 'HT',
    countryCode3: 'HTI',
    shortName: {
      fi: 'Haiti',
      sv: 'Haiti',
      en: 'Haiti',
    },
    officialName: {
      fi: 'Haitin tasavalta',
      sv: 'Republiken Haiti',
      en: 'Republic of Haiti',
    },
  },
  {
    countryId: '334',
    countryCode2: 'HM',
    countryCode3: 'HMD',
    shortName: {
      fi: 'Heard ja McDonaldins',
      sv: 'Heard-och McDonaldsö',
      en: 'Heard Island and McD',
    },
    officialName: {
      fi: 'Heard ja McDonaldinsaaret',
      sv: 'Heard- och McDonaldsöarna',
      en: 'Heard Island and McDonald Islands',
    },
  },
  {
    countryId: '336',
    countryCode2: 'VA',
    countryCode3: 'VAT',
    shortName: {
      fi: 'Vatikaani',
      sv: 'Vatikan',
      en: 'Holy See Vatican',
    },
    officialName: {
      fi: 'Vatikaanivaltio',
      sv: 'Vatikanstaten',
      en: null,
    },
  },
  {
    countryId: '340',
    countryCode2: 'HN',
    countryCode3: 'HND',
    shortName: {
      fi: 'Honduras',
      sv: 'Honduras',
      en: 'Honduras',
    },
    officialName: {
      fi: 'Hondurasin tasavalta',
      sv: 'Republiken Honduras',
      en: 'Republic of Honduras',
    },
  },
  {
    countryId: '344',
    countryCode2: 'HK',
    countryCode3: 'HKG',
    shortName: {
      fi: 'Hongkong',
      sv: 'Hongkong',
      en: 'Hong Kong',
    },
    officialName: {
      fi: 'Hongkongin erityishallintoalue',
      sv: 'den särskilda administrativa regionen Hongkong',
      en: 'Hong Kong Special Administrative Region',
    },
  },
  {
    countryId: '348',
    countryCode2: 'HU',
    countryCode3: 'HUN',
    shortName: {
      fi: 'Unkari',
      sv: 'Ungern',
      en: 'Hungary',
    },
    officialName: {
      fi: 'Unkari',
      sv: 'Ungern',
      en: 'Hungary',
    },
  },
  {
    countryId: '352',
    countryCode2: 'IS',
    countryCode3: 'ISL',
    shortName: {
      fi: 'Islanti',
      sv: 'Island',
      en: 'Iceland',
    },
    officialName: {
      fi: 'Islannin tasavalta',
      sv: 'Republiken Island',
      en: 'Republic of Iceland',
    },
  },
  {
    countryId: '356',
    countryCode2: 'IN',
    countryCode3: 'IND',
    shortName: {
      fi: 'Intia',
      sv: 'Indien',
      en: 'India',
    },
    officialName: {
      fi: 'Intian tasavalta',
      sv: 'Republiken Indien',
      en: 'Republic of India',
    },
  },
  {
    countryId: '360',
    countryCode2: 'ID',
    countryCode3: 'IDN',
    shortName: {
      fi: 'Indonesia',
      sv: 'Indonesien',
      en: 'Indonesia',
    },
    officialName: {
      fi: 'Indonesian tasavalta',
      sv: 'Republiken Indonesien',
      en: 'Republic of Indonesia',
    },
  },
  {
    countryId: '364',
    countryCode2: 'IR',
    countryCode3: 'IRN',
    shortName: {
      fi: 'Iran',
      sv: 'Iran',
      en: 'Iran Islamic Rep of',
    },
    officialName: {
      fi: 'Iranin islamilainen tasavalta',
      sv: 'Islamiska republiken Iran',
      en: 'Islamic Republic of Iran',
    },
  },
  {
    countryId: '368',
    countryCode2: 'IQ',
    countryCode3: 'IRQ',
    shortName: {
      fi: 'Irak',
      sv: 'Irak',
      en: 'Iraq',
    },
    officialName: {
      fi: 'Irakin tasavalta',
      sv: 'Republiken Irak',
      en: 'Republic of Iraq',
    },
  },
  {
    countryId: '372',
    countryCode2: 'IE',
    countryCode3: 'IRL',
    shortName: {
      fi: 'Irlanti',
      sv: 'Irland',
      en: 'Ireland',
    },
    officialName: {
      fi: 'Irlanti',
      sv: 'Irland',
      en: 'Ireland',
    },
  },
  {
    countryId: '376',
    countryCode2: 'IL',
    countryCode3: 'ISR',
    shortName: {
      fi: 'Israel',
      sv: 'Israel',
      en: 'Israel',
    },
    officialName: {
      fi: 'Israelin valtio',
      sv: 'Staten Israel',
      en: 'State of Israel',
    },
  },
  {
    countryId: '380',
    countryCode2: 'IT',
    countryCode3: 'ITA',
    shortName: {
      fi: 'Italia',
      sv: 'Italien',
      en: 'Italy',
    },
    officialName: {
      fi: 'Italian tasavalta',
      sv: 'Republiken Italien',
      en: 'Republic of Italy',
    },
  },
  {
    countryId: '384',
    countryCode2: 'CI',
    countryCode3: 'CIV',
    shortName: {
      fi: 'Norsunluurannikko',
      sv: 'Elfenbenskusten',
      en: 'Cote d lvoire',
    },
    officialName: {
      fi: 'Norsunluurannikon tasavalta',
      sv: 'Republiken Elfenbenskusten',
      en: null,
    },
  },
  {
    countryId: '388',
    countryCode2: 'JM',
    countryCode3: 'JAM',
    shortName: {
      fi: 'Jamaika',
      sv: 'Jamaica',
      en: 'Jamaica',
    },
    officialName: {
      fi: 'Jamaika',
      sv: 'Jamaica',
      en: 'Jamaica',
    },
  },
  {
    countryId: '392',
    countryCode2: 'JP',
    countryCode3: 'JPN',
    shortName: {
      fi: 'Japani',
      sv: 'Japan',
      en: 'Japan',
    },
    officialName: {
      fi: 'Japani',
      sv: 'Japan',
      en: 'Japan',
    },
  },
  {
    countryId: '398',
    countryCode2: 'KZ',
    countryCode3: 'KAZ',
    shortName: {
      fi: 'Kazakstan',
      sv: 'Kazakstan',
      en: 'Kazakhstan',
    },
    officialName: {
      fi: 'Kazakstanin tasavalta',
      sv: 'Republiken Kazakstan',
      en: 'Republic of Kazakhstan',
    },
  },
  {
    countryId: '400',
    countryCode2: 'JO',
    countryCode3: 'JOR',
    shortName: {
      fi: 'Jordania',
      sv: 'Jordanien',
      en: 'Jordan',
    },
    officialName: {
      fi: 'Jordanian hasemiittinen kuningaskunta',
      sv: 'Hashemitiska konungariket Jordanien',
      en: 'Hashemite Kingdom of Jordan',
    },
  },
  {
    countryId: '404',
    countryCode2: 'KE',
    countryCode3: 'KEN',
    shortName: {
      fi: 'Kenia',
      sv: 'Kenya',
      en: 'Kenya',
    },
    officialName: {
      fi: 'Kenian tasavalta',
      sv: 'Republiken Kenya',
      en: 'Republic of Kenya',
    },
  },
  {
    countryId: '408',
    countryCode2: 'KP',
    countryCode3: 'PRK',
    shortName: {
      fi: 'Korean kansantasav',
      sv: 'Folkrepubliken Korea',
      en: 'Korea Dem P Rep of',
    },
    officialName: {
      fi: 'Korean demokraattinen kansantasavalta',
      sv: 'Demokratiska folkrepubliken Korea',
      en: 'Democratic Peoples Republic of Korea',
    },
  },
  {
    countryId: '410',
    countryCode2: 'KR',
    countryCode3: 'KOR',
    shortName: {
      fi: 'Korean tasavalta',
      sv: 'Republiken Korea',
      en: 'Korea Republic of',
    },
    officialName: {
      fi: 'Korean tasavalta',
      sv: 'Republiken Korea',
      en: 'Republic of Korea',
    },
  },
  {
    countryId: '414',
    countryCode2: 'KW',
    countryCode3: 'KWT',
    shortName: {
      fi: 'Kuwait',
      sv: 'Kuwait',
      en: 'Kuwait',
    },
    officialName: {
      fi: 'Kuwaitin valtio',
      sv: 'Staten Kuwait',
      en: 'State of Kuwait',
    },
  },
  {
    countryId: '417',
    countryCode2: 'KG',
    countryCode3: 'KGZ',
    shortName: {
      fi: 'Kirgisia',
      sv: 'Kirgizistan',
      en: 'Kyrgyzstan',
    },
    officialName: {
      fi: 'Kirgisian tasavalta',
      sv: 'Republiken Kirgizistan',
      en: 'Kyrgyz Republic',
    },
  },
  {
    countryId: '418',
    countryCode2: 'LA',
    countryCode3: 'LAO',
    shortName: {
      fi: 'Laos',
      sv: 'Laos',
      en: 'Lao People s Dem Rep',
    },
    officialName: {
      fi: 'Laosin demokraattinen kansantasavalta',
      sv: 'Demokratiska folkrepubliken Laos',
      en: 'Lao People s Democratic Republic',
    },
  },
  {
    countryId: '422',
    countryCode2: 'LB',
    countryCode3: 'LBN',
    shortName: {
      fi: 'Libanon',
      sv: 'Libanon',
      en: 'Lebanon',
    },
    officialName: {
      fi: 'Libanonin tasavalta',
      sv: 'Republiken Libanon',
      en: 'Lebanese Republic',
    },
  },
  {
    countryId: '426',
    countryCode2: 'LS',
    countryCode3: 'LSO',
    shortName: {
      fi: 'Lesotho',
      sv: 'Lesotho',
      en: 'Lesotho',
    },
    officialName: {
      fi: 'Lesothon kuningaskunta',
      sv: 'Konungariket Lesotho',
      en: 'Kingdom of Lesotho',
    },
  },
  {
    countryId: '428',
    countryCode2: 'LV',
    countryCode3: 'LVA',
    shortName: {
      fi: 'Latvia',
      sv: 'Lettland',
      en: 'Latvia',
    },
    officialName: {
      fi: 'Latvian tasavalta',
      sv: 'Republiken Lettland',
      en: 'Republic of Latvia',
    },
  },
  {
    countryId: '430',
    countryCode2: 'LR',
    countryCode3: 'LBR',
    shortName: {
      fi: 'Liberia',
      sv: 'Liberia',
      en: 'Liberia',
    },
    officialName: {
      fi: 'Liberian tasavalta',
      sv: 'Republiken Liberia',
      en: 'Republic of Liberia',
    },
  },
  {
    countryId: '434',
    countryCode2: 'LY',
    countryCode3: 'LBY',
    shortName: {
      fi: 'Libya',
      sv: 'Libyen',
      en: 'Libya',
    },
    officialName: {
      fi: 'Libyan valtio',
      sv: 'Staten Libyen',
      en: 'State of Libya',
    },
  },
  {
    countryId: '438',
    countryCode2: 'LI',
    countryCode3: 'LIE',
    shortName: {
      fi: 'Liechtenstein',
      sv: 'Liechtenstein',
      en: 'Liechtenstein',
    },
    officialName: {
      fi: 'Liechtensteinin ruhtinaskunta',
      sv: 'Furstendömet Liechtenstein',
      en: 'Principality of Liechtenstein',
    },
  },
  {
    countryId: '440',
    countryCode2: 'LT',
    countryCode3: 'LTU',
    shortName: {
      fi: 'Liettua',
      sv: 'Litauen',
      en: 'Lithuania',
    },
    officialName: {
      fi: 'Liettuan tasavalta',
      sv: 'Republiken Litauen',
      en: 'Republic of Lithuania',
    },
  },
  {
    countryId: '442',
    countryCode2: 'LU',
    countryCode3: 'LUX',
    shortName: {
      fi: 'Luxemburg',
      sv: 'Luxemburg',
      en: 'Luxembourg',
    },
    officialName: {
      fi: 'Luxemburgin suurherttuakunta',
      sv: 'Storhertigdömet Luxemburg',
      en: 'Grand Duchy of Luxembourg',
    },
  },
  {
    countryId: '446',
    countryCode2: 'MO',
    countryCode3: 'MAC',
    shortName: {
      fi: 'Macao',
      sv: 'Macao',
      en: 'Macau',
    },
    officialName: {
      fi: 'Macaon erityishallintoalue',
      sv: 'den särskilda administrativa regionen Macao',
      en: 'Macau Special Administrative Region',
    },
  },
  {
    countryId: '450',
    countryCode2: 'MG',
    countryCode3: 'MDG',
    shortName: {
      fi: 'Madagaskar',
      sv: 'Madagaskar',
      en: 'Madagascar',
    },
    officialName: {
      fi: 'Madagaskarin tasavalta',
      sv: 'Republiken Madagaskar',
      en: 'Republic of Madagascar',
    },
  },
  {
    countryId: '454',
    countryCode2: 'MW',
    countryCode3: 'MWI',
    shortName: {
      fi: 'Malawi',
      sv: 'Malawi',
      en: 'Malawi',
    },
    officialName: {
      fi: 'Malawin tasavalta',
      sv: 'Republiken Malawi',
      en: 'Republic of Malawi',
    },
  },
  {
    countryId: '458',
    countryCode2: 'MY',
    countryCode3: 'MYS',
    shortName: {
      fi: 'Malesia',
      sv: 'Malaysia',
      en: 'Malaysia',
    },
    officialName: {
      fi: 'Malesia',
      sv: 'Malaysia',
      en: 'Malaysia',
    },
  },
  {
    countryId: '462',
    countryCode2: 'MV',
    countryCode3: 'MDV',
    shortName: {
      fi: 'Malediivit',
      sv: 'Maldiverna',
      en: 'Maldives',
    },
    officialName: {
      fi: 'Malediivien tasavalta',
      sv: 'Republiken Maldiverna',
      en: 'Republic of Maldives',
    },
  },
  {
    countryId: '466',
    countryCode2: 'ML',
    countryCode3: 'MLI',
    shortName: {
      fi: 'Mali',
      sv: 'Mali',
      en: 'Mali',
    },
    officialName: {
      fi: 'Malin tasavalta',
      sv: 'Republiken Mali',
      en: 'Republic of Mali',
    },
  },
  {
    countryId: '470',
    countryCode2: 'MT',
    countryCode3: 'MLT',
    shortName: {
      fi: 'Malta',
      sv: 'Malta',
      en: 'Malta',
    },
    officialName: {
      fi: 'Maltan tasavalta',
      sv: 'Republiken Malta',
      en: 'Republic of Malta',
    },
  },
  {
    countryId: '474',
    countryCode2: 'MQ',
    countryCode3: 'MTQ',
    shortName: {
      fi: 'Martinique',
      sv: 'Martinique',
      en: 'Martinique',
    },
    officialName: {
      fi: 'Martiniquen departementti',
      sv: 'Departementet Martinique',
      en: 'Department of Martinique',
    },
  },
  {
    countryId: '478',
    countryCode2: 'MR',
    countryCode3: 'MRT',
    shortName: {
      fi: 'Mauritania',
      sv: 'Mauretanien',
      en: 'Mauritania',
    },
    officialName: {
      fi: 'Mauritanian islamilainen tasavalta',
      sv: 'Islamiska republiken Mauretanien',
      en: 'Islamic Republic of Mauritania',
    },
  },
  {
    countryId: '480',
    countryCode2: 'MU',
    countryCode3: 'MUS',
    shortName: {
      fi: 'Mauritius',
      sv: 'Mauritius',
      en: 'Mauritius',
    },
    officialName: {
      fi: 'Mauritiuksen tasavalta',
      sv: 'Republiken Mauritius',
      en: 'Republic of Mauritius',
    },
  },
  {
    countryId: '484',
    countryCode2: 'MX',
    countryCode3: 'MEX',
    shortName: {
      fi: 'Meksiko',
      sv: 'Mexico',
      en: 'Mexico',
    },
    officialName: {
      fi: 'Meksikon yhdysvallat',
      sv: 'Mexicos förenta stater',
      en: 'United Mexican States',
    },
  },
  {
    countryId: '492',
    countryCode2: 'MC',
    countryCode3: 'MCO',
    shortName: {
      fi: 'Monaco',
      sv: 'Monaco',
      en: 'Monaco',
    },
    officialName: {
      fi: 'Monacon ruhtinaskunta',
      sv: 'Furstendömet Monaco',
      en: null,
    },
  },
  {
    countryId: '496',
    countryCode2: 'MN',
    countryCode3: 'MNG',
    shortName: {
      fi: 'Mongolia',
      sv: 'Mongoliet',
      en: 'Mongolia',
    },
    officialName: {
      fi: 'Mongolia',
      sv: 'Mongoliet',
      en: null,
    },
  },
  {
    countryId: '498',
    countryCode2: 'MD',
    countryCode3: 'MDA',
    shortName: {
      fi: 'Moldova',
      sv: 'Moldavien',
      en: 'Moldova',
    },
    officialName: {
      fi: 'Moldovan tasavalta',
      sv: 'Republiken Moldavien',
      en: 'Republic of Moldova',
    },
  },
  {
    countryId: '499',
    countryCode2: 'ME',
    countryCode3: 'MNE',
    shortName: {
      fi: 'Montenegro',
      sv: 'Montenegro',
      en: 'Montenegro',
    },
    officialName: {
      fi: 'Montenegro',
      sv: 'Montenegro',
      en: 'Montenegro',
    },
  },
  {
    countryId: '500',
    countryCode2: 'MS',
    countryCode3: 'MSR',
    shortName: {
      fi: 'Montserrat',
      sv: 'Montserrat',
      en: 'Montserrat',
    },
    officialName: {
      fi: 'Montserrat',
      sv: 'Montserrat',
      en: null,
    },
  },
  {
    countryId: '504',
    countryCode2: 'MA',
    countryCode3: 'MAR',
    shortName: {
      fi: 'Marokko',
      sv: 'Marocko',
      en: 'Morocco',
    },
    officialName: {
      fi: 'Marokon kuningaskunta',
      sv: 'Konungariket Marocko',
      en: 'Kingdom of Morocco',
    },
  },
  {
    countryId: '508',
    countryCode2: 'MZ',
    countryCode3: 'MOZ',
    shortName: {
      fi: 'Mosambik',
      sv: 'Mocambique',
      en: 'Mozambique',
    },
    officialName: {
      fi: 'Mosambikin tasavalta',
      sv: 'Republiken Mocambique',
      en: null,
    },
  },
  {
    countryId: '512',
    countryCode2: 'OM',
    countryCode3: 'OMN',
    shortName: {
      fi: 'Oman',
      sv: 'Oman',
      en: 'Oman',
    },
    officialName: {
      fi: 'Omanin Sulttaanikunta',
      sv: 'Sultanatet Oman',
      en: null,
    },
  },
  {
    countryId: '516',
    countryCode2: 'NA',
    countryCode3: 'NAM',
    shortName: {
      fi: 'Namibia',
      sv: 'Namibia',
      en: 'Namibia',
    },
    officialName: {
      fi: 'Namibian tasavalta',
      sv: 'Republiken Namibia',
      en: null,
    },
  },
  {
    countryId: '520',
    countryCode2: 'NR',
    countryCode3: 'NRU',
    shortName: {
      fi: 'Nauru',
      sv: 'Nauru',
      en: 'Nauru',
    },
    officialName: {
      fi: 'Naurun tasavalta',
      sv: 'Republiken Nauru',
      en: null,
    },
  },
  {
    countryId: '524',
    countryCode2: 'NP',
    countryCode3: 'NPL',
    shortName: {
      fi: 'Nepal',
      sv: 'Nepal',
      en: 'Nepal',
    },
    officialName: {
      fi: 'Nepalin demokraattinen liittotasavalta',
      sv: 'Demokratiska förbundsrepubliken Nepal',
      en: 'Federal Democratic Republic of Nepal',
    },
  },
  {
    countryId: '528',
    countryCode2: 'NL',
    countryCode3: 'NLD',
    shortName: {
      fi: 'Alankomaat',
      sv: 'Nederländerna',
      en: 'Netherlands',
    },
    officialName: {
      fi: 'Alankomaiden kuningaskunta',
      sv: 'Konungariket Nederländerna',
      en: 'Kingdom of the Netherlands',
    },
  },
  {
    countryId: '530',
    countryCode2: 'AN',
    countryCode3: 'ANT',
    shortName: {
      fi: 'Antillit',
      sv: 'Västindien',
      en: 'Netherlands Antilles',
    },
    officialName: {
      fi: 'Alankomaiden Antillit',
      sv: 'Nederländska Västindien',
      en: 'Netherlands Antilles',
    },
  },
  {
    countryId: '531',
    countryCode2: 'CW',
    countryCode3: 'CUW',
    shortName: {
      fi: 'Curaçao',
      sv: 'Curaçao',
      en: 'Curaçao',
    },
    officialName: {
      fi: 'Curaçao',
      sv: 'Curaçao',
      en: 'Curaçao',
    },
  },
  {
    countryId: '533',
    countryCode2: 'AW',
    countryCode3: 'ABW',
    shortName: {
      fi: 'Aruba',
      sv: 'Aruba',
      en: 'Aruba',
    },
    officialName: {
      fi: 'Aruba',
      sv: 'Aruba',
      en: 'Aruba',
    },
  },
  {
    countryId: '534',
    countryCode2: 'SX',
    countryCode3: 'SXM',
    shortName: {
      fi: 'Sint Maarten',
      sv: 'Sint Maarten',
      en: 'Sint Maarten',
    },
    officialName: {
      fi: 'Sint Maarten',
      sv: 'Sint Maarten',
      en: 'Sint Maarten',
    },
  },
  {
    countryId: '535',
    countryCode2: 'BQ',
    countryCode3: 'BES',
    shortName: {
      fi: 'Bonaire Sint Eustat',
      sv: 'Bonaire Sint Eustat',
      en: 'Bonaire Sint Eustat',
    },
    officialName: {
      fi: 'Bonaire Sint Eustatius ja Saba',
      sv: 'Bonaire Sint Eustatius och Saba',
      en: 'Bonaire Sint Eustatius and Saba',
    },
  },
  {
    countryId: '540',
    countryCode2: 'NC',
    countryCode3: 'NCL',
    shortName: {
      fi: 'Uusi-Kaledonia',
      sv: 'Nya Caledonien',
      en: 'New Caledonia',
    },
    officialName: {
      fi: 'Uusi-Kaledonia',
      sv: 'Nya Caledonien',
      en: null,
    },
  },
  {
    countryId: '548',
    countryCode2: 'VU',
    countryCode3: 'VUT',
    shortName: {
      fi: 'Vanuatu',
      sv: 'Vanuatu',
      en: 'Vanuatu',
    },
    officialName: {
      fi: 'Vanuatun tasavalta',
      sv: 'Republiken Vanuatu',
      en: null,
    },
  },
  {
    countryId: '554',
    countryCode2: 'NZ',
    countryCode3: 'NZL',
    shortName: {
      fi: 'Uusi-Seelanti',
      sv: 'Nya Zeeland',
      en: 'New Zealand',
    },
    officialName: {
      fi: 'Uusi-Seelanti',
      sv: 'Nya Zeeland',
      en: null,
    },
  },
  {
    countryId: '558',
    countryCode2: 'NI',
    countryCode3: 'NIC',
    shortName: {
      fi: 'Nicaragua',
      sv: 'Nicaragua',
      en: 'Nicaragua',
    },
    officialName: {
      fi: 'Nicaraguan tasavalta',
      sv: 'Republiken Nicaragua',
      en: null,
    },
  },
  {
    countryId: '562',
    countryCode2: 'NE',
    countryCode3: 'NER',
    shortName: {
      fi: 'Niger',
      sv: 'Niger',
      en: 'Niger',
    },
    officialName: {
      fi: 'Nigerin tasavalta',
      sv: 'Republiken Niger',
      en: null,
    },
  },
  {
    countryId: '566',
    countryCode2: 'NG',
    countryCode3: 'NGA',
    shortName: {
      fi: 'Nigeria',
      sv: 'Nigeria',
      en: 'Nigeria',
    },
    officialName: {
      fi: 'Nigerian liittotasavalta',
      sv: 'Förbundsrepubliken Nigeria',
      en: null,
    },
  },
  {
    countryId: '570',
    countryCode2: 'NU',
    countryCode3: 'NIU',
    shortName: {
      fi: 'Niue',
      sv: 'Niue',
      en: 'Niue',
    },
    officialName: {
      fi: 'Niue',
      sv: 'Niue',
      en: 'Niue',
    },
  },
  {
    countryId: '574',
    countryCode2: 'NF',
    countryCode3: 'NFK',
    shortName: {
      fi: 'Norfolkinsaari',
      sv: 'Norfolkön',
      en: 'Norfolk Island',
    },
    officialName: {
      fi: 'Norfolkinsaari',
      sv: 'Norfolkön',
      en: null,
    },
  },
  {
    countryId: '578',
    countryCode2: 'NO',
    countryCode3: 'NOR',
    shortName: {
      fi: 'Norja',
      sv: 'Norge',
      en: 'Norway',
    },
    officialName: {
      fi: 'Norjan kuningaskunta',
      sv: 'Konungariket Norge',
      en: null,
    },
  },
  {
    countryId: '580',
    countryCode2: 'MP',
    countryCode3: 'MNP',
    shortName: {
      fi: 'Pohjois-Mariaanit',
      sv: 'Nordmarianerna',
      en: 'Northern Mariana Isl',
    },
    officialName: {
      fi: 'Pohjois-Mariaanien liittovaltio',
      sv: 'Statsförbundet Nordmarianerna',
      en: null,
    },
  },
  {
    countryId: '581',
    countryCode2: 'UM',
    countryCode3: 'UMI',
    shortName: {
      fi: 'Tyynenmeren saaret',
      sv: 'Stillahavsöarna',
      en: 'US Outlying Islands',
    },
    officialName: {
      fi: 'Yhdysvaltain pienet erillissaaret',
      sv: 'Amerikanska Stillahavsöarna',
      en: null,
    },
  },
  {
    countryId: '583',
    countryCode2: 'FM',
    countryCode3: 'FSM',
    shortName: {
      fi: 'Mikronesia',
      sv: 'Mikronesien',
      en: 'Micronesia Federated',
    },
    officialName: {
      fi: 'Mikronesian liittovaltio',
      sv: 'Mikronesiska Federationen',
      en: null,
    },
  },
  {
    countryId: '584',
    countryCode2: 'MH',
    countryCode3: 'MHL',
    shortName: {
      fi: 'Marshallinsaaret',
      sv: 'Marshallöarna',
      en: 'Marshall Islands',
    },
    officialName: {
      fi: 'Marshallinsaarten tasavalta',
      sv: 'Republiken Marshallöarna',
      en: 'Republic of the Marshall Islands',
    },
  },
  {
    countryId: '585',
    countryCode2: 'PW',
    countryCode3: 'PLW',
    shortName: {
      fi: 'Palau',
      sv: 'Palau',
      en: 'Palau',
    },
    officialName: {
      fi: 'Palaun tasavalta',
      sv: 'Republiken Palau',
      en: null,
    },
  },
  {
    countryId: '586',
    countryCode2: 'PK',
    countryCode3: 'PAK',
    shortName: {
      fi: 'Pakistan',
      sv: 'Pakistan',
      en: 'Pakistan',
    },
    officialName: {
      fi: 'Pakistanin islamilainen tasavalta',
      sv: 'Islamiska republiken Pakistan',
      en: null,
    },
  },
  {
    countryId: '591',
    countryCode2: 'PA',
    countryCode3: 'PAN',
    shortName: {
      fi: 'Panama',
      sv: 'Panama',
      en: 'Panama',
    },
    officialName: {
      fi: 'Panaman tasavalta',
      sv: 'Republiken Panama',
      en: null,
    },
  },
  {
    countryId: '598',
    countryCode2: 'PG',
    countryCode3: 'PNG',
    shortName: {
      fi: 'Papua-Uusi-Guinea',
      sv: 'Papua Nya Guinea',
      en: 'Papua New Guinea',
    },
    officialName: {
      fi: 'Papua-Uuden-Guinean itsenäinen valtio',
      sv: 'Självständika staten Papua Nya Guinea',
      en: 'Independent State of Papua New Guinea',
    },
  },
  {
    countryId: '600',
    countryCode2: 'PY',
    countryCode3: 'PRY',
    shortName: {
      fi: 'Paraguay',
      sv: 'Paraguay',
      en: 'Paraguay',
    },
    officialName: {
      fi: 'Paraguayn tasavalta',
      sv: 'Republiken Paraguay',
      en: null,
    },
  },
  {
    countryId: '604',
    countryCode2: 'PE',
    countryCode3: 'PER',
    shortName: {
      fi: 'Peru',
      sv: 'Peru',
      en: 'Peru',
    },
    officialName: {
      fi: 'Perun tasavalta',
      sv: 'Republiken Peru',
      en: null,
    },
  },
  {
    countryId: '608',
    countryCode2: 'PH',
    countryCode3: 'PHL',
    shortName: {
      fi: 'Filippiinit',
      sv: 'Filippinerna',
      en: 'Philippines',
    },
    officialName: {
      fi: 'Filippiinien tasavalta',
      sv: 'Republiken Filippinerna',
      en: 'Republic of the Philippines',
    },
  },
  {
    countryId: '612',
    countryCode2: 'PN',
    countryCode3: 'PCN',
    shortName: {
      fi: 'Pitcairn',
      sv: 'Pitcairn',
      en: 'Pitcairn',
    },
    officialName: {
      fi: 'Pitcairn',
      sv: 'Pitcairn',
      en: null,
    },
  },
  {
    countryId: '616',
    countryCode2: 'PL',
    countryCode3: 'POL',
    shortName: {
      fi: 'Puola',
      sv: 'Polen',
      en: 'Poland',
    },
    officialName: {
      fi: 'Puolan tasavalta',
      sv: 'Republiken Polen',
      en: 'Republic of Poland',
    },
  },
  {
    countryId: '620',
    countryCode2: 'PT',
    countryCode3: 'PRT',
    shortName: {
      fi: 'Portugali',
      sv: 'Portugal',
      en: 'Portugal',
    },
    officialName: {
      fi: 'Portugalin tasavalta',
      sv: 'Republiken Portugal',
      en: 'Portuguese Republic',
    },
  },
  {
    countryId: '624',
    countryCode2: 'GW',
    countryCode3: 'GNB',
    shortName: {
      fi: 'Guinea-Bissau',
      sv: 'Guinea-Bissau',
      en: 'Guinea-Bissau',
    },
    officialName: {
      fi: 'Guinea-Bissaun tasavalta',
      sv: 'Republiken Guinea-Bissau',
      en: 'Republic of Guinea-Bissau',
    },
  },
  {
    countryId: '626',
    countryCode2: 'TL',
    countryCode3: 'TLS',
    shortName: {
      fi: 'Itä-Timor',
      sv: 'Östtimor',
      en: 'Timor-Leste',
    },
    officialName: {
      fi: 'Itä-Timorin demokraattinen tasavalta',
      sv: 'Demokratiska republiken Östtimor',
      en: 'Democratic Republic of Timor-Leste',
    },
  },
  {
    countryId: '630',
    countryCode2: 'PR',
    countryCode3: 'PRI',
    shortName: {
      fi: 'Puerto Rico',
      sv: 'Puerto Rico',
      en: 'Puerto Rico',
    },
    officialName: {
      fi: 'Puerto Ricon liittovaltio',
      sv: 'Statsförbundet Puerto Rico',
      en: null,
    },
  },
  {
    countryId: '634',
    countryCode2: 'QA',
    countryCode3: 'QAT',
    shortName: {
      fi: 'Qatar',
      sv: 'Qatar',
      en: 'Qatar',
    },
    officialName: {
      fi: 'Qatarin valtio',
      sv: 'Staten Qatar',
      en: null,
    },
  },
  {
    countryId: '638',
    countryCode2: 'RE',
    countryCode3: 'REU',
    shortName: {
      fi: 'Reunion',
      sv: 'Reunion',
      en: 'Reunion',
    },
    officialName: {
      fi: 'Reunion',
      sv: 'Reunion',
      en: null,
    },
  },
  {
    countryId: '642',
    countryCode2: 'RO',
    countryCode3: 'ROU',
    shortName: {
      fi: 'Romania',
      sv: 'Rumänien',
      en: 'Romania',
    },
    officialName: {
      fi: 'Romania',
      sv: 'Rumänien',
      en: 'Romania',
    },
  },
  {
    countryId: '643',
    countryCode2: 'RU',
    countryCode3: 'RUS',
    shortName: {
      fi: 'Venäjä',
      sv: 'Ryssland',
      en: 'Russian Federation',
    },
    officialName: {
      fi: 'Venäjän federaatio',
      sv: 'Ryska federationen',
      en: null,
    },
  },
  {
    countryId: '646',
    countryCode2: 'RW',
    countryCode3: 'RWA',
    shortName: {
      fi: 'Ruanda',
      sv: 'Rwanda',
      en: 'Rwanda',
    },
    officialName: {
      fi: 'Ruandan tasavalta',
      sv: 'Republiken Rwanda',
      en: null,
    },
  },
  {
    countryId: '652',
    countryCode2: 'BL',
    countryCode3: 'BLM',
    shortName: {
      fi: 'Saint-Barthélemy',
      sv: 'Saint-Barthélemy',
      en: 'Saint Barthélemy',
    },
    officialName: {
      fi: 'Saint-Barthélemyn yhteisö',
      sv: 'Förvaltningsområdet Saint-Barthélemy',
      en: 'Collectivity of Saint Barthélemy',
    },
  },
  {
    countryId: '654',
    countryCode2: 'SH',
    countryCode3: 'SHN',
    shortName: {
      fi: 'Saint Helena',
      sv: 'Saint Helena',
      en: 'Saint Helena',
    },
    officialName: {
      fi: 'Saint Helena Ascension ja Tristan da Cunha',
      sv: 'Sankt Helena Ascension och Tristan da Cunha',
      en: 'Saint Helena Ascension and Tristan da Cunha',
    },
  },
  {
    countryId: '659',
    countryCode2: 'KN',
    countryCode3: 'KNA',
    shortName: {
      fi: 'Saint Kitts ja Nevis',
      sv: 'St Christop o Nevis',
      en: 'Saint Kitts and Nevi',
    },
    officialName: {
      fi: 'Saint Kitts ja Nevis',
      sv: 'Saint Christopher och Nevis',
      en: null,
    },
  },
  {
    countryId: '660',
    countryCode2: 'AI',
    countryCode3: 'AIA',
    shortName: {
      fi: 'Anguilla',
      sv: 'Anguilla',
      en: 'Anguilla',
    },
    officialName: {
      fi: 'Anguilla',
      sv: 'Anguilla',
      en: 'Anguilla',
    },
  },
  {
    countryId: '662',
    countryCode2: 'LC',
    countryCode3: 'LCA',
    shortName: {
      fi: 'Saint Lucia',
      sv: 'Saint Lucia',
      en: 'Saint Lucia',
    },
    officialName: {
      fi: 'Saint Lucia',
      sv: 'Saint Lucia',
      en: null,
    },
  },
  {
    countryId: '663',
    countryCode2: 'MF',
    countryCode3: 'MAF',
    shortName: {
      fi: 'Saint-Martin',
      sv: 'Saint-Martin',
      en: 'Saint Martin',
    },
    officialName: {
      fi: 'Saint-Martinin yhteisö',
      sv: 'Förvaltningsområdet Saint-Martin',
      en: 'Collectivity of Saint Martin',
    },
  },
  {
    countryId: '666',
    countryCode2: 'PM',
    countryCode3: 'SPM',
    shortName: {
      fi: 'St-Pierre ja Miquelo',
      sv: 'St-Pierre o Miquelon',
      en: 'St Pierre and Miquel',
    },
    officialName: {
      fi: 'Saint-Pierren ja Miquelonin alueellinen yhteisö',
      sv: 'Territorialområdet Saint Pierre och Miquelon',
      en: 'Territorial Collectivity of Saint Pierre and Miquelon',
    },
  },
  {
    countryId: '670',
    countryCode2: 'VC',
    countryCode3: 'VCT',
    shortName: {
      fi: 'St Vincent',
      sv: 'St Vincent',
      en: 'St Vincent and Grena',
    },
    officialName: {
      fi: 'Saint Vincent ja Grenadiinit',
      sv: 'Saint Vincent och Grenadinerna',
      en: 'Saint Vincent and the Grenadines',
    },
  },
  {
    countryId: '674',
    countryCode2: 'SM',
    countryCode3: 'SMR',
    shortName: {
      fi: 'San Marino',
      sv: 'San Marino',
      en: 'San Marino',
    },
    officialName: {
      fi: 'San Marinon tasavalta',
      sv: 'Republiken San Marino',
      en: null,
    },
  },
  {
    countryId: '678',
    countryCode2: 'ST',
    countryCode3: 'STP',
    shortName: {
      fi: 'Sao Tome ja Principe',
      sv: 'Sao Tome o Principe',
      en: 'Sao Tome and Princip',
    },
    officialName: {
      fi: 'Sao Tomen ja Principen demokraattinen tasavalta',
      sv: 'Demokratiska republiken Sao Tome och Principe',
      en: null,
    },
  },
  {
    countryId: '682',
    countryCode2: 'SA',
    countryCode3: 'SAU',
    shortName: {
      fi: 'Saudi-Arabia',
      sv: 'Saudiarabien',
      en: 'Saudi Arabia',
    },
    officialName: {
      fi: 'Saudi-Arabian kuningaskunta',
      sv: 'Konungariket Saudi-Arabien',
      en: null,
    },
  },
  {
    countryId: '686',
    countryCode2: 'SN',
    countryCode3: 'SEN',
    shortName: {
      fi: 'Senegal',
      sv: 'Senegal',
      en: 'Senegal',
    },
    officialName: {
      fi: 'Senegalin tasavalta',
      sv: 'Republiken Senegal',
      en: null,
    },
  },
  {
    countryId: '688',
    countryCode2: 'RS',
    countryCode3: 'SRB',
    shortName: {
      fi: 'Serbia',
      sv: 'Serbien',
      en: 'Serbia',
    },
    officialName: {
      fi: 'Serbian tasavalta',
      sv: 'Republiken Serbien',
      en: null,
    },
  },
  {
    countryId: '690',
    countryCode2: 'SC',
    countryCode3: 'SYC',
    shortName: {
      fi: 'Seychellit',
      sv: 'Seychellerna',
      en: 'Seychelles',
    },
    officialName: {
      fi: 'Seychellien tasavalta',
      sv: 'Republiken Seychellerna',
      en: null,
    },
  },
  {
    countryId: '694',
    countryCode2: 'SL',
    countryCode3: 'SLE',
    shortName: {
      fi: 'Sierra Leone',
      sv: 'Sierra Leone',
      en: 'Sierra Leone',
    },
    officialName: {
      fi: 'Sierra Leonen tasavalta',
      sv: 'Republiken Sierra Leone',
      en: null,
    },
  },
  {
    countryId: '702',
    countryCode2: 'SG',
    countryCode3: 'SGP',
    shortName: {
      fi: 'Singapore',
      sv: 'Singapore',
      en: 'Singapore',
    },
    officialName: {
      fi: 'Singaporen tasavalta',
      sv: 'Republiken Singapore',
      en: null,
    },
  },
  {
    countryId: '703',
    countryCode2: 'SK',
    countryCode3: 'SVK',
    shortName: {
      fi: 'Slovakia',
      sv: 'Slovakien',
      en: 'Slovakia',
    },
    officialName: {
      fi: 'Slovakian tasavalta',
      sv: 'Slovakiska republiken',
      en: 'Slovak Republic',
    },
  },
  {
    countryId: '704',
    countryCode2: 'VN',
    countryCode3: 'VNM',
    shortName: {
      fi: 'Vietnam',
      sv: 'Vietnam',
      en: 'Viet Nam',
    },
    officialName: {
      fi: 'Vietnamin sosialistinen tasavalta',
      sv: 'Socialistiska republiken Vietnam',
      en: null,
    },
  },
  {
    countryId: '705',
    countryCode2: 'SI',
    countryCode3: 'SVN',
    shortName: {
      fi: 'Slovenia',
      sv: 'Slovenien',
      en: 'Slovenia',
    },
    officialName: {
      fi: 'Slovenian tasavalta',
      sv: 'Republiken Slovenien',
      en: 'Republic of Slovenia',
    },
  },
  {
    countryId: '706',
    countryCode2: 'SO',
    countryCode3: 'SOM',
    shortName: {
      fi: 'Somalia',
      sv: 'Somalia',
      en: 'Somalia',
    },
    officialName: {
      fi: 'Somalian liittotasavalta',
      sv: 'Förbundsrepubliken Somalia',
      en: 'Federal Republic of Somalia',
    },
  },
  {
    countryId: '710',
    countryCode2: 'ZA',
    countryCode3: 'ZAF',
    shortName: {
      fi: 'Etelä-Afrikka',
      sv: 'Sydafrika',
      en: 'South Africa',
    },
    officialName: {
      fi: 'Etelä-Afrikan tasavalta',
      sv: 'Republiken Sydafrika',
      en: 'Republic of South Africa',
    },
  },
  {
    countryId: '716',
    countryCode2: 'ZW',
    countryCode3: 'ZWE',
    shortName: {
      fi: 'Zimbabwe',
      sv: 'Zimbabwe',
      en: 'Zimbabwe',
    },
    officialName: {
      fi: 'Zimbabwen tasavalta',
      sv: 'Republiken Zimbabwe',
      en: null,
    },
  },
  {
    countryId: '724',
    countryCode2: 'ES',
    countryCode3: 'ESP',
    shortName: {
      fi: 'Espanja',
      sv: 'Spanien',
      en: 'Spain',
    },
    officialName: {
      fi: 'Espanjan kuningaskunta',
      sv: 'Konungariket Spanien',
      en: 'Kingdom of Spain',
    },
  },
  {
    countryId: '728',
    countryCode2: 'SS',
    countryCode3: 'SSD',
    shortName: {
      fi: 'Etelä-Sudan',
      sv: 'Sydsudan',
      en: 'South Sudan',
    },
    officialName: {
      fi: 'Etelä-Sudanin tasavalta',
      sv: 'Republiken Sydsudan',
      en: 'Republic of South Sudan',
    },
  },
  {
    countryId: '729',
    countryCode2: 'SD',
    countryCode3: 'SDN',
    shortName: {
      fi: 'Sudan',
      sv: 'Sudan',
      en: 'Sudan',
    },
    officialName: {
      fi: 'Sudanin tasavalta',
      sv: 'Republiken Sudan',
      en: 'Republic of the Sudan',
    },
  },
  {
    countryId: '732',
    countryCode2: 'EH',
    countryCode3: 'ESH',
    shortName: {
      fi: 'Länsi-Sahara',
      sv: 'Västsahara',
      en: 'Western Sahara',
    },
    officialName: {
      fi: 'Länsi-Sahara',
      sv: 'Västsahara',
      en: 'Western Sahara',
    },
  },
  {
    countryId: '740',
    countryCode2: 'SR',
    countryCode3: 'SUR',
    shortName: {
      fi: 'Suriname',
      sv: 'Surinam',
      en: 'Suriname',
    },
    officialName: {
      fi: 'Surinamen tasavalta',
      sv: 'Republiken Surinam',
      en: null,
    },
  },
  {
    countryId: '744',
    countryCode2: 'SJ',
    countryCode3: 'SJM',
    shortName: {
      fi: 'Svalbard',
      sv: 'Svalbard o Jan Mayen',
      en: 'Svalbard and Jan May',
    },
    officialName: {
      fi: 'Svalbard ja Jan Mayen',
      sv: 'Svalbard och Jan Mayen',
      en: 'Svalbard and Jan Mayen',
    },
  },
  {
    countryId: '748',
    countryCode2: 'SZ',
    countryCode3: 'SWZ',
    shortName: {
      fi: 'Eswatini',
      sv: 'Eswatini',
      en: 'Eswatini',
    },
    officialName: {
      fi: 'Eswatinin kuningaskunta',
      sv: 'Konungariket Eswatini',
      en: 'Kingdom of Eswatini',
    },
  },
  {
    countryId: '752',
    countryCode2: 'SE',
    countryCode3: 'SWE',
    shortName: {
      fi: 'Ruotsi',
      sv: 'Sverige',
      en: 'Sweden',
    },
    officialName: {
      fi: 'Ruotsin kuningaskunta',
      sv: 'Konungariket Sverige',
      en: 'Kingdom of Sweden',
    },
  },
  {
    countryId: '756',
    countryCode2: 'CH',
    countryCode3: 'CHE',
    shortName: {
      fi: 'Sveitsi',
      sv: 'Schweiz',
      en: 'Switzerland',
    },
    officialName: {
      fi: 'Sveitsin valaliitto',
      sv: 'Schweiziska edsförbundet',
      en: 'Swiss Confederation',
    },
  },
  {
    countryId: '760',
    countryCode2: 'SY',
    countryCode3: 'SYR',
    shortName: {
      fi: 'Syyria',
      sv: 'Syrien',
      en: 'Syrian Arab Republic',
    },
    officialName: {
      fi: 'Syyrian arabitasavalta',
      sv: 'Arabiska republiken Syrien',
      en: null,
    },
  },
  {
    countryId: '762',
    countryCode2: 'TJ',
    countryCode3: 'TJK',
    shortName: {
      fi: 'Tadzhikistan',
      sv: 'Tadzjikistan',
      en: 'Tajikistan',
    },
    officialName: {
      fi: 'Tadzhikistanin tasavalta',
      sv: 'Republiken Tadzjikistan',
      en: null,
    },
  },
  {
    countryId: '764',
    countryCode2: 'TH',
    countryCode3: 'THA',
    shortName: {
      fi: 'Thaimaa',
      sv: 'Thailand',
      en: 'Thailand',
    },
    officialName: {
      fi: 'Thaimaan kuningaskunta',
      sv: 'Konungariket Thailand',
      en: null,
    },
  },
  {
    countryId: '768',
    countryCode2: 'TG',
    countryCode3: 'TGO',
    shortName: {
      fi: 'Togo',
      sv: 'Togo',
      en: 'Togo',
    },
    officialName: {
      fi: 'Togon tasavalta',
      sv: 'Republiken Togo',
      en: null,
    },
  },
  {
    countryId: '772',
    countryCode2: 'TK',
    countryCode3: 'TKL',
    shortName: {
      fi: 'Tokelau',
      sv: 'Tokelau',
      en: 'Tokelau',
    },
    officialName: {
      fi: 'Tokelau',
      sv: 'Tokelau',
      en: null,
    },
  },
  {
    countryId: '776',
    countryCode2: 'TO',
    countryCode3: 'TON',
    shortName: {
      fi: 'Tonga',
      sv: 'Tonga',
      en: 'Tonga',
    },
    officialName: {
      fi: 'Tongan kuningaskunta',
      sv: 'Konungariket Tonga',
      en: null,
    },
  },
  {
    countryId: '780',
    countryCode2: 'TT',
    countryCode3: 'TTO',
    shortName: {
      fi: 'Trinidad ja Tobago',
      sv: 'Trinidad och Tobago',
      en: 'Trinidad and Tobago',
    },
    officialName: {
      fi: 'Trinidadin ja Tobagon tasavalta',
      sv: 'Republiken Trinidad och Tobago',
      en: null,
    },
  },
  {
    countryId: '784',
    countryCode2: 'AE',
    countryCode3: 'ARE',
    shortName: {
      fi: 'Arabiemiirikunnat',
      sv: 'Arabemiraten',
      en: 'United Arab Emirates',
    },
    officialName: {
      fi: 'Yhdistyneet arabiemiirikunnat',
      sv: 'Förenade Arabemiraten',
      en: 'United Arab Emirates',
    },
  },
  {
    countryId: '788',
    countryCode2: 'TN',
    countryCode3: 'TUN',
    shortName: {
      fi: 'Tunisia',
      sv: 'Tunisien',
      en: 'Tunisia',
    },
    officialName: {
      fi: 'Tunisian tasavalta',
      sv: 'Republiken Tunisien',
      en: null,
    },
  },
  {
    countryId: '792',
    countryCode2: 'TR',
    countryCode3: 'TUR',
    shortName: {
      fi: 'Turkki',
      sv: 'Turkiet',
      en: 'Turkey',
    },
    officialName: {
      fi: 'Turkin tasavalta',
      sv: 'Republiken Turkiet',
      en: null,
    },
  },
  {
    countryId: '795',
    countryCode2: 'TM',
    countryCode3: 'TKM',
    shortName: {
      fi: 'Turkmenistan',
      sv: 'Turkmenistan',
      en: 'Turkmenistan',
    },
    officialName: {
      fi: 'Turkmenistan',
      sv: 'Turkmenistan',
      en: 'Turkmenistan',
    },
  },
  {
    countryId: '796',
    countryCode2: 'TC',
    countryCode3: 'TCA',
    shortName: {
      fi: 'Turks- ja Caicos',
      sv: 'Turks- och Caicos',
      en: 'Turks and Caicos Isl',
    },
    officialName: {
      fi: 'Turks- ja Caicossaaret',
      sv: 'Turks- och Caicosöarna',
      en: null,
    },
  },
  {
    countryId: '798',
    countryCode2: 'TV',
    countryCode3: 'TUV',
    shortName: {
      fi: 'Tuvalu',
      sv: 'Tuvalu',
      en: 'Tuvalu',
    },
    officialName: {
      fi: 'Tuvalu',
      sv: 'Tuvalu',
      en: null,
    },
  },
  {
    countryId: '800',
    countryCode2: 'UG',
    countryCode3: 'UGA',
    shortName: {
      fi: 'Uganda',
      sv: 'Uganda',
      en: 'Uganda',
    },
    officialName: {
      fi: 'Ugandan tasavalta',
      sv: 'Republiken Uganda',
      en: null,
    },
  },
  {
    countryId: '804',
    countryCode2: 'UA',
    countryCode3: 'UKR',
    shortName: {
      fi: 'Ukraina',
      sv: 'Ukraina',
      en: 'Ukraine',
    },
    officialName: {
      fi: 'Ukraina',
      sv: 'Ukraina',
      en: null,
    },
  },
  {
    countryId: '807',
    countryCode2: 'MK',
    countryCode3: 'MKD',
    shortName: {
      fi: 'Pohjois-Makedonia',
      sv: 'Nordmakedonien',
      en: 'North Macedonia',
    },
    officialName: {
      fi: 'Pohjois-Makedonian tasavalta',
      sv: 'Republiken Nordmakedonien',
      en: 'Republic of North Macedonia',
    },
  },
  {
    countryId: '818',
    countryCode2: 'EG',
    countryCode3: 'EGY',
    shortName: {
      fi: 'Egypti',
      sv: 'Egypten',
      en: 'Egypt',
    },
    officialName: {
      fi: 'Egyptin arabitasavalta',
      sv: 'Arabrepubliken Egypten',
      en: 'Arab Republic of Egypt',
    },
  },
  {
    countryId: '826',
    countryCode2: 'GB',
    countryCode3: 'GBR',
    shortName: {
      fi: 'Iso-Britannia',
      sv: 'Storbritannien',
      en: 'United Kingdom',
    },
    officialName: {
      fi: 'Ison-Britannian ja Pohjois-Irlannin yhdistynyt kuningaskunta',
      sv: 'Förenade konungariket Storbritannien och Nordirland',
      en: 'United Kingdom of Great Britain and Northern Ireland',
    },
  },
  {
    countryId: '831',
    countryCode2: 'GG',
    countryCode3: 'GGY',
    shortName: {
      fi: 'Guernsey',
      sv: 'Guernsey',
      en: 'Guernsey',
    },
    officialName: {
      fi: 'Guernsey',
      sv: 'Guernsey',
      en: 'Guernsey',
    },
  },
  {
    countryId: '832',
    countryCode2: 'JE',
    countryCode3: 'JEY',
    shortName: {
      fi: 'Jersey',
      sv: 'Jersey',
      en: 'Jersey',
    },
    officialName: {
      fi: 'Jersey',
      sv: 'Jersey',
      en: 'Jersey',
    },
  },
  {
    countryId: '833',
    countryCode2: 'IM',
    countryCode3: 'IMN',
    shortName: {
      fi: 'Mansaari',
      sv: 'Isle of Man',
      en: 'Isle of Man',
    },
    officialName: {
      fi: 'Mansaari',
      sv: 'Isle of Man',
      en: 'Isle of Man',
    },
  },
  {
    countryId: '834',
    countryCode2: 'TZ',
    countryCode3: 'TZA',
    shortName: {
      fi: 'Tansania',
      sv: 'Tanzania',
      en: 'Tanzania United Rep',
    },
    officialName: {
      fi: 'Tansanian yhdistynyt tasavalta',
      sv: 'Förenade republiken Tanzania',
      en: null,
    },
  },
  {
    countryId: '840',
    countryCode2: 'US',
    countryCode3: 'USA',
    shortName: {
      fi: 'Yhdysvallat',
      sv: 'Förenta Staterna',
      en: 'United States',
    },
    officialName: {
      fi: 'Amerikan yhdysvallat',
      sv: 'Amerikas förenta stater',
      en: 'United States of America',
    },
  },
  {
    countryId: '850',
    countryCode2: 'VI',
    countryCode3: 'VIR',
    shortName: {
      fi: 'USA Neitsytsaaret',
      sv: 'USA Jungfruöarna',
      en: 'Virgin Islands US',
    },
    officialName: {
      fi: 'Yhdysvaltain Neitsytsaaret',
      sv: 'Amerikanska Jungfruöarna',
      en: null,
    },
  },
  {
    countryId: '854',
    countryCode2: 'BF',
    countryCode3: 'BFA',
    shortName: {
      fi: 'Burkina Faso',
      sv: 'Burkina Faso',
      en: 'Burkina Faso',
    },
    officialName: {
      fi: 'Burkina Faso',
      sv: 'Burkina Faso',
      en: 'Burkina Faso',
    },
  },
  {
    countryId: '858',
    countryCode2: 'UY',
    countryCode3: 'URY',
    shortName: {
      fi: 'Uruguay',
      sv: 'Uruguay',
      en: 'Uruguay',
    },
    officialName: {
      fi: 'Uruguayn itäinen tasavalta',
      sv: 'Republiken Uruguay',
      en: null,
    },
  },
  {
    countryId: '860',
    countryCode2: 'UZ',
    countryCode3: 'UZB',
    shortName: {
      fi: 'Uzbekistan',
      sv: 'Uzbekistan',
      en: 'Uzbekistan',
    },
    officialName: {
      fi: 'Uzbekistanin tasavalta',
      sv: 'Republiken Uzbekistan',
      en: null,
    },
  },
  {
    countryId: '862',
    countryCode2: 'VE',
    countryCode3: 'VEN',
    shortName: {
      fi: 'Venezuela',
      sv: 'Venezuela',
      en: 'Venezuela',
    },
    officialName: {
      fi: 'Venezuelan bolivariaaninen tasavalta',
      sv: 'Bolivarianska republiken Venezuela',
      en: 'Bolivarian Republic of Venezuela',
    },
  },
  {
    countryId: '876',
    countryCode2: 'WF',
    countryCode3: 'WLF',
    shortName: {
      fi: 'Wallis ja Futuna',
      sv: 'Wallis och Futuna',
      en: 'Wallis and Futuna Is',
    },
    officialName: {
      fi: 'Wallis ja Futuna',
      sv: 'Wallis och Futuna',
      en: null,
    },
  },
  {
    countryId: '882',
    countryCode2: 'WS',
    countryCode3: 'WSM',
    shortName: {
      fi: 'Samoa',
      sv: 'Samoa',
      en: 'Samoa',
    },
    officialName: {
      fi: 'Samoan itsenäinen valtio',
      sv: 'Självständiga staten Samoa',
      en: null,
    },
  },
  {
    countryId: '887',
    countryCode2: 'YE',
    countryCode3: 'YEM',
    shortName: {
      fi: 'Jemen',
      sv: 'Jemen',
      en: 'Yemen',
    },
    officialName: {
      fi: 'Jemenin tasavalta',
      sv: 'Republiken Jemen',
      en: 'Republic of Yemen',
    },
  },
  {
    countryId: '894',
    countryCode2: 'ZM',
    countryCode3: 'ZMB',
    shortName: {
      fi: 'Sambia',
      sv: 'Zambia',
      en: 'Zambia',
    },
    officialName: {
      fi: 'Sambian tasavalta',
      sv: 'Republiken Zambia',
      en: null,
    },
  },
]

export default data
