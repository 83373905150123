import { Block, Heading, Text } from 'suomifi-ui-components'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { mkToimintakykyTestTd } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { action } from 'mobx'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

export const ToimintakykyForm = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const {
    lomake: { tili },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkToimintakykyTestTd('container')}>
      <Heading variant="h3" mb="xs">
        {t('toimintakyky')}
      </Heading>
      <Text> {t('toimintakykyInfoText')} </Text>
      <Block mt={verticalMarginToken} />
      <FormTextArea
        labelText={t(
          isOmaisuusluettelo()
            ? 'toimintakykyInputLabel'
            : 'toimintakykyTilikausiLabel'
        )}
        data-test-id={mkToimintakykyTestTd('input')}
        maxLength={1000}
        required
        validate={commonXssValidator(t)}
        value={tili.toimintakyky}
        updateValue={action((value: string) => {
          tili.toimintakyky = value
        })}
      />
    </section>
  )
})
