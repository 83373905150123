import { Block, suomifiDesignTokens } from 'suomifi-ui-components'
import React from 'react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

const SimpleAppPage: React.FC = () => {
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <MainContent>
      <Block mt={verticalMarginToken} />
      <ApplicationDetails>
        <Outlet />
      </ApplicationDetails>
    </MainContent>
  )
}

export default SimpleAppPage

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
