import React from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  resolveNavigationStep,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkPageStepHeadingTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const PageStepHeading: React.FC<{
  heading: string
  steps: TilintarkastusRoutePath[]
}> = observer(({ heading, steps }) => {
  const [t] = useTranslation()
  const location = useLocation()
  const isTablet = useDeviceContext().tablet
  const step = resolveNavigationStep(location)
  const { excludeSteps } = getTilintarkastusStore()
  const activeSteps = steps.filter((step) => !excludeSteps.has(step))
  const currentStep = activeSteps.indexOf(step as TilintarkastusRoutePath) + 1
  const numberOfSteps = activeSteps.length

  const ref = useAutoFocus<HTMLDivElement>()

  return (
    <Block
      tabIndex={-1}
      ref={ref}
      data-test-id={mkPageStepHeadingTestId('container')}
      mb="s"
    >
      <Paragraph mb={isTablet ? 'm' : 'xs'}>
        <Text smallScreen data-test-id={mkPageStepHeadingTestId('step-info')}>
          {t('vaihe')} {currentStep}/{numberOfSteps}
        </Text>
      </Paragraph>
      <Heading variant="h2" data-test-id={mkPageStepHeadingTestId('text')}>
        {heading}
      </Heading>
    </Block>
  )
})

export default PageStepHeading
