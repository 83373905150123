import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { InlineAlert, Link } from 'suomifi-ui-components'
import { mkUncompletedStepsSummaryTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { ErrorsBulletedList } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import {
  resolveTilintarkastusNavigationPath,
  TILINTARKASTUS_ROUTES,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  resolveFormErrors,
  TilintarkastusRouteFormControls,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

type Props = {
  forms: Partial<TilintarkastusRouteFormControls>
}

const FormUncompletedStepsSummary = observer(
  forwardRef<HTMLDivElement, Props>(function FormUncompletedStepsSummary(
    { forms },
    ref
  ) {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const isTablet = useDeviceContext().tablet
    const { vaatimus } = getTilintarkastusStore()
    const { isPrincipalUnderaged } = vaatimus

    const RouteErrorTranslations: Record<TilintarkastusRoutePath, string> = {
      // Strings are translation keys
      [TILINTARKASTUS_ROUTES.ALOITUS]: 'aloitus',
      [TILINTARKASTUS_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: isPrincipalUnderaged
        ? 'asuminenHeading'
        : 'toimintakykyJaAsuminen',
      [TILINTARKASTUS_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]:
        'kaytossaOlevaOmaisuusHeading2',
      [TILINTARKASTUS_ROUTES.VARAT]: 'varat',
      [TILINTARKASTUS_ROUTES.VELAT]: 'velat',
      [TILINTARKASTUS_ROUTES.TULOT]: 'tulotHeading',
      [TILINTARKASTUS_ROUTES.MENOT]: 'menotHeading',
      [TILINTARKASTUS_ROUTES.ELAKKEET_JA_ETUUDET]: isOmaisuusluettelo()
        ? 'elakkeetJaEtuudet'
        : 'saannollisetTulot',
      [TILINTARKASTUS_ROUTES.KAYTTOVARAT]: 'kayttovarat',
      [TILINTARKASTUS_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]:
        'sopimuksetJaVakuutukset',
      [TILINTARKASTUS_ROUTES.MUUT_MERKITTAVAT_TOIMET]: 'muutMerkittavatToimet',
      [TILINTARKASTUS_ROUTES.PALKKIO]: 'palkkio',
      [TILINTARKASTUS_ROUTES.VALTAKIRJA]: 'valtakirja',
      [TILINTARKASTUS_ROUTES.LISATIEDOT]: 'lisatiedot',
      [TILINTARKASTUS_ROUTES.YHTEYSTIEDOT]: 'yhteystiedot',
      [TILINTARKASTUS_ROUTES.YHTEENVETO]: 'yhteenvetoJaLahetys',
      [TILINTARKASTUS_ROUTES.VALMIS]: 'valmis',
      [TILINTARKASTUS_ROUTES.TULOSTUS]: 'tulostettavaYhteenveto',
    }

    const errors = Object.entries(
      resolveFormErrors(forms, RouteErrorTranslations)
    ) as [TilintarkastusRoutePath, string][]

    return (
      <section data-test-id={mkUncompletedStepsSummaryTestId('container')}>
        {errors.length > 0 && (
          <FocusableDiv ref={ref} tabIndex={-1}>
            <InlineAlert
              mb={'s'}
              status="error"
              smallScreen={!isTablet}
              data-test-id={mkUncompletedStepsSummaryTestId('errors')}
              tabIndex={0}
              labelText={
                isOmaisuusluettelo()
                  ? t('pakollisiaTietojaPuuttuuOmaisuusluettelo')
                  : t('pakollisiaTietojaPuuttuu')
              }
            >
              <ErrorsBulletedList>
                {errors.map(([path, error]) => (
                  <li key={path}>
                    <Link
                      smallScreen
                      href="#"
                      onClick={() => {
                        void saveDraft()
                        navigate(
                          resolveTilintarkastusNavigationPath(vaatimus, path)
                        )
                      }}
                    >
                      {t(error.toString()) || ''}
                    </Link>
                  </li>
                ))}
              </ErrorsBulletedList>
            </InlineAlert>
          </FocusableDiv>
        )}
      </section>
    )
  })
)

export default FormUncompletedStepsSummary
