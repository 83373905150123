import keymirror from 'keymirror'
import { AsiaPersonRoleId } from 'asia-common/src/vtj/koodisto/asia-enums'

export const OrganizationType = keymirror({
  VALTION_EDUNVALVONTATOIMISTO: null,
  OSTOPALVELU_EDUNVALVONTATOIMISTO: null,
})
export type OrganizationTypeId = (typeof OrganizationType)[keyof typeof OrganizationType]
export const allOrganizationTypeIds = Object.keys(OrganizationType) as OrganizationTypeId[]

const ValtionEdunvalvontatoimisto = keymirror({
  AHVENANMAAN_OIKEUSAPU_JA_EDUNVALVONTATOIMISTO: null,
  ETELA_POHJANMAAN_EDUNVALVONTATOIMISTO: null,
  ETELA_SAVON_EDUNVALVONTATOIMISTO: null,
  HELSINGIN_EDUNVALVONTATOIMISTO: null,
  ITA_UUDENMAAN_EDUNVALVONTATOIMISTO: null,
  KAINUUN_EDUNVALVONTATOIMISTO: null,
  KANTA_HAMEEN_EDUNVALVONTATOIMISTO: null,
  KESKI_POHJANMAAN_JA_POHJANMAAN_EDUNVALVONTATOIMISTO: null,
  KESKI_SUOMEN_EDUNVALVONTATOIMISTO: null,
  KESKI_UUDENMAAN_EDUNVALVONTATOIMISTO: null,
  KYMENLAAKSON_EDUNVALVONTATOIMISTO: null,
  LAPIN_EDUNVALVONTATOIMISTO: null,
  LAPPEENRANNAN_EDUNVALVONTATOIMISTO: null,
  LANSI_UUDENMAAN_EDUNVALVONTATOIMISTO: null,
  OULUN_EDUNVALVONTATOIMISTO: null,
  PIRKANMAAN_EDUNVALVONTATOIMISTO: null,
  POHJOIS_KARJALAN_EDUNVALVONTATOIMISTO: null,
  POHJOIS_SAVON_EDUNVALVONTATOIMISTO: null,
  PAIJAT_HAMEEN_EDUNVALVONTATOIMISTO: null,
  RAUMAN_EDUNVALVONTATOIMISTO: null,
  SATAKUNNAN_EDUNVALVONTATOIMISTO: null,
  VARSINAIS_SUOMEN_EDUNVALVONTATOIMISTO: null,
  YLIVIESKAN_EDUNVALVONTATOIMISTO: null,
})

const OstopalveluEdunvalvontatoimisto = keymirror({
  TUUSULAN_KUNTA: null,
  LAPPAJARVEN_KUNTA: null,
  MERIKARVIAN_KUNTA: null,
  ETELA_SAVON_EDUNVALVONTAPALVELU_KY: null,
  VERO_JA_TILIPALVELU_MARKKU_KARVONEN: null,
  ALAJARVEN_KAUPUNKI: null,
  LAPIN_EDUNVALVONTA_OY: null,
  SALLAN_KUNTA: null,
  PUDASJARVEN_KAUPUNKI: null,
  TERVOLAN_KUNTA: null,
  LAKIASIAINTOIMISTO_PENTIKAINEN_OY: null,
  YLITORNION_KUNTA: null,
  KEURUUN_KAUPUNKI: null,
  SALON_KAUPUNKI: null,
  SOMERON_KAUPUNKI: null,
  ASIANAJOTOIMISTO_ARI_ORVAS_OY: null, // deprecated -> renamed to Karhu Oy
  ASIANAJOTOIMISTO_KARHU_OY: null,
  JURIDISK_BYRO_LAKIASIAINTOIMISTO_HANNA_STENHOLM_OY_AB: null,
  ASIANAJOTOIMISTO_FAGERHOLM_MARD_ADVOKATBYRO_OY_AB: null,
})

export const OrganizationTypeOrganization = {
  [OrganizationType.VALTION_EDUNVALVONTATOIMISTO]: ValtionEdunvalvontatoimisto,
  [OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO]: OstopalveluEdunvalvontatoimisto,
}

export const Organization = {
  ...ValtionEdunvalvontatoimisto,
  ...OstopalveluEdunvalvontatoimisto,
}
export type OrganizationId = (typeof Organization)[keyof typeof Organization]
export const allValtionEdunvalvontatoimistoIds = Object.keys(ValtionEdunvalvontatoimisto) as OrganizationId[]
export const allOstopalveluEdunvalvontatoimistoIds = Object.keys(OstopalveluEdunvalvontatoimisto) as OrganizationId[]

export const getOrganizationOrganizationType = (organizationId: OrganizationId): OrganizationTypeId =>
  Object.keys(OrganizationTypeOrganization).find((ot) =>
    Object.values(OrganizationTypeOrganization[ot as OrganizationTypeId]).some((o) => o === organizationId)
  ) as OrganizationTypeId

export interface OrganizationMetadata {
  personRoleIds: AsiaPersonRoleId[]
  organizationTypeIds: OrganizationTypeId[]
}
