import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  getMuutVelatContent,
  getPankkilainatContent,
  getTakauksetContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/velat.util'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const Velat: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake,
  } = getTilintarkastusStore()
  const { lomakkeelleValitutTiedot } = lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('velat-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('velat')}
      </Heading>
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('pankkilainatHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['pankkilainat']
            ? getPankkilainatContent(t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {isPrincipalUnderaged ? t('panttaukset') : t('takauksetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['takaukset'] ||
          lomakkeelleValitutTiedot['panttaukset']
            ? getTakauksetContent(t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('muutVelatHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['muutVelat'] ? getMuutVelatContent(t) : []
        }
      />
    </section>
  )
})

export default Velat
