import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  Block,
  Button,
  InlineAlert,
  ModalTitle,
  Modal,
  ModalFooter,
  ModalContent,
  LoadingSpinner,
  IconArrowLeft,
  Paragraph,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import {
  currentNavigationSteps,
  resolveTilintarkastusNavigationPath,
  TILINTARKASTUS_ROUTES,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkLahetaTiliTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { submitTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/actions/submit-tili-actions'
import {
  NavButtonContainer,
  NavButtonGroupContainer,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import SaveDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/SaveDialog'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  resolveFormErrors,
  TilintarkastusRouteFormControls,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

export const LahetaTiliVahvistusModal: React.FC<{
  visible: boolean
  onSubmit: () => unknown
  onClose: () => unknown
}> = ({ visible, onSubmit, onClose }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { asiaType } = getTilintarkastusStore().vaatimus

  return (
    <Modal
      visible={visible}
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      onEscKeyDown={onClose}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent>
        <ModalTitle aria-live="polite" aria-atomic="true">
          {t(translations[asiaType].lahetaTili)}
        </ModalTitle>
        {isOmaisuusluettelo() && (
          <Paragraph mb={verticalMarginToken}>
            {t('haluatkoLahettaaOmaisuusluettelonInfo')}
          </Paragraph>
        )}
        <Paragraph>{t(translations[asiaType].haluatkoLahettaaTilin)}</Paragraph>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onSubmit}
          data-test-id={mkLahetaTiliTestId('modal-laheta-painike')}
        >
          {t('laheta')}
        </Button>
        <Button
          onClick={onClose}
          variant="secondary"
          data-test-id={mkLahetaTiliTestId('modal-peruuta-painike')}
        >
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const LahetaTiliNotifications: React.FC = observer(() => {
  const store = getTilintarkastusStore()
  const showLoading = store.submitStatus === 'in-progress'
  const showError =
    store.submitStatus === 'none' && store.submitError !== undefined
  return (
    <>
      {showLoading && <LahetaTiliLoading />}
      {showError && <LahetaTiliError />}
    </>
  )
})

export const LahetaTiliLoading: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="neutral"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('tilia-lahetetaan')}
      mb={'s'}
    >
      <div ref={ref} tabIndex={-1}>
        <LoadingSpinner
          status={'loading'}
          text={t('tiliaLahetetaan')}
          textAlign="right"
          variant="small"
        />
      </div>
    </InlineAlert>
  )
}

const LahetaTiliError: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="error"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('virhe-lahetyksessa')}
      tabIndex={0}
      mb={'s'}
    >
      <div ref={ref} tabIndex={-1}>
        {t('lahetysEpaonnistuiYritaUudelleen')}
      </div>
    </InlineAlert>
  )
}

export const FormSubmitBar: React.FC<{
  forms: Partial<TilintarkastusRouteFormControls>
  onErrorRef: RefObject<HTMLDivElement>
}> = observer(({ forms, onErrorRef }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [lahetaTiliVahvistusModalVisible, setLahetaTiliVahvistusModalVisible] =
    useState(false)
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const { handleSubmit } = useFormContext()
  const openFrontPage = () => navigate('/')

  const [isSaveDialogVisible, setSaveDialogVisible] = useState(false)
  const location = useLocation()

  const { excludeSteps, submitStatus, vaatimus } = getTilintarkastusStore()
  const { previous } = currentNavigationSteps(excludeSteps, location)

  const onSaveAndExit = () => {
    void saveDraft()
    setSaveDialogVisible(false)
    openFrontPage()
  }
  const asiointiStore = useAsiointiUserStore()

  return (
    <section data-test-id={mkLahetaTiliTestId('container')}>
      <LahetaTiliNotifications />
      <LahetaTiliVahvistusModal
        onSubmit={async () => {
          if (submitStatus === 'none') {
            setLahetaTiliVahvistusModalVisible(false)
            const response = await submitTili(asiointiStore.lang)
            if (response.isOk) {
              navigate(
                resolveTilintarkastusNavigationPath(
                  vaatimus,
                  TILINTARKASTUS_ROUTES.VALMIS
                )
              )
            }
          }
        }}
        onClose={() => setLahetaTiliVahvistusModalVisible(false)}
        visible={lahetaTiliVahvistusModalVisible}
      />
      <SaveDialog
        visible={isSaveDialogVisible}
        onSave={onSaveAndExit}
        onClose={() => {
          setSaveDialogVisible(false)
        }}
      />

      <Block mt={verticalMarginToken}>
        <NavButtonContainer>
          <Button
            data-test-id={mkLahetaTiliTestId('save-button')}
            onClick={() => setSaveDialogVisible(true)}
            variant="secondary"
          >
            {t('tallennaJaPalaaEtusivulle')}
          </Button>

          <NavButtonGroupContainer>
            {previous && (
              <>
                <Button
                  variant="secondary"
                  icon={<IconArrowLeft />}
                  onClick={() => {
                    void saveDraft()
                    navigate(
                      resolveTilintarkastusNavigationPath(vaatimus, previous)
                    )
                  }}
                >
                  {t('previous')}
                </Button>
                <Block ml="s" />
              </>
            )}

            <Button
              data-test-id={mkLahetaTiliTestId('send-button')}
              onClick={async () => {
                await handleSubmit(() => {
                  void saveDraft()
                  const errors = resolveFormErrors(forms, {})
                  if (Object.values(errors).length === 0) {
                    setLahetaTiliVahvistusModalVisible(true)
                  } else {
                    setTimeout(() => onErrorRef?.current?.focus())
                  }
                })()
              }}
            >
              {t('laheta')}
            </Button>
          </NavButtonGroupContainer>
        </NavButtonContainer>
      </Block>
    </section>
  )
})

const translations = {
  OMAISUUSLUETTELO: {
    lahetaTili: 'lahetaOmaisuusluettelo',
    haluatkoLahettaaTilin: 'haluatkoLahettaaOmaisuusluettelon',
  },
  PAATOSTILI: {
    lahetaTili: 'lahetaPaatostili',
    haluatkoLahettaaTilin: 'haluatkoLahettaaPaatostilin',
  },
  VUOSITILI: {
    lahetaTili: 'lahetaVuositili',
    haluatkoLahettaaTilin: 'haluatkoLahettaaVuositilin',
  },
}
