import { Block, suomifiDesignTokens } from 'suomifi-ui-components'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import FormNavigationWizard from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationWizard'
import {
  TILINTARKASTUS_ROUTES,
  TILINTARKASTUS_STEPS,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { TopHeading } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/TopHeading'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusRouteFormControls } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const TilintarkastusAppPage: React.FC<{
  forms: TilintarkastusRouteFormControls
}> = observer(({ forms }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    vaatimus: { asiaType },
  } = getTilintarkastusStore()

  const headingTranslationString = {
    OMAISUUSLUETTELO: 'omaisuusluettelo',
    PAATOSTILI: 'paatostili',
    VUOSITILI: 'vuositili',
  }

  return (
    <>
      <TopHeading>{t(headingTranslationString[asiaType])}</TopHeading>

      <MainContent>
        <NavigationAndNotifications>
          <FormNavigationWizard
            forms={forms}
            routes={TILINTARKASTUS_ROUTES}
            steps={TILINTARKASTUS_STEPS}
          />
        </NavigationAndNotifications>
        <Block mt={verticalMarginToken} />
        <ApplicationDetails>
          <Outlet />
        </ApplicationDetails>
      </MainContent>
    </>
  )
})

export default TilintarkastusAppPage

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
const NavigationAndNotifications = styled.div`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    max-width: 330px;
    margin: 0;
  }
`
