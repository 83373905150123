import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import OmatTiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteystiedot/OmatTiedotForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import TiedoksiantoForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteystiedot/TiedoksiantoForm'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const YhteystiedotPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)
  const {
    vaatimus: { asiaType },
  } = getTilintarkastusStore()

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('yhteystiedot')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph>{t('omaisuusluetteloYhteystiedotInfo1')}</Paragraph>
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <OmatTiedotForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        {asiaType === 'PAATOSTILI' && (
          <>
            <TiedoksiantoForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </>
        )}

        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default YhteystiedotPage
