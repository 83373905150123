import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  AsiointiHabitationPeriodType,
  AsiointiHabitationType,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import {
  formatDateAsLocal,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormCountryDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCountryDropdown'
import { getCountryById } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/country.store'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import React, { JSX } from 'react'
import { FrontAsuinpaikka } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import {
  COUNTRY_CODE_FINLAND,
  COUNTRY_CODE_UNKNOWN,
} from 'holhous-common/src/vtj/country/country.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { mkAsuinpaikkaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

export const AsuinpaikkaForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: { tili },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkAsuinpaikkaTestId('container')}>
      {isPrincipalUnderaged ? (
        <Paragraph>{t('lisaaAsuinpaikka')}</Paragraph>
      ) : (
        <>
          <Heading mb="xs" variant="h3">
            {t('asuinpaikka')}
          </Heading>
          <Paragraph>
            {t(
              isOmaisuusluettelo()
                ? 'ohjeIlmoitaAsuinpaikat_OMAISUUSLUETTELO'
                : 'ohjeIlmoitaAsuinpaikat'
            )}
          </Paragraph>
        </>
      )}

      <FormListInput
        ExpanderComponent={AsuinpaikkaListItemContent}
        state={tili.asuminen.asuinpaikat}
        dataTestIdPrefix={mkAsuinpaikkaTestId('form')}
        ModalContentComponent={AsuinpaikkaModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            habitationType: undefined,
            habitationPeriodType: undefined,
            streetAddress: undefined,
            streetAddressExtra: undefined,
            postalCode: undefined,
            postOffice: undefined,
            beginDate: undefined,
            endDate: undefined,
            countryCode: COUNTRY_CODE_FINLAND,
          })
        }
        stateSubmitAdapter={(asuinpaikka: FrontAsuinpaikka) => {
          if (
            [
              AsiointiHabitationType.HOUSELESS,
              AsiointiHabitationType.UNKNOWN,
            ].includes(asuinpaikka.habitationType as AsiointiHabitationType)
          ) {
            const { habitationType, id, beginDate } = asuinpaikka
            return {
              habitationType,
              id,
              beginDate,
              countryCode: COUNTRY_CODE_UNKNOWN,
            }
          } else {
            return asuinpaikka
          }
        }}
        translationPrefix="asuminen"
      />
    </section>
  )
})

const AsuinpaikkaModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontAsuinpaikka>): JSX.Element => {
  const [t] = useTranslation()
  const asumistyypit = useAsuinpaikkaItemsTranslated()
  const asumisjaksotyypit = useAsumisjaksoItemsTranslated()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const lang = useAsiointiUserStore().lang
  const countryId = entry.countryCode

  const unknownOrHouseless =
    entry.habitationType === AsiointiHabitationType.HOUSELESS ||
    entry.habitationType === AsiointiHabitationType.UNKNOWN

  return (
    <>
      <FormDropdown
        labelText={t('habitationType')}
        data-test-id={createDataTestId('modal-asumistyyppi')}
        value={entry.habitationType}
        items={asumistyypit}
        required
        updateValue={(value) => {
          setEntry({
            ...entry,
            habitationType: value as AsiointiHabitationType,
          })
        }}
      />
      <Block mt={verticalMarginToken} />

      {!unknownOrHouseless && (
        <>
          <FormDropdown
            labelText={t('habitationPeriodType')}
            data-test-id={createDataTestId('modal-asumisjaksotyyppi')}
            value={entry.habitationPeriodType}
            items={asumisjaksotyypit}
            required
            updateValue={(value) => {
              setEntry({
                ...entry,
                habitationPeriodType: value as AsiointiHabitationPeriodType,
              })
            }}
          />
          <Block mt={verticalMarginToken} />
          <FormTextInput
            data-test-id={createDataTestId('modal-katuosoite')}
            labelText={t('katuosoite')}
            value={entry.streetAddress}
            required
            validate={commonXssValidator(t)}
            updateValue={(value) =>
              setEntry({ ...entry, streetAddress: value })
            }
          />
          <FormTextInput
            data-test-id={createDataTestId('modal-katuosoite-lisatieto')}
            labelText={t('osoitteenLisarivi')}
            value={entry.streetAddressExtra}
            validate={commonXssValidator(t)}
            updateValue={(value) =>
              setEntry({ ...entry, streetAddressExtra: value })
            }
          />
          <FormTextInput
            data-test-id={createDataTestId('modal-postinumero')}
            labelText={t('postinumero')}
            value={entry.postalCode}
            required
            validate={commonXssValidator(t)}
            updateValue={(value) => setEntry({ ...entry, postalCode: value })}
          />
          <FormTextInput
            data-test-id={createDataTestId('modal-postitoimipaikka')}
            labelText={t('postitoimipaikka')}
            value={entry.postOffice}
            required
            validate={commonXssValidator(t)}
            updateValue={(value) => setEntry({ ...entry, postOffice: value })}
          />
          <FormCountryDropdown
            data-test-id={createDataTestId('modal-maa')}
            value={getCountryById(countryId).shortName[lang]}
            updateValue={(value) => {
              setEntry({ ...entry, countryCode: value })
            }}
          />
        </>
      )}

      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />

      {!isOmaisuusluettelo() && (
        <FormDateInput
          labelText={t('paattymispaivamaara')}
          data-test-id={createDataTestId('modal-paattymispaivamaara')}
          value={
            entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              endDate: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />
      )}
    </>
  )
}

export const AsuinpaikkaListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontAsuinpaikka>): JSX.Element => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const lang = useAsiointiUserStore().lang
  const countryId = entry.countryCode
  const unknownOrHouseless =
    entry.habitationType === AsiointiHabitationType.HOUSELESS ||
    entry.habitationType === AsiointiHabitationType.UNKNOWN

  const formattedAddress = unknownOrHouseless
    ? ''
    : `${entry.streetAddress}, ${entry.postalCode} ${entry.postOffice}`

  const heading = entry.habitationType
    ? t(`habitationType_${entry.habitationType}`)
    : ''

  const alkamispvm: string =
    (entry.beginDate && formatDateAsLocal(entry.beginDate)) ||
    t('alkamispaivamaaraEiTiedossa')

  const paattymispvm = entry.endDate
    ? formatDateAsLocal(entry.endDate)
    : t('paattymispaivamaaraEiTiedossa')

  return (
    <FormListInputExpander
      heading={heading}
      headingRight={
        unknownOrHouseless
          ? ''
          : t(`habitationTypePeriodTitle_${entry.habitationPeriodType}`)
      }
      subHeading={formattedAddress}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
      showSubHeadingWhileOpen={!unknownOrHouseless}
    >
      {unknownOrHouseless ? (
        <>
          <Heading variant="h5">{t('alkamispaivamaara')}</Heading>
          <Text>
            {entry.beginDate
              ? formatDateAsLocal(entry.beginDate)
              : t('inputNotSpecified')}
          </Text>
          <Block mt={verticalMarginToken} />
        </>
      ) : (
        <>
          {entry.streetAddressExtra && (
            <>
              <Heading variant="h5">{t('osoitteenLisarivi')}</Heading>
              <Text>{entry.streetAddressExtra}</Text>
              <Block mt={verticalMarginToken} />
            </>
          )}

          <Heading variant="h5">{t('alkamispaivamaara')}</Heading>
          <Text>{alkamispvm}</Text>
          <Block mt={verticalMarginToken} />

          {!isOmaisuusluettelo() && (
            <>
              <Heading variant="h5">{t('paattymispaivamaara')}</Heading>
              <Text>{paattymispvm}</Text>
              <Block mt={verticalMarginToken} />
            </>
          )}

          <Heading variant="h5">{t('maa')}</Heading>
          <Text>{getCountryById(countryId).shortName[lang]}</Text>
          <Block mt={verticalMarginToken} />
        </>
      )}
    </FormListInputExpander>
  )
}

export type AsuinpaikkaTranslation = {
  name: string
  value: AsiointiHabitationType
}

const useAsuinpaikkaItemsTranslated = (): AsuinpaikkaTranslation[] => {
  const [t] = useTranslation()
  return Object.values(AsiointiHabitationType).map((asumistyyppi) => {
    return { name: t(`habitationType_${asumistyyppi}`), value: asumistyyppi }
  })
}

type AsumisjaksoTranslation = {
  name: string
  value: AsiointiHabitationPeriodType
}
const useAsumisjaksoItemsTranslated = (): AsumisjaksoTranslation[] => {
  const [t] = useTranslation()
  return Object.values(AsiointiHabitationPeriodType).map((tyyppi) => {
    return { name: t(`habitationPeriodType_${tyyppi}`), value: tyyppi }
  })
}
