import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { AsiakirjaType, AsiakirjaOriginType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

// asiakirjas have same restrictions, asia type has different restrictions
const aetAsiakirjaRestrictionMetadata: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

// asia and all asiakirjas have same retentions
const aetRetentionMetadata: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_10_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_10_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const avioliitonEsteidenTutkintaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.PYYNTO_AVIOLIITON_ESTEIDEN_TUTKINNASTA_LIITTEINEEN_VIHKIMINEN_SUOMESSA,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['96bb9aaf-2602-45ec-9fad-3de9d9f18f91'],
      defaultToivoAsiakirjaTypeId: '96bb9aaf-2602-45ec-9fad-3de9d9f18f91',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6573c561-f921-4eeb-a411-4036687147ea'],
      defaultToivoAsiakirjaTypeId: '6573c561-f921-4eeb-a411-4036687147ea',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5ce4a6d1-c222-4bd4-abd6-7f8230d66ba5'],
      defaultToivoAsiakirjaTypeId: '5ce4a6d1-c222-4bd4-abd6-7f8230d66ba5',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['48f46a2b-7fee-4e76-b841-1beef9937059'],
      defaultToivoAsiakirjaTypeId: '48f46a2b-7fee-4e76-b841-1beef9937059',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_TOIMITETUSTA_AVIOLIITON_ESTEIDEN_TUTKINNASTA_VIHKIMISILMOITUS,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['562f87f4-58e2-48f8-ab9e-81b21651fc27'],
      defaultToivoAsiakirjaTypeId: '562f87f4-58e2-48f8-ab9e-81b21651fc27',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ff1d44ba-959d-46fe-adfd-f88f5400910c'],
      defaultToivoAsiakirjaTypeId: 'ff1d44ba-959d-46fe-adfd-f88f5400910c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KIELTEINEN_PAATOS_RAUKEAMINEN,
      restrictionMetadata: aetAsiakirjaRestrictionMetadata,
      retentionMetadata: aetRetentionMetadata,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['dd15c7bf-e4ea-464b-9ec9-91e0f0637ce3'],
      defaultToivoAsiakirjaTypeId: 'dd15c7bf-e4ea-464b-9ec9-91e0f0637ce3',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.SIVIILISAATY_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KV_KORJAUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.PUOLISO_A, AsiaPersonRole.PUOLISO_B],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.TODISTUS_TOIMITETUSTA_AVIOLIITON_ESTEIDEN_TUTKINNASTA_VIHKIMISILMOITUS,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.HARKINTA_AIKA,
    },
    {
      asiaStatusId: AsiaStatus.HARKINTA_AIKA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.KASITTELYSSA,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  },
  retentionMetadata: aetRetentionMetadata,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
