import { Country } from 'holhous-common/src/vtj/country/country.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import data from 'edunvalvonta-asiointi/src/vtj/asiointi/common/countries.data'

export interface CountryStore {
  countries: Country[]
}

const countryStore = { countries: data }

export function getCountryStore(): CountryStore {
  if (!countryStore) {
    throw new Error('CountryStore not initialized')
  }
  return countryStore
}

export const getCountryNamesInDisplayOrder = (
  lang: HolhousAsiointiLanguageCode
): string[] =>
  getCountryStore()
    .countries.map((c) => c.shortName[lang])
    .sort()

export const getCountryByShortName = (
  lang: HolhousAsiointiLanguageCode,
  countryName: string
): Country => {
  const found = getCountryStore().countries.find(
    (c) => c.shortName[lang] === countryName
  )
  if (!found) {
    throw new Error('Country not found')
  }
  return found
}

export const getCountryById = (countryId: string): Country => {
  const found = getCountryStore().countries.find(
    (c) => c.countryId === countryId
  )

  if (!found) {
    throw new Error('Unrecognized country ' + countryId)
  }
  return found
}
