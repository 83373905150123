import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import {
  BulletList,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconArrowRight,
  IconCheckCircleFilled,
  InlineAlert,
  Paragraph,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import {
  getTiliFeedbackUrl,
  kasittelyAjatUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  ButtonBlock,
  FocusableHeading,
  ValmisBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import { useNavigate } from 'react-router-dom'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { useValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  TILINTARKASTUS_ROUTES,
  resolveTilintarkastusNavigationPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'

const ValmisPage: React.FC = observer(() => {
  const [t, i18n] = useTranslation()
  const userStore = useAsiointiUserStore()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const navigate = useNavigate()
  const henkilo = useValittuHenkilo()
  const openFrontPage = () =>
    navigate(getAsioiPathByPersonId(henkilo?.personId))

  const { vaatimus } = getTilintarkastusStore()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const { asiaType } = getTilintarkastusStore().vaatimus

  const TiliSection: React.FC = () => {
    return (
      <>
        <Block variant="section" mt={verticalMarginToken}>
          <Block mt={verticalMarginToken} />
          <Heading variant="h3" smallScreen={!isTablet}>
            {t('mitaSeuraavaksiTapahtuu')}
          </Heading>
          <Block mt="xs" />

          <BulletList>
            <li>
              <Text smallScreen={!isTablet}>
                {t('virkailijaOttaaPaatostilinKasittelyyn')}
              </Text>
            </li>
            <li>
              <Text smallScreen={!isTablet}>
                {t('virkailijaOnSinuunYhteydessaJosTarvitaanLisatietoja')}{' '}
              </Text>
            </li>
            <li>
              <Text smallScreen={!isTablet}>
                {t('virkailijaTarkastaaPaatostilin')}{' '}
              </Text>
            </li>
          </BulletList>
        </Block>
        <Block variant="section" mt={verticalMarginToken}>
          <Heading variant="h3" smallScreen={!isTablet}>
            {t('kasittelyaika')}
          </Heading>
          <Block mt="xs">
            <ExternalLink
              href={kasittelyAjatUrl(
                i18n.language as HolhousAsiointiLanguageCode
              )}
              variant="accent"
              underline="initial"
              smallScreen={!isTablet}
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('tarkistaHenkiloasiakkaidenKasittelyajatDvvnSivuilta')}
            </ExternalLink>
          </Block>
        </Block>
      </>
    )
  }

  return (
    <ResponsiveBorderedContentBlock data-test-id={mkValmisTestId('container')}>
      <ValmisBlock mb={verticalMarginToken}>
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis')}
      </ValmisBlock>
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t(translations[asiaType].kiitosHeading)}
      </FocusableHeading>
      <Paragraph mt={verticalMarginToken}>
        {t(translations[asiaType].tositteetInfo)}
      </Paragraph>
      <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
      <InlineAlert labelText={t('otaYhteenvetoTalteen')}>
        <Paragraph mb={verticalMarginToken}>
          {t('lahetysPagePrintInfoText')}
        </Paragraph>
        <ExternalLink
          href={resolveTilintarkastusNavigationPath(
            vaatimus,
            TILINTARKASTUS_ROUTES.TULOSTUS
          )}
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('avaaTulostettavaYhteenveto')}
        </ExternalLink>
      </InlineAlert>
      {!isOmaisuusluettelo() && <TiliSection />}
      <DividerLine mt={verticalMarginToken} />
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('annaPalautetta')}
        </Heading>
        <Paragraph mt={verticalMarginToken}>
          {t(translations[asiaType].palauteInfo)}
        </Paragraph>
        <Block mt={verticalMarginToken}>
          <ExternalLink
            href={getTiliFeedbackUrl(userStore.lang)}
            data-test-id={mkValmisTestId('tili-feedback-link-button')}
            variant="accent"
            underline="initial"
            smallScreen={!isTablet}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('annaPalautettaPalvelusta')}
          </ExternalLink>
        </Block>
      </Block>
      <DividerLine mt={verticalMarginToken} />
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('seuraavaksi')}
        </Heading>
        <Paragraph mt={verticalMarginToken}>
          {t('voitPalataEtusivulleTaiKirjautuaUlos')}
        </Paragraph>
        <ButtonBlock variant="section" mt={verticalMarginToken}>
          <Button
            onClick={openFrontPage}
            variant={'secondary'}
            iconRight={<IconArrowRight />}
            role="link"
            data-test-id={mkValmisTestId('asiointi-front-page-link-button')}
          >
            {t('siirryPalvelunEtusivulle')}
          </Button>
        </ButtonBlock>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

export default ValmisPage

const translations = {
  OMAISUUSLUETTELO: {
    kiitosHeading: 'kiitosOmaisuusluetteloOnLahetetty',
    tositteetInfo: 'omaisuusluetteloLahetettyInfo1',
    palauteInfo: 'omaisuusluetteloAnnaPalautettaInfo1',
  },
  VUOSITILI: {
    kiitosHeading: 'kiitosVuositiliOnLahetetty',
    tositteetInfo: 'muistaTositteetInfo',
    palauteInfo: 'vuositiliAnnaPalautettaInfo',
  },
  PAATOSTILI: {
    kiitosHeading: 'kiitosPaatostiliOnLahetetty',
    tositteetInfo: 'muistaTositteetInfo',
    palauteInfo: 'paatostiliAnnaPalautettaInfo',
  },
}
