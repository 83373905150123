import { flow, observable, runInAction, toJS } from 'mobx'
import * as draftApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/draft-api-client'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import {
  Drafts,
  Draft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { Lomake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'

export type DraftStore = Drafts & {
  isLoading: boolean
  isInitialized: boolean
}

const draftStore: DraftStore = observable({
  drafts: [],
  isLoading: false,
  isInitialized: false,
})

export function useDraftStore(): DraftStore {
  return draftStore
}

// Override local state by the state retrieved from the server
export const fetchDraftsIntoStore = flow(function* () {
  draftStore.isLoading = true
  const { drafts } = yield draftApi.getDrafts()
  draftStore.drafts = observable(drafts)
  draftStore.isInitialized = true
  draftStore.isLoading = false
  return draftStore.isInitialized
})

export const createDraftStore = (data: Draft[]): DraftStore => {
  runInAction(() => {
    draftStore.drafts = observable(data)
    draftStore.isInitialized = true
  })
  return draftStore
}

export const getDraftBySourceId = (sourceId: string): Draft | undefined => {
  return draftStore.drafts.find((draft) => draft.sourceId === sourceId)
}

export const saveTiliAsDraft = async (
  lomake: Lomake,
  vaatimus: Pick<
    AsiointiSeurantaAsiavaatimus,
    'seurantaAsiavaatimusId' | 'asiaType'
  >,
  visitedSteps: string[]
): Promise<void> =>
  flow(function* () {
    const tiliDraftToSave = observable({
      sourceId: vaatimus.seurantaAsiavaatimusId.toString(),
      type: vaatimus.asiaType,
      data: toJS({ lomake, visitedSteps }), // a non-observable copy of the original to avoid it changing
      updatedAt: new Date().toISOString() as LongIsoDateString, // user specific date to avoid re-renders
    })
    let found = false
    for (const storeDraft of draftStore.drafts) {
      if (storeDraft.sourceId === tiliDraftToSave.sourceId) {
        storeDraft.data = tiliDraftToSave.data
        storeDraft.updatedAt = tiliDraftToSave.updatedAt
        found = true
        break
      }
    }
    if (!found) {
      draftStore.drafts.push(tiliDraftToSave)
    }
    return yield draftApi.saveTiliDraft(tiliDraftToSave)
  })()
