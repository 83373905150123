import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTili,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import { FrontSaannollinenTulo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseLocalDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  AlaikaisenTilinTyyppi,
  AsiointiElaketyyppi,
  AsiointiOmaisuusluetteloSaannollinenTuloTyyppi,
  AsiointiSaannollinenTuloTyyppi,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

const SaannollisetTulotForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { tulotiedot } = getTili()
  const translations =
    saannollisetTulotTranslations[
      isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI'
    ]
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: { lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkYleiskatsausTestId('tulot-form')}>
      <Heading variant="h3" mb="xs">
        {t(translations.heading)}
      </Heading>
      {isPrincipalUnderaged && lomakkeelleValitutTiedot['tulot'] === true && (
        <InlineAlert
          mb={verticalMarginToken}
          data-test-id={mkYleiskatsausTestId('alaikainen-info')}
        >
          <Paragraph mb="s">{t('alaikainenElakeInfoText1')}</Paragraph>
          <Paragraph>{t('alaikainenElakeInfoText2')}</Paragraph>
        </InlineAlert>
      )}
      <Paragraph>{t(translations.infoText)}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={SaannollisetTulotListItemContent}
        state={tulotiedot.saannollisetTulot}
        dataTestIdPrefix={mkYleiskatsausTestId('tulot-form')}
        ModalContentComponent={SaannollisetTulotModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            tyyppi: undefined,
            maksaja: '',
            nettosumma: 0,
            beginDate: undefined,
            endDate: undefined,
            asiakirjat: [],
          })
        }
        translationPrefix={translations.translationPrefix}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

export default SaannollisetTulotForm

const SaannollisetTulotModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontSaannollinenTulo>) => {
  const [t] = useTranslation()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const translations =
    saannollisetTulotTranslations[
      isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI'
    ]
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()

  const tulotyypit = Object.values(
    isOmaisuusluettelo()
      ? AsiointiOmaisuusluetteloSaannollinenTuloTyyppi
      : AsiointiSaannollinenTuloTyyppi
  ).reduce(
    (
      result: { name: string; value: AsiointiLedgerAccountIncomeType }[],
      tyyppi
    ) => {
      return [...result, { name: t(`incomeType_${tyyppi}`), value: tyyppi }]
    },
    []
  )

  return (
    <>
      <FormDropdown
        labelText={t('tyyppi')}
        data-test-id={createDataTestId('modal-tyyppi')}
        value={entry.tyyppi}
        items={tulotyypit}
        required
        updateValue={(value) => {
          setEntry({
            ...entry,
            tyyppi: value as AsiointiLedgerAccountIncomeType,
          })
        }}
      />
      <Block mt={verticalMarginToken} />

      <FormTextInput
        data-test-id={createDataTestId('modal-maksaja')}
        labelText={t('maksaja')}
        value={entry.maksaja}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, maksaja: value })}
      />

      <FormNumberInput
        data-test-id={createDataTestId('modal-nettosumma')}
        labelText={t('nettosummaKuukaudessaEuroina')}
        value={entry.nettosumma}
        validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
        required
        updateValue={(value) =>
          setEntry({
            ...entry,
            nettosumma: !value ? 0 : value,
          })
        }
      />

      <FormDateInput
        labelText={t('alkamispaivamaara')}
        data-test-id={createDataTestId('modal-alkamispaivamaara')}
        value={
          entry.beginDate ? formatDateAsLocal(entry.beginDate) : entry.beginDate
        }
        updateValue={(value) => {
          setEntry({
            ...entry,
            beginDate: value ? parseLocalDate(value) : value,
          })
        }}
        validate={commonDateValidator(t)}
      />
      {!isOmaisuusluettelo() && (
        <FormDateInput
          labelText={t('paattymispaivamaara')}
          data-test-id={createDataTestId('modal-paattymispaivamaara')}
          value={
            entry.endDate ? formatDateAsLocal(entry.endDate) : entry.endDate
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              endDate: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />
      )}

      {isPrincipalUnderaged && isElake(entry.tyyppi) && (
        <Block mb={verticalMarginToken}>
          <FormRadioButtonGroup
            data-test-id={createDataTestId('alaikainenElakemodal-radio')}
            labelText={t('elakkeenMaksaminen')}
            radioButtons={[
              {
                labelText: t(
                  `AlaikaisenTilinTyyppi_${AlaikaisenTilinTyyppi.HUOLTAJAN_HALLINNOIMA}`
                ),
                value: AlaikaisenTilinTyyppi.HUOLTAJAN_HALLINNOIMA,
              },
              {
                labelText: t(
                  `AlaikaisenTilinTyyppi_${AlaikaisenTilinTyyppi.ALAIKAISEN_OMA}`
                ),
                value: AlaikaisenTilinTyyppi.ALAIKAISEN_OMA,
              },
              {
                labelText: t(
                  `AlaikaisenTilinTyyppi_${AlaikaisenTilinTyyppi.HUOLTAJAN_OMA}`
                ),
                value: AlaikaisenTilinTyyppi.HUOLTAJAN_OMA,
              },
            ]}
            value={entry.mihinPankkitililleMaksetaan}
            required
            updateValue={(value) => {
              setEntry({
                ...entry,
                mihinPankkitililleMaksetaan: value as AlaikaisenTilinTyyppi,
              })
            }}
          />
          {entry.mihinPankkitililleMaksetaan ===
            AlaikaisenTilinTyyppi.HUOLTAJAN_OMA && (
            <InlineAlert mt="m">{t('huoltajanOmaInlineAlertText')}</InlineAlert>
          )}
        </Block>
      )}
      <FormAttachmentFileBox
        observableAttachments={entry.asiakirjat}
        asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
        data-test-id={createDataTestId('modal-asiakirja')}
        title={t('asiakirjat')}
        text={t(translations.asiakirjaInfoText)}
      />
    </>
  )
}

const SaannollisetTulotListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontSaannollinenTulo>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const translations =
    saannollisetTulotTranslations[
      isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI'
    ]

  const beginDate = entry.beginDate
    ? formatDateAsLocal(entry.beginDate)
    : t('alkamispaivamaaraEiTiedossa')
  const endDate = entry.endDate
    ? formatDateAsLocal(entry.endDate)
    : t('paattymispaivamaaraEiTiedossa')

  const dates = isOmaisuusluettelo() ? beginDate : `${beginDate} - ${endDate}`

  return (
    <FormListInputExpander
      heading={t(`incomeType_${entry.tyyppi}`)}
      headingRight={`${formatNumberWithComma(entry.nettosumma)} € / kk`}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      <Heading variant="h5">{t('maksaja')}</Heading>
      <Paragraph mb={verticalMarginToken}>{entry.maksaja}</Paragraph>

      <Heading variant="h5">{t(translations.paivamaara)}</Heading>
      <Paragraph mb={verticalMarginToken}>{dates}</Paragraph>

      {entry.mihinPankkitililleMaksetaan && (
        <Block>
          <Heading variant="h5">{t('elakkeenMaksaminen')}</Heading>
          <Paragraph mb={verticalMarginToken}>
            {t(`AlaikaisenTilinTyyppi_${entry.mihinPankkitililleMaksetaan}`)}
          </Paragraph>
        </Block>
      )}

      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('etuusJaElakepaatokset')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Block mt={verticalMarginToken} />
    </FormListInputExpander>
  )
}

const saannollisetTulotTranslations = {
  OMAISUUSLUETTELO: {
    translationPrefix: 'elakeEtuus',
    heading: 'elakkeetJaEtuudetHeading',
    infoText: 'elakkeetEtuudetInfotext',
    paivamaara: 'alkamispaivamaara',
    asiakirjaInfoText: 'elakeEtuusAsiakirjaInfoText',
  },
  TILI: {
    translationPrefix: 'tulot',
    heading: 'saannollisetTulotHeading',
    infoText: 'ohjeIlmoitaSaannollisetTulot',
    paivamaara: 'jakso',
    asiakirjaInfoText: 'ohjeSaannolisenTulonAsiakirjat',
  },
}

const isElake = (tyyppi: AsiointiLedgerAccountIncomeType | undefined) => {
  return tyyppi ? AsiointiElaketyyppi.includes(tyyppi) : false
}
