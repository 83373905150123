import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  Block,
  Heading,
  Paragraph,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import styled from 'styled-components'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { runInAction } from 'mobx'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { AsiointiElaketyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const AlaikainenElinkustannusForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const elinkustannuksetTestId = 'alaikainen-elinkustannus'

  const tili = getTili()
  const elaketulot = tili.tulotiedot.saannollisetTulot
    .filter((tulo) =>
      tulo.tyyppi ? AsiointiElaketyyppi.includes(tulo.tyyppi) : []
    )
    .reduce((acc, tulo) => (acc += tulo.nettosumma), 0)

  return (
    <section data-test-id={mkYleiskatsausTestId(elinkustannuksetTestId)}>
      <Heading variant="h4" mb="s">
        {t('elinkustannuksiinKaytettavatElaketulot')}
      </Heading>
      <ElinkustannusFrame>
        <Paragraph mb={verticalMarginToken}>
          {t('elinkustannusInfotext')}
        </Paragraph>
        <ElakkeetYhteensaContainer mb={verticalMarginToken}>
          <Text>{t('elakkeetYhteensa')}</Text>
          <Text data-test-id={mkYleiskatsausTestId('elaketulotSum')}>
            {t('elaketulotSum', { elaketulot })}
          </Text>
        </ElakkeetYhteensaContainer>
        {!tili.tulotiedot.elaketulotElinkustannuksiin
          .enKaytaElakettaElinkustannuksiin && (
          <FormNumberInput
            labelText={t('kustannuksiinKaytettavaMaara')}
            data-test-id={mkYleiskatsausTestId(
              elinkustannuksetTestId,
              'kustannuksiin-kaytettava-maara'
            )}
            value={
              tili.tulotiedot.elaketulotElinkustannuksiin
                ?.elinkustannuksiinKaytettavatElaketulot
            }
            required={
              !tili.tulotiedot.elaketulotElinkustannuksiin
                .enKaytaElakettaElinkustannuksiin
            }
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              runInAction(() => {
                tili.tulotiedot.elaketulotElinkustannuksiin.elinkustannuksiinKaytettavatElaketulot =
                  value
              })
            }
          />
        )}
        <DividerLine mb={verticalMarginToken} />
        <FormCheckboxInput
          data-test-id={mkYleiskatsausTestId(
            elinkustannuksetTestId,
            'en-kayta-elinkustannuksiin'
          )}
          labelText={t('enKaytaElinkustannuksiin')}
          updateValue={(value) =>
            runInAction(() => {
              tili.tulotiedot.elaketulotElinkustannuksiin.enKaytaElakettaElinkustannuksiin =
                value
            })
          }
          defaultValue={false}
        />
      </ElinkustannusFrame>
    </section>
  )
})

export default AlaikainenElinkustannusForm

const ElinkustannusFrame = styled(Block)`
  border-radius: 2px;
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  background: ${suomifiDesignTokens.colors.highlightLight4};
  padding: ${suomifiDesignTokens.spacing.l};
`

const ElakkeetYhteensaContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`
