import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Voimassaoloaika } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const getVakuutukset = (
  { vakuutukset }: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  return vakuutukset.map((v) => ({
    heading: `${v.vakuutustyyppi}`,
    rows: [
      [
        v.toimittaja,
        v.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN
          ? t('maaraaikainen')
          : v.voimassaoloaika === Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA
          ? t('toistaiseksiVoimassaOleva')
          : undefined,
        getPaivamaaraString(t, v.beginDate, v.endDate),
      ]
        .filter((s) => s)
        .join(', '),
    ],
    attachments: v.asiakirjat,
  }))
}

const Vakuutukset: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('vakuutukset-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('vakuutuksetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['vakuutukset'] ? getVakuutukset(tili, t) : []
        }
      />
    </section>
  )
})

export default Vakuutukset
