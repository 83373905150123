import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTilirivit,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontYhtioTaiYritysOsuus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonAmountWithDecimalsOrUndefinedValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonPercentageWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const OsuudetYhtioissaYrityksissaForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontYhtioTaiYritysOsuus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.MUUT_VARAT,
      yhtionNimi: '',
      osakkeidenMaara: undefined,
      omistusosuusProsentteina: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section
      data-test-id={mkOmaisuusTestId('osuudetYhtioissaYrityksissa-form')}
    >
      <Heading variant="h3" mb="xs">
        {t('osuudetYhtioissaYrityksissaHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaOsuudetYhtioissaTaiYrityksissa')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={OsuudetYhtioissaYrityksissaListItem}
        ModalContentComponent={YhtioTaiYritysOsuusModal}
        state={omaisuus.varat.osuudetHenkiloYhtiossaJaYrityksissa}
        dataTestIdPrefix={mkOmaisuusTestId('osuudetYhtioissaYrityksissa-form')}
        newEntry={mkNewEntry}
        translationPrefix="osuudetYhtioissaYrityksissa"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}
const YhtioTaiYritysOsuusModal: FC<FormModalProps<FrontYhtioTaiYritysOsuus>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const { vaatimus: evtv } = getTilintarkastusStore()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)
    const translations =
      translationsByType[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-yhtionNimi')}
          labelText={t('yhtionNimi')}
          value={entry.yhtionNimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, yhtionNimi: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-osakkeidenMaara')}
          labelText={t('osakkeidenMaara')}
          digitMode="five"
          value={entry.osakkeidenMaara}
          validate={commonAmountWithDecimalsOrUndefinedValidator(t)}
          updateValue={(value) =>
            setEntry({
              ...entry,
              osakkeidenMaara: value,
            })
          }
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-omistusosuusProsentteina')}
          labelText={t('omistusOsuusProsentteina')}
          required
          digitMode="five"
          value={entry.omistusosuusProsentteina}
          validate={commonPercentageWithDecimalsOrUndefinedValidator(t, 'five')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              omistusosuusProsentteina: value,
            })
          }
        />
        {!isOmaisuusluettelo() && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLabel)}
          hintText={
            isOmaisuusluettelo()
              ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
              : undefined
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          title={t('asiakirjat')}
          text={t(translations.asiakirjatInfo)}
        />
      </div>
    )
  })

const OsuudetYhtioissaYrityksissaListItem: FC<
  FormElementProps<FrontYhtioTaiYritysOsuus>
> = observer(({ createDataTestId, entry, onEdit, onRemove }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omistusosuusProsentti = entry.omistusosuusProsentteina
    ? `${formatNumberWithComma(entry.omistusosuusProsentteina)} %`
    : ''

  const arvoLopussaEur = entry.arvoTilikaudenLopussaEur
    ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
    : ''

  const arvoAlussaEur = entry.arvoTilikaudenAlussaEur
    ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
    : ''

  return (
    <FormListInputExpander
      heading={entry.yhtionNimi}
      headingRight={arvoLopussaEur}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
      showSubHeadingWhileOpen
    >
      {entry.osakkeidenMaara && (
        <>
          <Heading variant="h5">{t('osakkeidenMaara')}</Heading>
          <Text>{entry.osakkeidenMaara}</Text>
          <Block mt={verticalMarginToken} />
        </>
      )}

      <Heading variant="h5">{t('omistusOsuusProsentteina')}</Heading>
      <Text>{omistusosuusProsentti}</Text>
      <Block mt={verticalMarginToken} />

      {!isOmaisuusluettelo() && (
        <>
          <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
          <Text>{arvoAlussaEur}</Text>
          <Block mt={verticalMarginToken} />
        </>
      )}

      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('asiakirjat')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Block mt={verticalMarginToken} />
    </FormListInputExpander>
  )
})

export default observer(OsuudetYhtioissaYrityksissaForm)

const translationsByType = {
  OMAISUUSLUETTELO: {
    arvoLabel: 'arvoEuroina',
    asiakirjatInfo: 'asiakirjaOsuudetInfo',
  },
  TILI: {
    arvoLabel: 'arvoTilikaudenLopussaEuroina',
    asiakirjatInfo: 'osuudetYhtioissaYrityksissaAsiakirjaInfo',
  },
}
