import keymirror from 'keymirror'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { asiointiLedgerAccountsAssetsMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-map'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import { asiointiLedgerAccountsDebtMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-map'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import { asiointiLedgerAccountsExpenseMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-map'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { asiointiLedgerAccountsIncomeMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-map'
import { AsiointiLedgerAccountSubCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export const AsiointiAccountType = keymirror({
  FINAL: null,
  YEARLY: null,
})
export type AsiointiAccountTypeId =
  (typeof AsiointiAccountType)[keyof typeof AsiointiAccountType]

export const AsiointiLocale = keymirror({
  fi: null,
  sv: null,
})
export type AsiointiLocaleId =
  (typeof AsiointiLocale)[keyof typeof AsiointiLocale]

export enum AsiointiHabitationType {
  REAL_ESTATE = 'REAL_ESTATE',
  SHARE_IN_CONDOMINIUM = 'SHARE_IN_CONDOMINIUM',
  RENTED_FLAT = 'RENTED_FLAT',
  INSTITUTION = 'INSTITUTION',
  HALL_OF_RESIDENCE = 'HALL_OF_RESIDENCE',
  HOUSING_SERVICE = 'HOUSING_SERVICE',
  OTHER = 'OTHER',
  HOUSELESS = 'HOUSELESS',
  UNKNOWN = 'UNKNOWN',
}

export const AsiointiSaannollinenTuloTyyppi = Object.keys(
  asiointiLedgerAccountsIncomeMap
)
  .filter((key) =>
    [
      AsiointiLedgerAccountSubCategory.ELAKETULOT,
      AsiointiLedgerAccountSubCategory.ETUUDET,
      AsiointiLedgerAccountSubCategory.PALKKATULOT,
      AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    ].includes(
      asiointiLedgerAccountsIncomeMap[key as AsiointiLedgerAccountIncomeType]
        .subCategory
    )
  )
  .map((key) => key as AsiointiLedgerAccountIncomeType)

export const AsiointiOmaisuusluetteloSaannollinenTuloTyyppi = Object.keys(
  asiointiLedgerAccountsIncomeMap
)
  .filter((key) =>
    [
      AsiointiLedgerAccountSubCategory.ELAKETULOT,
      AsiointiLedgerAccountSubCategory.ETUUDET,
      AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    ].includes(
      asiointiLedgerAccountsIncomeMap[key as AsiointiLedgerAccountIncomeType]
        .subCategory
    )
  )
  .map((key) => key as AsiointiLedgerAccountIncomeType)

export const AsiointiElaketyyppi = Object.keys(asiointiLedgerAccountsIncomeMap)
  .filter(
    (key) =>
      asiointiLedgerAccountsIncomeMap[key as AsiointiLedgerAccountIncomeType]
        .subCategory === AsiointiLedgerAccountSubCategory.ELAKETULOT
  )
  .map((key) => key as AsiointiLedgerAccountIncomeType)

export enum AsiointiHabitationPeriodType {
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT',
}

export const AsiointiTalletustyyppi = Object.keys(
  asiointiLedgerAccountsAssetsMap
)
  .filter(
    (key) =>
      asiointiLedgerAccountsAssetsMap[key as AsiointiLedgerAccountAssetsType]
        .subCategory ===
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN
  )
  .map((key) => key as AsiointiLedgerAccountAssetsType)

export const AsiointiArvopaperiTyyppi = Object.keys(
  asiointiLedgerAccountsAssetsMap
)
  .filter((key) =>
    [
      AsiointiLedgerAccountSubCategory.ARVOPAPERIT,
      AsiointiLedgerAccountSubCategory.RAHASTO_OSUUDET,
    ].includes(
      asiointiLedgerAccountsAssetsMap[key as AsiointiLedgerAccountAssetsType]
        .subCategory
    )
  )
  .map((key) => key as AsiointiLedgerAccountAssetsType)

export const AsiointiMuuVelkaTyyppi = Object.keys(AsiointiLedgerAccountDebtType)
  .filter(
    (key) =>
      asiointiLedgerAccountsDebtMap[key as AsiointiLedgerAccountDebtType]
        .subCategory === AsiointiLedgerAccountSubCategory.MUUT_VELAT
  )
  .map((key) => key as AsiointiLedgerAccountDebtType)

export const AsiointiPankkilainaTyyppi = Object.keys(
  AsiointiLedgerAccountDebtType
)
  .filter(
    (key) =>
      asiointiLedgerAccountsDebtMap[key as AsiointiLedgerAccountDebtType]
        .subCategory === AsiointiLedgerAccountSubCategory.PANKKILAINAT
  )
  .map((key) => key as AsiointiLedgerAccountDebtType)

export const AsiointiTuloTyyppiBySubcategory = (
  subCategory: AsiointiLedgerAccountSubCategory
): AsiointiLedgerAccountIncomeType[] => {
  return Object.keys(AsiointiLedgerAccountIncomeType)
    .filter(
      (key) =>
        asiointiLedgerAccountsIncomeMap[key as AsiointiLedgerAccountIncomeType]
          .subCategory === subCategory
    )
    .map((key) => key as AsiointiLedgerAccountIncomeType)
}

export const AsiointiMenoTyyppiBySubcategory = (
  subCategory: AsiointiLedgerAccountSubCategory
): AsiointiLedgerAccountExpenseType[] => {
  return Object.keys(AsiointiLedgerAccountExpenseType)
    .filter(
      (key) =>
        asiointiLedgerAccountsExpenseMap[
          key as AsiointiLedgerAccountExpenseType
        ].subCategory === subCategory
    )
    .map((key) => key as AsiointiLedgerAccountExpenseType)
}

export enum Kayttotarkoitus {
  OMASSA_KAYTOSSA = 'OMASSA_KAYTOSSA',
  TYHJILLAAN = 'TYHJILLAAN',
  VUOKRATTUNA = 'VUOKRATTUNA',
}

export enum Voimassaoloaika {
  MAARA_AIKAINEN = 'MAARA_AIKAINEN',
  TOISTAISEKSI_VOIMASSA_OLEVA = 'TOISTAISEKSI_VOIMASSA_OLEVA',
}

export enum AlaikaisenTilinTyyppi {
  HUOLTAJAN_HALLINNOIMA = 'HUOLTAJAN_HALLINNOIMA',
  ALAIKAISEN_OMA = 'ALAIKAISEN_OMA',
  HUOLTAJAN_OMA = 'HUOLTAJAN_OMA',
}

export enum KyllaEiTuntematon {
  KYLLA = 'KYLLA',
  EI = 'EI',
  TUNTEMATON = 'TUNTEMATON',
}
