import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import kuntakoodit from 'common/src/vtj/static/kuntakoodit-generated.json'
import { assertUnreachableCode } from 'common/src/vtj/typescript.util'
import { z } from 'zod'

let cachedValidatedKuntakoodit: KuntakooditWithTranslations | null = null

export function getKuntakoodit(): KuntakooditWithTranslations {
  if (!cachedValidatedKuntakoodit) {
    cachedValidatedKuntakoodit = kuntakooditSchema.parse(kuntakoodit)
  }
  return cachedValidatedKuntakoodit
}

export function getKuntakoodiName(
  kuntakoodi: string,
  lang: LanguageCode.FI | LanguageCode.SV | LanguageCode.EN
): string {
  const kuntakoodit = getKuntakoodit()
  const foundKuntakoodi = kuntakoodit[kuntakoodi]
  if (!foundKuntakoodi) {
    switch (lang) {
      case LanguageCode.FI:
        return `Tuntematon (${kuntakoodi})`
      case LanguageCode.SV:
        return `Okänd (${kuntakoodi})`
      case LanguageCode.EN:
        return `Unknown (${kuntakoodi})`
      default:
        assertUnreachableCode(lang)
    }
  }
  return foundKuntakoodi.name[lang]
}

const kuntakooditSchema = z.record(
  z.string(),
  z.object({
    code: z.string(),
    name: z.object({
      fi: z.string(),
      sv: z.string(),
      en: z.string(),
    }),
  })
)

export type KuntakooditWithTranslations = z.infer<typeof kuntakooditSchema>

export const alandMunicipalities = [
  '035', // Brändö
  '043', // Eckerö
  '060', // Finström
  '062', // Föglö
  '065', // Geta
  '076', // Hammarland
  '170', // Jomala
  '295', // Kumlinge
  '318', // Kökar
  '417', // Lemland
  '438', // Lumparland
  '478', // Maarianhamina
  '736', // Saltvik
  '766', // Sottunga
  '771', // Sund
  '941', // Vårdö
]
export const FOREIGN_MUNICIPALITY = '200' // Ulkomaat
export const UNKNOWN_MUNICIPALITY = '199' // Tuntematon
export const NO_MUNICIPALITY_IN_FINLAND = '198' // Ei kotikuntaa Suomessa
export const invalidMunicipalities = [
  UNKNOWN_MUNICIPALITY,
  NO_MUNICIPALITY_IN_FINLAND,
  FOREIGN_MUNICIPALITY,
]

export const isAlandMunicipality = (municipality?: string | null): boolean =>
  // @ts-expect-error includes with possibly null value
  alandMunicipalities.includes(municipality)
export const isForeignMunicipality = (municipality?: string | null): boolean =>
  municipality === FOREIGN_MUNICIPALITY
export const isValidMunicipality = (municipality?: string | null): boolean =>
  // @ts-expect-error includes with possibly null value
  !invalidMunicipalities.includes(municipality)
