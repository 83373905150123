import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  ulkomaalaisenRekisterointi4VuottaRetention,
  ulkomaalaisenRekisterointiPysyvaRetention,
  ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
  ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta31HenkilotietojaRestriction,
  ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
  ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta31HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/common-ulkomaalaisen-rekisterointi-metadata'

export const kotikunnanRekisterointiMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOLLISYYSASIAKIRJASTA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a9ef9fe9-27b8-4505-b43c-29a21a6d89f2'],
      defaultToivoAsiakirjaTypeId: 'a9ef9fe9-27b8-4505-b43c-29a21a6d89f2',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e77ee3f2-c624-453e-82dc-c168cfe2b6cf'],
      defaultToivoAsiakirjaTypeId: 'e77ee3f2-c624-453e-82dc-c168cfe2b6cf',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['286524b1-2ea0-40e5-9f1e-6c92c99c6f17'],
      defaultToivoAsiakirjaTypeId: '286524b1-2ea0-40e5-9f1e-6c92c99c6f17',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata:
        ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['32984f4c-261e-42a7-9680-a5b106e3dd59'],
      defaultToivoAsiakirjaTypeId: '32984f4c-261e-42a7-9680-a5b106e3dd59',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KOTIKUNTAPYYNTO,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: [
        '49356334-75f4-4d00-a0aa-64835859060f',
        'f3083699-972f-4630-9ff6-35c7e43b791f',
        'f350e75d-971f-46cc-85d1-9c0bb4174a49',
      ],
      defaultToivoAsiakirjaTypeId: '49356334-75f4-4d00-a0aa-64835859060f',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUSTEET_KOTIKUNNALLE_TAI_ASUMISEN_VAKINAISUUDEN_OSOITTAVA_SELVITYS,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['ce38b329-e0e1-4eeb-9202-18f543747f79'],
      defaultToivoAsiakirjaTypeId: 'ce38b329-e0e1-4eeb-9202-18f543747f79',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_LIITTEINEEN,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7c0410ca-8f88-4948-afc6-56d91b751212'],
      defaultToivoAsiakirjaTypeId: '7c0410ca-8f88-4948-afc6-56d91b751212',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata:
        ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta31HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['2b6994c9-41b2-4f14-a69f-42c9a16f6cdf'],
      defaultToivoAsiakirjaTypeId: '2b6994c9-41b2-4f14-a69f-42c9a16f6cdf',
    },
  ],
  huomioMetadata: [
    // FIXME remove: https://jira.dvv.fi/browse/OIVA-5519
    {
      huomioTypeId: HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
      postDueDateHuomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
    },
    // FIXME remove: https://jira.dvv.fi/browse/OIVA-5519
    {
      huomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_LAUSUNTOA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SELVITYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.VAALIT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MUU_TALLENNUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SAHKOISEN_ASIAKIRJAN_VARMENNUS,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.ILMOITTAJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.AIHEETON_VIENTI,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [
      SpecifierType.A_TAI_P_OLESKELULUPA,
      SpecifierType.B_OLESKELULUPA_TAI_PERHESUHDEPERUSTE,
      SpecifierType.EU_REKISTEROINTI_ALLE_12_KK_SITTEN,
      SpecifierType.EU_REKISTEROINTI_YLI_12_KK_SITTEN,
      SpecifierType.POHJOISMAAT_TAI_VIRO,
      SpecifierType.TILAPAISEN_SUOJELUN_OLESKELULUPA,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.LYKKAYS,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LYKKAYSTA,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LYKKAYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.TUNNISTAUTUMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.PAATOS_LIITTEINEEN],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: false,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.KUULEMINEN,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_KUULEMISTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.KUULEMINEN_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: false,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_SELVITYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.SELVITYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: false,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.LAUSUNTOPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LAUSUNTOA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LAUSUNTO_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: false,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELOMAKE_KANSALAINEN, Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  retentionMetadata: ulkomaalaisenRekisterointiPysyvaRetention,
  restrictionMetadata: ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta31HenkilotietojaRestriction,
  emailMetadata: {
    fromAddress: 'international@dvv.fi',
    useUserEmailIfPossible: true,
  },
}
