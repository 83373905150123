import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'

export const muutotOsittainSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const muutot4VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_4_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_4_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}
