import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { runInAction } from 'mobx'

const LisatiedotForm: React.FC = observer(() => {
  const [t] = useTranslation()

  const tili = getTili()

  return (
    <section data-test-id={mkYleiskatsausTestId('lisatiedot-container')}>
      <FormTextArea
        data-test-id={mkYleiskatsausTestId('lisatiedot-input')}
        labelText={t('lisatietojaTextInputLabel')}
        hintText={t('lisatietojaHintText')}
        maxLength={2000}
        rows={9}
        value={tili.lisatiedot}
        validate={commonXssValidator(t)}
        updateValue={(value) => {
          runInAction(() => {
            tili.lisatiedot = value
          })
        }}
      />
    </section>
  )
})

export default LisatiedotForm
