import { TFunction } from 'react-i18next'
import {
  getArvoAlussaString,
  getArvoLopussaString,
  getVelallinenString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { YhteenvetoGroupProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

export const getPankkilainatContent = (
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      tili: {
        tilirivit: { velat },
      },
    },
    vaatimus: { asiaType },
  } = getTilintarkastusStore()

  return velat.pankkilainat.map((pl) => ({
    heading: `${t(`lainatyyppi_${pl.tyyppi}`)} ${pl.pankinNimi}`,
    rows: [
      `${t('lainanNumero')}: ${pl.lainanNumero}`,
      getArvoAlussaString(t, asiaType, pl.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, pl.arvoTilikaudenLopussaEur),
    ],
    attachments: pl.asiakirjat,
  }))
}

export const getTakauksetContent = (t: TFunction): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      tili: {
        tilirivit: { velat },
      },
    },
    vaatimus: { asiaType },
  } = getTilintarkastusStore()

  return velat.takaukset.map((tak) => ({
    heading: getVelallinenString(
      t,
      tak.velallisenEtunimi,
      tak.velallisenSukunimi
    ),
    rows: [
      tak.vakuus !== undefined ? `${t('vakuus')}: ${tak.vakuus}` : undefined,
      getArvoAlussaString(t, asiaType, tak.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, tak.arvoTilikaudenLopussaEur),
      tak.onkoTullutHenkilonMaksettavaksi !== undefined
        ? `${t('onkoTullutHenkilonMaksettavaksi')}: ${
            tak.onkoTullutHenkilonMaksettavaksi ? t('kylla') : t('ei')
          }`
        : undefined,
    ],
    attachments: tak.asiakirjat,
  }))
}

export const getMuutVelatContent = (t: TFunction): YhteenvetoGroupProps[] => {
  const {
    lomake: {
      tili: {
        tilirivit: { velat },
      },
    },
    vaatimus: { asiaType },
  } = getTilintarkastusStore()

  return velat.muutVelat.map((mv) => ({
    heading: `${t(`velkatyyppi_${mv.tyyppi}`)}`,
    rows: [
      getArvoAlussaString(t, asiaType, mv.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, mv.arvoTilikaudenLopussaEur),
    ],
    attachments: mv.asiakirjat,
  }))
}
