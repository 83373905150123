import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'

export const edunvalvontaJulkinenEiHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaJulkinenHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

// From TOS-excel row: Käsittelyprosessin kuvaus (diaaritiedot) => asiatype restriction
export const edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_28],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_28],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.SALAINEN,
    publicityClassIds: [PublicityClass.SALAINEN],
    publicityMetadata: {
      [PublicityClass.SALAINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const edunvalvontaPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}

export const edunvalvonta10VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_10_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_10_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const edunvalvonta50VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_50_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_50_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}
