import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumat20VuottaRetention,
  elamantapahtumatJulkinenHenkilotietojaRestriction,
  elamantapahtumatPysyvaRetention,
  elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'

export const siviilisaadynRekisterointiKotimainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ERO_PAATOS_AVIOEROSTA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat20VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['03a25894-68eb-49bd-9179-028cd50e5384'],
      defaultToivoAsiakirjaTypeId: '03a25894-68eb-49bd-9179-028cd50e5384',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_SELVITYSASIAKIRJAT,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat20VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7aeadaa5-8c0b-4ded-b5e6-99fbb75475e2'],
      defaultToivoAsiakirjaTypeId: '7aeadaa5-8c0b-4ded-b5e6-99fbb75475e2',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat20VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['582ad357-8156-4195-bb13-263be2ae8455'],
      defaultToivoAsiakirjaTypeId: '582ad357-8156-4195-bb13-263be2ae8455',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISILMOITUS_DVVN_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      // FIXME: https://jira.dvv.fi/browse/OIVA-5562
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['28c80ab9-a65a-457d-a968-900d30b5f170'],
      defaultToivoAsiakirjaTypeId: '28c80ab9-a65a-457d-a968-900d30b5f170',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_SUKUNIMEN_ILMOITTAMINEN_DVVN_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['f2d644a5-5b88-41a2-9b25-c89320d07f8d'],
      defaultToivoAsiakirjaTypeId: 'f2d644a5-5b88-41a2-9b25-c89320d07f8d',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_MUU_ASIAKIRJA_DVVN_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      // FIXME: https://jira.dvv.fi/browse/OIVA-5562
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['723329ad-8b2e-4638-a555-77abb0960c35'],
      defaultToivoAsiakirjaTypeId: '723329ad-8b2e-4638-a555-77abb0960c35',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISILMOITUS_HETUTON,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['96b4aa8e-061c-46a1-8f20-51e77e049269'],
      defaultToivoAsiakirjaTypeId: '96b4aa8e-061c-46a1-8f20-51e77e049269',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISILMOITUS_MUU_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['ddeff5bc-4a60-42b5-9584-b30063ce16ec'],
      defaultToivoAsiakirjaTypeId: 'ddeff5bc-4a60-42b5-9584-b30063ce16ec',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_SUKUNIMEN_ILMOITTAMINEN_MUU_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a69ad520-17a1-4660-832d-8ce91cbea73d'],
      defaultToivoAsiakirjaTypeId: 'a69ad520-17a1-4660-832d-8ce91cbea73d',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_MUU_ASIAKIRJA_MUU_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['51363564-0802-4584-8e80-abf0d24e828f'],
      defaultToivoAsiakirjaTypeId: '51363564-0802-4584-8e80-abf0d24e828f',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_SELVITYSASIAKIRJAT_DVVN_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a04a8a80-b901-437d-9dfa-a57fad9660b6'],
      defaultToivoAsiakirjaTypeId: 'a04a8a80-b901-437d-9dfa-a57fad9660b6',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_SELVITYSASIAKIRJAT_MUU_VIHKIJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['52a07364-ec32-4141-9dbd-1617ef7dac35'],
      defaultToivoAsiakirjaTypeId: '52a07364-ec32-4141-9dbd-1617ef7dac35',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_DVVN_PAATOS_DVVN_VIHKIJA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['e4c776a9-f5fb-45d2-aa85-52c2e4359bdd'],
      defaultToivoAsiakirjaTypeId: 'e4c776a9-f5fb-45d2-aa85-52c2e4359bdd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_DVVN_PAATOS_MUU_VIHKIJA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['89afc02a-7e33-419c-9271-7ba6d3e5367d'],
      defaultToivoAsiakirjaTypeId: '89afc02a-7e33-419c-9271-7ba6d3e5367d',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.AVIOERO,
      SpecifierType.AVIOLIITTO,
      SpecifierType.REKISTEROIDUN_PARISUHTEEN_PURKAMINEN,
      SpecifierType.REKISTEROIDUN_PARISUHTEEN_ILMOITTAMINEN,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LISATIETOA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LISATIETO_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
